/* eslint-disable no-param-reassign */

import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { switchToSimpleCreator } from "~/features/nav/store-slice.ts";

const name = "simpleEditor";

// readonly subId?: "enhance-faces" | "fix-face-colours" | "remove-background";

type SizeAndCropNav = {
	readonly id: "size-and-crop";
	readonly subId?: "orientation" | "size" | "crop";
};

type AdjustmentsNav = {
	readonly id: "adjustments";
	readonly subId?:
		| "detail"
		| "ai-filters"
		| "brightness"
		| "contrast"
		| "saturation"
		| "colour";
};

type PreviewNav = {
	readonly id: "preview";
	readonly subId?: "show-on-wall" | "hanging-options" | "share";
};

type SimpleEditorNav = SizeAndCropNav | AdjustmentsNav | PreviewNav;

type State = {
	readonly nav: SimpleEditorNav;
};

const initialState: State = {
	nav: {
		id: "size-and-crop",
	},
};

const slice = createSlice({
	name,
	initialState,
	reducers: {
		setSimpleEditorNav: (
			state: Draft<State>,
			action: PayloadAction<SimpleEditorNav>,
		) => {
			state.nav = action.payload;
		},
	},
	extraReducers: {
		[switchToSimpleCreator.type]: (state) => {
			state.nav = initialState.nav;
		},
	},
});

export type { SimpleEditorNav, SizeAndCropNav, AdjustmentsNav, PreviewNav };
export const { setSimpleEditorNav } = slice.actions;
export default slice.reducer;
