import { BuildSources } from "@brickme/project-core/src";
import { isOnceOffLoaded } from "~/utils/loading.ts";
import { CreatorProject } from "./state.ts";

function creatorProjectToBuildSources(project: CreatorProject): BuildSources {
	return {
		original: project.sourceImage.bitmap,
		backgroundMask: isOnceOffLoaded(project.backgroundMask)
			? project.backgroundMask.data
			: undefined,
		enhanceFaces: isOnceOffLoaded(project.enhanceFacesImage)
			? project.enhanceFacesImage.data
			: undefined,
		colorisation: isOnceOffLoaded(project.colorisationImage)
			? project.colorisationImage.data
			: undefined,
		facesMask: project.facesMask,
	};
}

export default creatorProjectToBuildSources;
