import {
	maxKnownBasePlateSize,
	maxImageZoom,
	NumberOfBasePlatesDimension,
} from "../model/index.ts";
import { runGeneratorSync } from "../utils/run-generator.ts";
import {
	Bitmap,
	cloneBitmapToWritable,
	ensureBitmapOpaque,
	scaleBitmap,
} from "../bitmap/index.ts";

// Poor name. We use this to save the source image. This allows to save a smaller
// sized source (which means lower resolution when doing largest sizes, but no difference if
// at %acceptableResolutionRatio% of max size
const acceptableResolutionRatio = 0.25;

function createMaxRequiredResolutionSourceImage(
	source: Bitmap,
	maxNumberOfBasePlates: NumberOfBasePlatesDimension,
) {
	const noAlpha = runGeneratorSync(cloneBitmapToWritable(source));
	ensureBitmapOpaque(noAlpha);
	const maxWidth =
		maxNumberOfBasePlates *
		maxKnownBasePlateSize *
		maxImageZoom *
		acceptableResolutionRatio;
	const maxHeight =
		maxNumberOfBasePlates *
		maxKnownBasePlateSize *
		maxImageZoom *
		acceptableResolutionRatio;
	if (noAlpha.width <= maxWidth || noAlpha.height <= maxHeight) {
		return noAlpha;
	}

	const xScale = maxWidth / noAlpha.width;
	const yScale = maxHeight / noAlpha.height;
	const useScale = Math.max(xScale, yScale);
	const scaled = runGeneratorSync(scaleBitmap(noAlpha, useScale));
	return scaled;
}

export default createMaxRequiredResolutionSourceImage;
