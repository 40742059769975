import {
	CognitoUser,
	AuthenticationDetails,
	CognitoUserSession,
	CognitoUserPool,
	CognitoUserAttribute,
	ClientMetadata,
	ICognitoUserAttributeData,
} from "amazon-cognito-identity-js";

type AwsError = {
	readonly code: string;
} & Error;

function isAwsError(error: unknown): error is AwsError {
	return typeof error === "object" && !!error && "code" in error;
}

function authenticateUser(
	cognitoUser: CognitoUser,
	authenticationDetails: AuthenticationDetails,
): Promise<CognitoUserSession> {
	return new Promise<CognitoUserSession>((resolve, reject) => {
		cognitoUser.authenticateUser(authenticationDetails, {
			onSuccess: resolve,
			onFailure: reject,
		});
	});
}

function changePassword(
	cognitoUser: CognitoUser,
	oldPassword: string,
	newPassword: string,
) {
	return new Promise<void>((resolve, reject) => {
		cognitoUser.changePassword(oldPassword, newPassword, (error) => {
			if (error) {
				reject(error);
				return;
			}
			resolve();
		});
	});
}

function signUp(
	userPool: CognitoUserPool,
	emailAddress: string,
	password: string,
	extraAttributes?: CognitoUserAttribute[],
	clientMetadata?: ClientMetadata,
): Promise<CognitoUser | undefined> {
	return new Promise((resolve, reject) => {
		userPool.signUp(
			emailAddress,
			password,
			[
				new CognitoUserAttribute({ Name: "email", Value: emailAddress }),
				...(extraAttributes ?? []),
			],
			[],
			(error, result) => {
				if (error) {
					if (isAwsError(error) && error.code === "UsernameExistsException") {
						resolve(undefined);
						return;
					}
					reject(error);
					return;
				}

				if (!result?.user) {
					reject(new Error("Error trying to sign up"));
					return;
				}

				resolve(result.user);
			},
			clientMetadata,
		);
	});
}
async function forgotPassword(user: CognitoUser) {
	return new Promise<void>((resolve, reject) => {
		user.forgotPassword({
			onSuccess: resolve,
			onFailure: reject,
		});
	});
}

async function confirmPassword(
	user: CognitoUser,
	verificationCode: string,
	newPassword: string,
) {
	return new Promise<void>((resolve, reject) => {
		user.confirmPassword(verificationCode, newPassword, {
			onSuccess: resolve,
			onFailure: reject,
		});
	});
}

async function getUserAttributes(
	user: CognitoUser,
): Promise<readonly CognitoUserAttribute[]> {
	return new Promise((resolve, reject) => {
		user.getUserAttributes((err, userAttributes) => {
			if (err) {
				reject(err);
				return;
			}

			resolve(userAttributes ?? []);
		});
	});
}

async function getSession(user: CognitoUser): Promise<CognitoUserSession> {
	return new Promise<CognitoUserSession>((resolve, reject) => {
		user.getSession(
			(err: Error | null, session: CognitoUserSession | null) => {
				if (err || !session) {
					reject(err);
					return;
				}

				resolve(session);
			},
			{ clientMetadata: {} },
		);
	});
}

async function updateUserAttributes(
	user: CognitoUser,
	attributes: ICognitoUserAttributeData[],
) {
	return new Promise<void>((resolve, reject) => {
		user.updateAttributes(attributes, (err: Error | undefined) => {
			if (err) {
				reject(err);
				return;
			}

			resolve();
		});
	});
}

export {
	updateUserAttributes,
	authenticateUser,
	signUp,
	changePassword,
	confirmPassword,
	forgotPassword,
	isAwsError,
	getUserAttributes,
	getSession,
};
