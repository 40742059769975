/* eslint-disable no-plusplus */
import { Mat, OpenCv } from "@brickme/opencv";
import {
	uint8ArrayAllocUnsafe,
	uint8ArrayFromArrayBufferView,
} from "../utils/uint8-array.ts";
import { Bitmap, bitmapChannels } from "./types.ts";

function bitmapToOpenCv(cv: OpenCv, source: Bitmap): Mat {
	return cv.matFromImageData({
		width: source.width,
		height: source.height,
		data: new Uint8ClampedArray(
			source.data.slice(0, source.width * source.height * bitmapChannels),
		),
	});
}

function makeRgbaArrayFromRgb(array: Uint8Array) {
	if (array.length % 3 !== 0) {
		throw new Error("Length is incorrect");
	}

	const rgbaArray = uint8ArrayAllocUnsafe((array.length / 3) * 4);
	let j = 0;
	for (let i2 = 0; i2 < array.length; i2++) {
		rgbaArray[j] = array[i2];

		if ((i2 + 1) % 3 === 0) {
			rgbaArray[++j] = 0xff;
		}

		j++;
	}

	return rgbaArray;
}

function cloneOpenCvToBitmap(source: Mat) {
	// Open CV comes through as RGB sometimes.
	const isRgba = source.cols * source.rows * 4 === source.data.byteLength;
	const sourceData = uint8ArrayFromArrayBufferView(source.data);
	const data = isRgba
		? sourceData
		: // NOTE: this produces a clone, but we don't really need a clone
			// since just reading from it.
			makeRgbaArrayFromRgb(sourceData);
	return {
		data,
		width: source.cols,
		height: source.rows,
	};
}

export { cloneOpenCvToBitmap, bitmapToOpenCv };
