import React, { Suspense, lazy, useMemo, useEffect } from "react";
import { useBeforeunload } from "react-beforeunload";
import useEffectOnce from "react-use/esm/useEffectOnce";
import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import useAppSelector from "~/hooks/use-app-selector.ts";
import ShortHeader from "~/components/header/short-header.tsx";
import StatusMainPanel from "~/components/ui/status-main-panel.tsx";
import LoadingAnimation from "~/components/ui/loading-animation.tsx";
import { useShowUnloadWarningRef } from "~/context/before-unload.tsx";
import { selectHasOpenProject } from "~/features/workspace/selectors.ts";
import {
	selectShowPreview,
	selectIsTutorialOpen,
	selectIsCompleteOpen,
	selectIsSavedDesignsOpen,
	selectShowWhatsMyPassword,
	selectInitialActionStatus,
	selectGuestPromoIsOpen,
	selectPromptLogin,
	selectBasePlateConversionWarningOpen,
	selectIsSimpleCreatorOpen,
	selectShowCompleteLoginPrompt,
	selectShowCreateBrickArt,
} from "~/features/nav/selectors.ts";
import { performInitialAction } from "~/features/nav/store-slice.ts";
import {
	loadSystemPalette,
	loadPricingModel,
	loadCountries,
} from "~/features/reference/store-slice.ts";
import {
	selectHasSystemPalette,
	selectHasPricingModel,
} from "~/features/reference/selectors.ts";
import PreviewHeader from "~/features/preview/preview-header.tsx";
import EditorHeader from "~/features/editor/editor-header.tsx";
import CompleteModal from "~/features/order/complete-modal.tsx";
import {
	selectUser,
	selectJustSignedUp,
	selectHasCountries,
} from "~/features/user/selectors.ts";
import {
	ensurePicPricesLoadedForCountryCode,
	loadAuthenticatedUser,
	initialiseIpInfo,
} from "~/features/user/store-slice.ts";
import { pushGtmData } from "~/features/analytics/index.ts";
import SourceImageScreen from "~/features/workspace/source-image-screen.tsx";
import LoginPromptModal from "~/features/nav/login-prompt-modal.tsx";
import DontLoseYourWorkModal from "~/features/nav/dont-lose-your-work-modal.tsx";
import JustSignedUpModal from "~/features/nav/just-signed-up-modal.tsx";
import GuestPromoModal from "~/features/nav/guest-promo-modal.tsx";
import WhatsMyPasswordModal from "~/features/nav/whats-my-password-modal.tsx";
import TutorialModal from "~/features/nav/tutorial-modal.tsx";
import BasePlateConversionWarningModal from "~/features/nav/base-plate-conversion-warning-modal.tsx";
import SavedDesignsModal from "~/features/workspace/saved-designs-modal.tsx";
import ConfirmDeleteModal from "~/features/user/confirm-delete-modal.tsx";
import PaletteLoadStatus from "~/features/reference/palette-load-status.tsx";
import PricingModelLoadStatus from "~/features/reference/pricing-model-load-status.tsx";

const EditorSection = lazy(
	() => import("~/features/editor/editor-section.tsx"),
);
const SimpleEditorSection = lazy(
	() => import("~/features/simple-editor/simple-editor-section.tsx"),
);
const PreviewSection = lazy(
	() => import("~/features/preview/preview-section.tsx"),
);
const CreateBrickArtModal = lazy(
	() => import("~/features/preview/create-brick-art-modal.tsx"),
);

function App() {
	const dispatch = useAppDispatch();
	useEffectOnce(() => {
		(async () => {
			await Promise.all([
				dispatch(loadAuthenticatedUser()),
				dispatch(loadSystemPalette()),
				dispatch(loadPricingModel()),
				dispatch(loadCountries()),
			]);
			await dispatch(initialiseIpInfo());
			await dispatch(ensurePicPricesLoadedForCountryCode());
			await dispatch(performInitialAction());
		})();
	});

	const hasSystemPalette = useAppSelector(selectHasSystemPalette);
	const hasCountries = useAppSelector(selectHasCountries);
	const hasPricingModel = useAppSelector(selectHasPricingModel);

	const user = useAppSelector(selectUser);
	const hasProject = useAppSelector(selectHasOpenProject);
	const showPreview = useAppSelector(selectShowPreview);
	const isTutorialOpen = useAppSelector(selectIsTutorialOpen);
	const isCreateBrickArtOpen = useAppSelector(selectShowCreateBrickArt);
	const isCompleteOpen = useAppSelector(selectIsCompleteOpen);
	const isSavedDesignsOpen = useAppSelector(selectIsSavedDesignsOpen);
	const showWhatsMyPassword = useAppSelector(selectShowWhatsMyPassword);
	const initialActionStatus = useAppSelector(selectInitialActionStatus);
	const justSignedUp = useAppSelector(selectJustSignedUp);
	const basePlateConversionWarningOpen = useAppSelector(
		selectBasePlateConversionWarningOpen,
	);
	const isGuestPromoOpen = useAppSelector(selectGuestPromoIsOpen);
	const isLoginPromptOpen = useAppSelector(selectPromptLogin);
	const isSimpleCreatorOpen = useAppSelector(selectIsSimpleCreatorOpen);
	const isCompleteLoginOpen = useAppSelector(selectShowCompleteLoginPrompt);

	const show = useMemo(() => {
		if (user.type === "loadingPrevious") {
			return "loadingUser";
		}
		if (!hasSystemPalette) {
			return "loadingSystemPalette";
		}
		if (!hasCountries) {
			return "loadingCountries";
		}
		if (!hasPricingModel) {
			return "loadingPricingModel";
		}
		if (initialActionStatus !== "done") {
			return "performingInitialAction";
		}
		if (user.type !== "signedIn" && isLoginPromptOpen) {
			return "loginPrompt";
		}
		if (
			user.type !== "signedIn" &&
			user.type !== "guest" &&
			!isCompleteLoginOpen
		) {
			return "dontLoseYourWork";
		}
		if (justSignedUp) {
			return "justSignedUp";
		}
		if (user.type === "guest" && isGuestPromoOpen) {
			return "guestPromoModal";
		}
		if (!hasProject) {
			return "selectImage";
		}
		if (showPreview) {
			return "preview";
		}
		return "editor";
	}, [
		user,
		isCompleteLoginOpen,
		hasProject,
		showPreview,
		initialActionStatus,
		hasSystemPalette,
		hasCountries,
		hasPricingModel,
		isGuestPromoOpen,
		isLoginPromptOpen,
		justSignedUp,
	]);

	// GTM
	useEffect(() => {
		if (show === "dontLoseYourWork") {
			dispatch(
				pushGtmData({
					event: "PageView",
					page_title: "App Sign In",
				}),
			);
		}
	}, [dispatch, show]);

	const showUnloadWarningRef = useShowUnloadWarningRef();
	useBeforeunload(() => {
		if (!showUnloadWarningRef.current) {
			return undefined;
		}
		if (show === "selectImage" || show === "editor" || show === "preview") {
			return "Are you sure you want to leave? You're part way through a project!";
		}
		return undefined;
	});

	return (
		<>
			{show === "editor" && !isSimpleCreatorOpen && <EditorHeader />}
			{show === "preview" && !isSimpleCreatorOpen && <PreviewHeader />}
			{[
				"selectImage",
				"firstTime",
				"dontLoseYourWork",
				"performingInitialAction",
				"loadingUser",
				"loadingSystemPalette",
				"loadingPricingModel",
				"loadingCountries",
			].includes(show) && <ShortHeader />}
			{show === "loadingSystemPalette" && <PaletteLoadStatus />}
			{show === "loadingCountries" && <PaletteLoadStatus />}
			{show === "loadingPricingModel" && <PricingModelLoadStatus />}
			{show === "loadingUser" && (
				<StatusMainPanel>
					<LoadingAnimation>Logging user in...</LoadingAnimation>
				</StatusMainPanel>
			)}
			{show === "performingInitialAction" && (
				<StatusMainPanel>
					<LoadingAnimation>Loading image...</LoadingAnimation>
				</StatusMainPanel>
			)}
			{show === "selectImage" && (
				<Suspense
					fallback={
						<StatusMainPanel>
							<LoadingAnimation>Loading...</LoadingAnimation>
						</StatusMainPanel>
					}
				>
					<SourceImageScreen />
				</Suspense>
			)}
			{(show === "editor" || show === "preview") && isSimpleCreatorOpen && (
				<Suspense
					fallback={
						<StatusMainPanel>
							<LoadingAnimation>Loading editor...</LoadingAnimation>
						</StatusMainPanel>
					}
				>
					<SimpleEditorSection />
				</Suspense>
			)}
			{show === "editor" && !isSimpleCreatorOpen && (
				<Suspense
					fallback={
						<StatusMainPanel>
							<LoadingAnimation>Loading editor...</LoadingAnimation>
						</StatusMainPanel>
					}
				>
					<EditorSection />
				</Suspense>
			)}
			{show === "preview" && !isSimpleCreatorOpen && (
				<Suspense
					fallback={
						<StatusMainPanel>
							<LoadingAnimation>Loading editor...</LoadingAnimation>
						</StatusMainPanel>
					}
				>
					<PreviewSection />
				</Suspense>
			)}
			<LoginPromptModal show={show === "loginPrompt"} />
			<DontLoseYourWorkModal show={show === "dontLoseYourWork"} />
			<JustSignedUpModal show={show === "justSignedUp"} />
			<GuestPromoModal show={show === "guestPromoModal"} />
			<WhatsMyPasswordModal show={showWhatsMyPassword} />
			<TutorialModal show={isTutorialOpen} />
			{user.type === "signedIn" && user.user.artProductCreator && (
				<Suspense fallback={null}>
					<CreateBrickArtModal show={isCreateBrickArtOpen} />
				</Suspense>
			)}
			<BasePlateConversionWarningModal show={basePlateConversionWarningOpen} />
			<CompleteModal show={isCompleteOpen} />
			{
				/* need this because assumes signed in user */
				user.type === "signedIn" && (
					<>
						<SavedDesignsModal show={isSavedDesignsOpen} />
						<ConfirmDeleteModal />
					</>
				)
			}
		</>
	);
}

export default App;
