import { sortBy } from "lodash-es";
import {
	BrickColourHexString,
	AutoPaletteMode,
	CustomPaletteMode,
	Palette,
	getClosestPaletteBrick,
	getClosestPaletteItem,
	HybridPaletteMode,
	BuildBitmap,
	BrickColour,
	WritableBuildBitmap,
	PictureConfiguration,
} from "../model/index.ts";
import { bitmapChannels, cloneBitmapToWritable } from "../bitmap/index.ts";
import {
	BuildOperationInput,
	BuildOperationResult,
	OperationBuildSources,
} from "./operation.ts";
import { readUInt32BE, writeUInt32BE } from "../utils/uint8-array.ts";
import compileMaskingProps from "./compile-masking-props.ts";
import { floatFloor } from "../utils/float.ts";
import { hslToRgb } from "../utils/colour-conversions.ts";

// Far from comprehensive, but unlikely to need more than this
const noBrickL = 0.5;
const noBrickS = 1;

function findAvailableNoBrickColour(systemPalette: Palette) {
	function findHue(
		potentialHue: number,
		attemptNumber: number,
	): BuildBitmap["noBrickColour"] {
		const rgb = hslToRgb(potentialHue, noBrickS, noBrickL);
		if (
			!systemPalette.some(
				(c) =>
					c.brick.r === rgb.r && c.brick.g === rgb.g && c.brick.b === rgb.b,
			)
		) {
			return {
				rgba: rgb.r * 0x1000000 + rgb.g * 0x10000 + rgb.b * 0x100 + 0xff,
			};
		}
		if (attemptNumber > 100) {
			throw new Error("Couldn't find a no-brick colour");
		}

		return findHue((potentialHue + 17) % 360, attemptNumber + 1);
	}
	// bright pink hue
	return findHue(310, 0);
}

function* applyPalette(
	image: WritableBuildBitmap,
	palette: Palette,
	config: PictureConfiguration,
	{ backgroundMask }: OperationBuildSources,
	systemPalette: Palette,
): Generator<undefined, Pick<BuildBitmap, "noBrickColour">> {
	// Note: don't use data.length, doesn't equal this
	// 4 bytes per pixel
	// Important to take a reference since it can change while generator running
	const { data, width, height } = image;
	const numPixelBytes = width * height * bitmapChannels;

	const maskingProps = compileMaskingProps(config, backgroundMask, image);

	let noBrickColour: BuildBitmap["noBrickColour"] = undefined;
	if (config.removeBackground && "noBrick" in config.removeBackground) {
		noBrickColour = findAvailableNoBrickColour(systemPalette);
	}

	let availablePalette = palette.filter(
		(p) => p.limit === undefined || p.limit > 0,
	);
	const usedCounts = new Map<Palette[number], number>();
	for (let p = 0; p < numPixelBytes; p += bitmapChannels) {
		if (noBrickColour !== undefined && maskingProps !== undefined) {
			const pixelIndex = p / bitmapChannels;
			const x = pixelIndex % width;
			const y = (pixelIndex - x) / width;
			const maskX = floatFloor(
				(x + maskingProps.croppedXOffset) / maskingProps.usedResizeScale,
			);
			const maskY = floatFloor(
				(y + maskingProps.croppedYOffset) / maskingProps.usedResizeScale,
			);
			if (maskingProps.foregroundRatio[maskX][maskY] === 0) {
				writeUInt32BE(data, noBrickColour.rgba, p);
				continue;
			}
		}

		const pixel = readUInt32BE(data, p);

		// Note: getClosestPaletteItem inside has a cache
		const closest = getClosestPaletteItem(availablePalette, pixel);

		const colourCount = usedCounts.get(closest);
		let newCount = 1;
		if (colourCount !== undefined) {
			newCount = colourCount + 1;
		}
		usedCounts.set(closest, newCount);
		if (closest.limit !== undefined && newCount >= closest.limit) {
			availablePalette = availablePalette.filter((p) => p !== closest);
		}

		writeUInt32BE(data, closest.brick.rgba, p);

		if (p % 1000 === 0) {
			yield;
		}
	}

	return { noBrickColour };
}

function* getBrickPrePenCounts(
	image: BuildBitmap,
	palette: Palette,
): Generator<undefined, Record<BrickColourHexString, number>> {
	const counts = Object.fromEntries(palette.map((c) => [c.brick.hexString, 0]));
	// Note: don't use data.length, doesn't equal this
	// 4 bytes per pixel
	// Note: important to take a reference since can change while generator running
	// TODO: Ignore no brick
	const { data, width, height } = image;
	const noBrickRgba = image.noBrickColour?.rgba;
	const numPixelBytes = width * height * bitmapChannels;
	for (let p = 0; p < numPixelBytes; p += bitmapChannels) {
		const pixel = readUInt32BE(data, p);
		if (pixel === noBrickRgba) {
			continue;
		}

		const closest = getClosestPaletteBrick(palette, pixel);
		counts[closest.hexString] += 1;
		if (p % 1000 === 0) {
			yield;
		}
	}
	return counts;
}

function* applyCustomPaletteMode(
	image: BuildBitmap,
	paletteMode: CustomPaletteMode,
	systemPalette: Palette,
	config: PictureConfiguration,
	sources: OperationBuildSources,
): Generator<undefined, BuildOperationResult> {
	const clonedImage = yield* cloneBitmapToWritable(image);
	const { noBrickColour } = yield* applyPalette(
		clonedImage,
		systemPalette.filter((p) =>
			paletteMode.palette.some((i) => i.hexString === p.brick.hexString),
		),
		config,
		sources,
		systemPalette,
	);
	return {
		type: "cloned",
		clonedImage: { ...clonedImage, noBrickColour },
	};
}

function* applyTopPalette(
	image: BuildBitmap,
	numberOfColours: number,
	systemPalette: Palette,
	config: PictureConfiguration,
	sources: OperationBuildSources,
	extraFixedColours: readonly BrickColour[] = [],
) {
	if (numberOfColours < extraFixedColours.length) {
		throw new Error("Can't have more fixed colours than total colours");
	}

	// Note: We specifically want to choose the palette before pen's impact.
	// This makes pen marks stable. i.e. if writing over some colour area the
	// image doesn't "jump around" because of changing palette snaps.
	const beforePenCounts = yield* getBrickPrePenCounts(image, systemPalette);

	const orderedBeforePenCounts = sortBy(
		Object.entries(beforePenCounts),
		([colour]) =>
			extraFixedColours.some((c) => c.hexString === colour) ? 0 : 1,
		([, count]) => -count,
		// This second condition is only to make sure the sort is stable
		([colour]) => colour,
	).map(([c]) => c);

	const topColours = new Set<BrickColourHexString>(
		orderedBeforePenCounts.slice(0, numberOfColours),
	);

	// TODO: This is a reference into a weakmap, so if could cache would improve that
	// The idea here is to be able to use the weak cache for closest palette calculations
	// In benchmarks, this second applyPalette takes about twice as long as the system
	// one above.
	const topPalette = systemPalette.filter(({ brick }) =>
		topColours.has(brick.hexString),
	);

	const clonedImage = yield* cloneBitmapToWritable(image);
	const { noBrickColour } = yield* applyPalette(
		clonedImage,
		topPalette,
		config,
		sources,
		systemPalette,
	);
	return {
		type: "cloned" as const,
		clonedImage: { ...clonedImage, noBrickColour },
	};
}

function* applyAutoPalette(
	image: BuildBitmap,
	paletteMode: AutoPaletteMode,
	systemPalette: Palette,
	config: PictureConfiguration,
	sources: OperationBuildSources,
) {
	return yield* applyTopPalette(
		image,
		paletteMode.numberOfColours,
		systemPalette,
		config,
		sources,
	);
}

// Note: this isn't a perfect algorithm and probably not even good. But it's good enough for now.
// Problem: Fixed palette colours interacting with non-fixed palette
function* applyHybridPalette(
	image: BuildBitmap,
	paletteMode: HybridPaletteMode,
	systemPalette: Palette,
	config: PictureConfiguration,
	sources: OperationBuildSources,
) {
	return yield* applyTopPalette(
		image,
		paletteMode.palette.length + paletteMode.numberOfExtraColours,
		systemPalette,
		config,
		sources,
		paletteMode.palette,
	);
}

function* paletteModeOperation({
	config,
	intermediate: { image },
	systemPalette,
	sources,
}: Pick<
	BuildOperationInput,
	"config" | "intermediate" | "systemPalette" | "sources"
>): Generator<undefined, BuildOperationResult> {
	switch (config.paletteMode.type) {
		case "custom":
			return yield* applyCustomPaletteMode(
				image,
				config.paletteMode,
				systemPalette,
				config,
				sources,
			);
		case "auto":
			return yield* applyAutoPalette(
				image,
				config.paletteMode,
				systemPalette,
				config,
				sources,
			);
		case "hybrid":
			return yield* applyHybridPalette(
				image,
				config.paletteMode,
				systemPalette,
				config,
				sources,
			);
		default:
			throw new Error("Unrecognised palette mode");
	}
}

export default paletteModeOperation;
