import React, {
	ReactNode,
	createContext,
	useState,
	useEffect,
	useContext,
} from "react";
import { OpenCv } from "@brickme/opencv";

const Context = createContext<OpenCv | "loading" | undefined>(undefined);

type OpenCvProviderProps = {
	readonly openCv: Promise<OpenCv>;
	readonly children: ReactNode;
};

export function OpenCvProvider({ openCv, children }: OpenCvProviderProps) {
	const [value, setValue] = useState<"loading" | OpenCv>("loading");
	useEffect(() => {
		setValue("loading");
		(async () => {
			const newValue = await openCv;
			setValue(newValue);
		})();
	}, [openCv]);

	return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useOpenCv(): OpenCv | undefined {
	const value = useContext(Context);
	if (value === undefined) {
		throw new Error("No open cv context");
	}
	if (value === "loading") {
		return undefined;
	}
	return value;
}
