import { useCallback, useState } from "react";

// Gets around having to define callbacks for set true or set false (to prevent child re-rendering)
/** @deprecated At least should remove useCallbacks but not sure impact on calling code */
function useBooleanCallbacks(initialState: boolean) {
	const [state, setState] = useState(initialState);
	const setOther = useCallback(() => {
		setState(!initialState);
	}, [initialState]);
	const setInitial = useCallback(() => {
		setState(initialState);
	}, [initialState]);
	return [state, setOther, setInitial] as const;
}

export default useBooleanCallbacks;
