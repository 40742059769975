/* eslint-disable no-param-reassign */
import {
	createSlice,
	PayloadAction,
	Draft,
	createAsyncThunk,
} from "@reduxjs/toolkit";
import shareSavedProjectMutation, {
	ShareSavedProjectMutation,
	ShareSavedProjectMutationVariables,
} from "~/api/share-saved-project-mutation.ts";
import createBrickArtDesignFromSavedProjectMutation, {
	CreateBrickArtDesignFromSavedProjectMutation,
	CreateBrickArtDesignFromSavedProjectVariables,
} from "~/api/create-brick-art-design-from-saved-project-mutation.ts";
import { StoreThunkApiConfig } from "~/store-config.ts";
import {
	UpdatableLoad,
	pendingUpdatableLoad,
	performUpdatableLoad,
	loadingUpdatableLoad,
	loadingOnceOffLoad,
	OnceOffLoad,
	pendingOnceOffLoad,
	loadedOnceOffLoad,
	errorOnceOffLoad,
} from "~/utils/loading.ts";
import {
	createProject,
	State as SaveProjectState,
	updateSaveOpenProject,
} from "~/features/save-project/store-slice.ts";
import {
	resetProject,
	setBasePlateSize,
	setNumberOfBasePlates,
	removeOtherVersion,
} from "~/features/workspace/store-slice.ts";

const name = "preview";

type PreviewType = { type: "full" } | { type: "scene"; sceneId: string };

type State = {
	readonly previewType: PreviewType;
	readonly isSaveAndCompareOpen: boolean;
	readonly shareUrl: OnceOffLoad<{
		readonly url: string;
		readonly mediaUrl: string;
		readonly hasCopiedToClipboard: boolean;
	}>;
	readonly hasSeenExactlyWarning: boolean;
	readonly pendingRemoveOtherVersionId?: string;
	readonly createBrickArtStatus: UpdatableLoad<void>;
};

const shareSavedProject = createAsyncThunk<
	{ readonly url: string; readonly mediaUrl: string },
	undefined,
	StoreThunkApiConfig<{ saveProject: SaveProjectState }>
>(
	`${name}/shareSavedProject`,
	async (_, { extra: { apiClient }, getState }) => {
		const { openedProjectSaveState } = getState().saveProject;
		if (openedProjectSaveState?.type !== "savedToBackend") {
			throw new Error("Project must be saved");
		}

		const result = await apiClient.request<
			ShareSavedProjectMutation,
			ShareSavedProjectMutationVariables
		>(shareSavedProjectMutation, {
			savedProjectId: openedProjectSaveState.id,
		});
		const shareResult = result.shareSavedProject;
		if (!shareResult) {
			throw new Error("There was a problem sharing the project");
		}
		return shareResult;
	},
);

type CreateBrickArtValues = {
	readonly title: string;
	readonly description: string;
	readonly tags: readonly string[];
	readonly collectionShopifyIds: readonly string[];
};

const createBrickArt = createAsyncThunk<
	UpdatableLoad<void>,
	CreateBrickArtValues,
	StoreThunkApiConfig<{ preview: State; saveProject: SaveProjectState }>
>(
	`${name}/createBrickArt`,
	async (values, { extra: { apiClient }, getState }) => {
		const {
			preview: { createBrickArtStatus },
			saveProject: { openedProjectSaveState },
		} = getState();
		return performUpdatableLoad(
			createBrickArtStatus,
			async () => {
				if (openedProjectSaveState?.type !== "savedToBackend") {
					throw new Error(`Project not saved: ${openedProjectSaveState?.type}`);
				}
				await apiClient.request<
					CreateBrickArtDesignFromSavedProjectMutation,
					CreateBrickArtDesignFromSavedProjectVariables
				>(createBrickArtDesignFromSavedProjectMutation, {
					id: openedProjectSaveState.id,
					...values,
				});
			},
			() => undefined,
		);
	},
);

const slice = createSlice({
	name,
	initialState: {
		previewType: { type: "full" },
		isSaveAndCompareOpen: false,
		shareUrl: pendingOnceOffLoad,
		hasSeenExactlyWarning: false,
		createBrickArtStatus: pendingUpdatableLoad,
	} as State,
	reducers: {
		setPreviewType: (
			state: Draft<State>,
			action: PayloadAction<PreviewType>,
		) => {
			state.previewType = action.payload;
		},
		setSaveAndCompareOpen: (
			state: Draft<State>,
			action: PayloadAction<boolean>,
		) => {
			state.isSaveAndCompareOpen = action.payload;
		},
		copiedShareUrlToClipboard: (state: Draft<State>) => {
			if (state.shareUrl.type !== "loaded") {
				throw new Error("Invalid state");
			}
			state.shareUrl.data.hasCopiedToClipboard = true;
		},
		setHasSeenExactlyWarning: (state: Draft<State>) => {
			state.hasSeenExactlyWarning = true;
		},
		setPendingRemoveOtherVersionId: (
			state: Draft<State>,
			action: PayloadAction<string>,
		) => {
			state.pendingRemoveOtherVersionId = action.payload;
		},
		cancelPendingDeleteOtherVersion: (state: Draft<State>) => {
			state.pendingRemoveOtherVersionId = undefined;
		},
		resetCreateBrickArtStatus: (state: Draft<State>) => {
			state.createBrickArtStatus = pendingUpdatableLoad;
		},
	},
	extraReducers: {
		[createBrickArt.pending.type]: (state: Draft<State>) => {
			state.createBrickArtStatus = loadingUpdatableLoad(
				state.createBrickArtStatus,
			);
		},
		[createBrickArt.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof createBrickArt)["fulfilled"]>>,
		) => {
			state.createBrickArtStatus = action.payload;
		},
		[removeOtherVersion.name]: (
			state: Draft<State>,
			action: ReturnType<typeof removeOtherVersion>,
		) => {
			if (state.pendingRemoveOtherVersionId === action.payload) {
				state.pendingRemoveOtherVersionId = undefined;
			}
		},
		[shareSavedProject.pending.type]: (state: Draft<State>) => {
			state.shareUrl = loadingOnceOffLoad;
		},
		[shareSavedProject.fulfilled.type]: (
			state: Draft<State>,
			action: ReturnType<(typeof shareSavedProject)["fulfilled"]>,
		) => {
			state.shareUrl = loadedOnceOffLoad({
				url: action.payload.url,
				mediaUrl: action.payload.mediaUrl,
				hasCopiedToClipboard: false,
			});
		},
		[shareSavedProject.rejected.type]: (
			state: Draft<State>,
			action: ReturnType<(typeof shareSavedProject)["rejected"]>,
		) => {
			state.shareUrl = errorOnceOffLoad(
				action.error.message ?? "Error sharing project",
			);
		},
		[createProject.pending.type]: (state: Draft<State>) => {
			state.shareUrl = pendingOnceOffLoad;
		},
		[updateSaveOpenProject.pending.type]: (state: Draft<State>) => {
			state.shareUrl = pendingOnceOffLoad;
		},
		[resetProject.pending.type]: (state: Draft<State>) => {
			state.shareUrl = pendingOnceOffLoad;
		},
		[setBasePlateSize.type]: (state: Draft<State>) => {
			state.previewType = { type: "full" };
		},
		[setNumberOfBasePlates.type]: (state: Draft<State>) => {
			state.previewType = { type: "full" };
		},
	},
});

export type { PreviewType };
export { shareSavedProject, createBrickArt };
export const {
	setPreviewType,
	setSaveAndCompareOpen,
	copiedShareUrlToClipboard,
	setHasSeenExactlyWarning,
	setPendingRemoveOtherVersionId,
	cancelPendingDeleteOtherVersion,
	resetCreateBrickArtStatus,
} = slice.actions;
export default slice.reducer;
