import React, { ReactElement, ReactNode } from "react";
import logoImage from "~/styles/img/logo.svg";
import MainMenu from "./main-menu.tsx";

type FullHeaderProps = {
	readonly children: ReactNode;
	readonly actions: readonly ReactElement[];
};

export default function FullHeader({
	children,
	actions,
}: FullHeaderProps): ReactElement {
	return (
		<div className="main-header-outer">
			<header className="main-header">
				<div className="main-header__container container-fluid">
					<div className="main-header__content row">
						<div className="main-header__aside-col col-md-3 col-sm-4 col-5">
							<MainMenu />
							<div className="main-header__logo">
								<a href="/" className="main-header__logo-link">
									<img
										src={logoImage}
										alt="Brick Me Creator"
										className="main-header__logo-img"
									/>
									<span className="main-header__logo-descr d-none d-sm-block">
										Creator
									</span>
								</a>
							</div>
						</div>
						<div className="main-header__col col-md-9 col-sm-8 col-7">
							{children}
							<div className="main-header__actions">{actions}</div>
						</div>
					</div>
				</div>
			</header>
		</div>
	);
}
