import { Draft } from "@reduxjs/toolkit";
import {
	Bitmap,
	BitmapMask,
	FaceBoundingBox,
	EncodedImage,
	PictureConfiguration,
	BrickedPictureOutput,
	Project,
	UnixTimestamp,
} from "@brickme/project-core/src";
import { OnceOffLoad } from "~/utils/loading.ts";

type CreatorOtherVersion = {
	readonly id: string;
	readonly picture: PictureConfiguration;
};

type CreatorProjectSource = {
	readonly smallestEncodedImage: EncodedImage;
	readonly bitmap: Bitmap;
	readonly originalEncodedImage: EncodedImage | undefined;
};

type CreatorProject = Omit<Project, "otherVersions"> & {
	readonly sourceImage: CreatorProjectSource;
	readonly faceBoundingBoxes: readonly FaceBoundingBox[] | undefined;
	readonly facesMask: BitmapMask | undefined;
	readonly backgroundMask: OnceOffLoad<BitmapMask>;
	readonly enhanceFacesImage: OnceOffLoad<Bitmap>;
	readonly colorisationImage: OnceOffLoad<Bitmap>;
	readonly otherVersions: readonly CreatorOtherVersion[];
};

function creatorProjectToCoreProject(project: CreatorProject): Project {
	return {
		...project,
		otherVersions: project.otherVersions.map(({ picture }) => picture),
	};
}

type OpenProject = {
	readonly build: BrickedPictureOutput;
	readonly builtCurrentPictureUpdatedAt: UnixTimestamp;
	readonly project: CreatorProject;
};

type State = {
	readonly openProject?: OpenProject;
};

function requireOpenProject(state: Draft<State>): Draft<OpenProject> {
	const { openProject } = state;
	if (!openProject) {
		throw new Error("No open project");
	}
	return openProject;
}

export { requireOpenProject, creatorProjectToCoreProject };
export type {
	OpenProject,
	CreatorProject,
	CreatorProjectSource,
	CreatorOtherVersion,
	State,
};
