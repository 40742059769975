/* eslint-disable no-bitwise */
import { Bitmap, bitmapChannels } from "./types.ts";

// Note: we don't use data.length because sometimes it can be longer than the bitmap
function iterateBitmapIndex(
	image: Bitmap,
	op: (index: number, x: number, y: number) => void,
) {
	for (let y = 0; y < image.height; y++) {
		for (let x = 0; x < image.width; x++) {
			const idx = (image.width * y + x) << 2;
			op(idx, x, y);
		}
	}
}

function* iterateBitmapIndexGenerator(
	image: Bitmap,
	op: (index: number, x: number, y: number) => void,
	yieldSize: number,
) {
	if (yieldSize % bitmapChannels !== 0) {
		throw new Error(`yield size must be divisible by bitmapchannels`);
	}
	for (let y = 0; y < image.height; y++) {
		for (let x = 0; x < image.width; x++) {
			const idx = (image.width * y + x) << 2;
			op(idx, x, y);
			if (idx % yieldSize === 0) {
				yield;
			}
		}
	}
}

export { iterateBitmapIndexGenerator, iterateBitmapIndex };
