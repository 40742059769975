import React, { useCallback } from "react";
import IFrame from "react-iframe";
import Modal from "~/components/modal.tsx";
import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import { closeTutorial } from "./store-slice.ts";

type TutorialModalProps = {
	readonly show: boolean;
};

function TutorialModal({ show }: TutorialModalProps) {
	const dispatch = useAppDispatch();
	const onClose = useCallback(() => {
		dispatch(closeTutorial());
	}, [dispatch]);

	return (
		<Modal show={show} size="large">
			<div className="default-modal__tutorial-video">
				{show && (
					<IFrame
						className="default-modal__tutorial-video__frame"
						url="https://www.youtube.com/embed/fKTlwGnuZSU"
						frameBorder={0}
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					/>
				)}
			</div>
			<Modal.Actions
				actions={[{ text: "Close", onClick: onClose, type: "ghost" }]}
			/>
		</Modal>
	);
}

export default TutorialModal;
