import { WritableBitmap } from "./types.ts";
import { iterateBitmapIndex } from "./iterate.ts";

function clipPixelChannel(pixel: number) {
	if (pixel < 0) {
		return 0;
	}
	if (pixel > 0xff) {
		return 0xff;
	}
	return Math.round(pixel);
}

function clipPixelChannels(image: WritableBitmap) {
	iterateBitmapIndex(image, (i) => {
		// eslint-disable-next-line no-param-reassign
		image.data[i] = clipPixelChannel(image.data[i]);
	});
}

export { clipPixelChannels, clipPixelChannel };
