import React, { useMemo, useRef, useEffect } from "react";
import { Provider } from "react-redux";
import { GraphQLClient, ClientError } from "graphql-request";
import { DateTime, Duration } from "luxon";
import isMobileDetector from "is-mobile";
import { unwrapResult } from "@reduxjs/toolkit";
import { backOff } from "exponential-backoff";
import { BasePlateSize, scenes, SceneImage } from "@brickme/project-core/src";
import { OpenCv } from "@brickme/opencv";
import {
	isSupported as isOpenCvSupported,
	notSupportedStub as openCvNotSupportedStub,
} from "@brickme/opencv/browser/support";
import { createStubGtm, initialiseGtm } from "~/frontend-common/gtm.ts";
import { appendTidioScript, TidioChatApi } from "~/frontend-common/tidio.ts";
import LoadingAnimation from "~/components/ui/loading-animation.tsx";
import { createStoreWithLocalStoragePersistence } from "~/store/create-store.ts";
import { WebsiteUrlProvider } from "~/context/website-url.tsx";
import { HeavyProcessRunnerProvider } from "~/context/heavy-process-runner.tsx";
import { BeforeUnloadProvider } from "~/context/before-unload.tsx";
import { OpenCvProvider } from "~/context/open-cv.tsx";
import createFileStorage from "~/file-storage.ts";
import { createTidioChatApiProxy } from "~/tidio/index.ts";
import createProcessRunner from "~/utils/create-process-runner.ts";
import isFacebookSourceBrowser from "~/utils/is-facebook-source-browser.ts";
import { initialState as referenceInitialState } from "~/features/reference/state.ts";
import createBuildListener from "~/features/workspace/create-build-listener.ts";
import createAutoSaveListener from "~/features/save-project/auto-save-listener.ts";
import { initialOrderState } from "~/features/order/store-slice.ts";
import { refreshAuthenticatedUser } from "~/features/user/store-slice.ts";
import { initialState as navInitialState } from "~/features/nav/store-slice.ts";
import type { AppStore } from "~/store/types.d.ts";
import { createPersistCountryService } from "~/store/persist-country.ts";
import { getPossibleInitialActionFromUrl } from "~/features/nav/initial-action.ts";
import { createAuthService } from "~/frontend-common/auth.ts";
import { createIpInfoGeolocationService } from "~/frontend-common/localisation.ts";
import App from "./app.tsx";

// If within 1 min of expiring then get new access token
const refreshAccessThreshold = Duration.fromMillis(60 * 1000);

type AppContainerProps = {
	readonly siteCode: string;
	readonly websiteUrl: string;
	readonly apiUrl: string;
	readonly cognitoPoolConfig: {
		readonly id: string;
		readonly appClientId: string;
	};
	readonly shopifyConfig: {
		readonly shopDomain: string;
		readonly storefrontAccessToken: string;
	};
	readonly platformCookieDomain: string;
	readonly tidioScriptId?: string;
	readonly gtmId?: string;
	readonly userAgent: string;
	readonly ipInfoToken: string;
	readonly url: string;
	readonly version: string;
	readonly defaultCountryCode: string;
	readonly mediaBaseUrl: string;
	readonly basePlateSizes: readonly BasePlateSize[];
	readonly maxNumberOfSelectableBasePlates: number;
};

function AppContainer({
	websiteUrl,
	apiUrl,
	gtmId,
	cognitoPoolConfig,
	shopifyConfig,
	platformCookieDomain,
	tidioScriptId,
	url,
	basePlateSizes,
	userAgent,
	ipInfoToken,
	version,
	defaultCountryCode,
	mediaBaseUrl,
	siteCode,
	maxNumberOfSelectableBasePlates,
}: AppContainerProps) {
	if (basePlateSizes.length === 0) {
		throw new Error("No base plate sizes provided");
	}

	// Shopify
	const shopifyClient = useMemo(
		() =>
			new GraphQLClient(
				`https://${shopifyConfig.shopDomain}/api/2022-10/graphql.json`,
				{
					headers: {
						"X-Shopify-Storefront-Access-Token":
							shopifyConfig.storefrontAccessToken,
					},
				},
			),
		[shopifyConfig],
	);

	// OpenCV
	const openCvLoader = useMemo<Promise<OpenCv>>(async () => {
		if (isOpenCvSupported) {
			const module = await import("@brickme/opencv/browser");
			return module.default;
		}
		return openCvNotSupportedStub;
	}, []);

	// Tidio
	const { current: tidioChatApiProxy } = useRef(createTidioChatApiProxy());
	useEffect(() => {
		if (!tidioScriptId) {
			return () => undefined;
		}

		const sub = appendTidioScript(
			document,
			tidioScriptId,
			(api: TidioChatApi) => {
				tidioChatApiProxy.setApi(api);
			},
		);
		return () => sub.remove();
	}, [tidioScriptId, tidioChatApiProxy]);

	// Api
	const storeRef = useRef<AppStore>();
	const apiClient = useMemo(() => {
		return new GraphQLClient(apiUrl, {
			async fetch(url, options) {
				return backOff(() => fetch(url, options), {
					startingDelay: 500,
					numOfAttempts: 10,
					timeMultiple: 2,
					maxDelay: 4_000,
				});
			},
			async requestMiddleware(request) {
				const store = storeRef.current;
				if (!store) {
					throw new Error("Store not initialised yet");
				}
				const { user } = store.getState().user;
				if (user.type !== "signedIn") {
					return request;
				}

				let { jwtAccessToken } = user.user;
				const expiry = DateTime.fromMillis(jwtAccessToken.expiry);
				const diff = expiry.diffNow();
				if (diff.as("seconds") < refreshAccessThreshold.as("seconds")) {
					const result = await store.dispatch(refreshAuthenticatedUser());
					jwtAccessToken = unwrapResult(result);
				}

				const headers = new Headers(request.headers);
				headers.set(`Authorization`, jwtAccessToken.token);
				return {
					...request,
					headers,
				};
			},
			// This cuts the error down from a full json response + message
			responseMiddleware(response) {
				if (response instanceof ClientError && response.response.errors) {
					// eslint-disable-next-line no-param-reassign
					response.message = response.response.errors
						.map((e) => e.message)
						.join(", ");
				}
			},
		});
	}, [apiUrl]);
	const apiTempMediaStorage = useMemo(
		() => createFileStorage(apiClient),
		[apiClient],
	);

	// Auth service
	const authService = useMemo(
		() =>
			createAuthService({
				userPoolId: cognitoPoolConfig.id,
				appClientId: cognitoPoolConfig.appClientId,
				cookieDomain: platformCookieDomain,
				apiClient,
				originSite: siteCode,
			}),
		[cognitoPoolConfig, siteCode, platformCookieDomain, apiClient],
	);

	// Heavy process runner - manage workloads
	const runHeavyProcess = useMemo(() => createProcessRunner(), []);

	const gtm = useMemo(
		() => (gtmId ? initialiseGtm({ gtmId }) : createStubGtm()),
		[gtmId],
	);

	// Redux store
	// Note: React.StrictMode makes this run twice in dev - only runs once in prod
	const store = useMemo(() => {
		const initialAction = getPossibleInitialActionFromUrl(url);
		const isMobile = isMobileDetector({ tablet: true });
		const isSimpleCreatorOpen = isMobile;

		const injectFullMediaUrl = (image: SceneImage) => ({
			...image,
			imageSrc: `${mediaBaseUrl}/${image.imageSrc}`,
		});

		const newStore = createStoreWithLocalStoragePersistence(
			{
				gtm,
				authService,
				apiClient,
				shopifyClient,
				apiTempMediaStorage,
				tidioChatApi: tidioChatApiProxy,
				platformCookieDomain,
				openCvLoader,
				ipGeolocationService: createIpInfoGeolocationService(ipInfoToken),
				persistCountryService: createPersistCountryService({
					secure: window.location.protocol === "https:",
					cookieDomain: platformCookieDomain,
				}),
			},
			{
				reference: {
					...referenceInitialState,
					maxNumberOfSelectableBasePlates,
					scenes: scenes.map((s) => ({
						...s,
						fullSize: injectFullMediaUrl(s.fullSize),
						mobileSize: injectFullMediaUrl(s.mobileSize),
					})),
					isMobile,
					version,
					defaultCountryCode,
					siteCode,
					shopifyShopDomain: shopifyConfig.shopDomain,
					basePlateSizes,
					os: userAgent.toLowerCase().includes("android") ? "android" : "other",
					browserSource: isFacebookSourceBrowser(userAgent)
						? "facebook"
						: "other",
				},
				nav: {
					...navInitialState,
					initialAction,
					isSimpleCreatorOpen,
				},
				order: initialOrderState,
			},
		);
		newStore.subscribe(
			createBuildListener(newStore, openCvLoader, runHeavyProcess),
		);
		newStore.subscribe(createAutoSaveListener(newStore));

		storeRef.current = newStore;
		return newStore;
	}, [
		url,
		gtm,
		authService,
		apiClient,
		shopifyClient,
		shopifyConfig,
		apiTempMediaStorage,
		runHeavyProcess,
		tidioChatApiProxy,
		platformCookieDomain,
		ipInfoToken,
		userAgent,
		version,
		openCvLoader,
		siteCode,
		defaultCountryCode,
		basePlateSizes,
		mediaBaseUrl,
		maxNumberOfSelectableBasePlates,
	]);

	if (!store) {
		return <LoadingAnimation>Loading...</LoadingAnimation>;
	}

	return (
		<Provider store={store}>
			<WebsiteUrlProvider url={websiteUrl}>
				<OpenCvProvider openCv={openCvLoader}>
					<HeavyProcessRunnerProvider runProcess={runHeavyProcess}>
						<BeforeUnloadProvider>
							<App />
						</BeforeUnloadProvider>
					</HeavyProcessRunnerProvider>
				</OpenCvProvider>
			</WebsiteUrlProvider>
		</Provider>
	);
}

export default AppContainer;
