/**
 * @deprecated Not used in shopify site, so remove this functionality/possibility
 */
type StartPicToBrickAction = {
	readonly type: "startPicToBrick";
	readonly picId: string;
};

type LoadSourceImageAction = {
	readonly type: "loadSourceImage";
	readonly imageUrl: string;
};

type LoadProjectAction = {
	readonly type: "loadProject";
	readonly projectId: string;
};

type LoadBrickArtDesign = {
	readonly type: "loadBrickArtDesign";
	readonly designId: string;
};

type InitialAction =
	| StartPicToBrickAction
	| LoadSourceImageAction
	| LoadProjectAction
	| LoadBrickArtDesign;

function getPossibleInitialActionFromUrl(
	url: string,
): InitialAction | undefined {
	let parsed: URL;
	try {
		parsed = new URL(url);
	} catch (e) {
		console.error(e);
		return undefined;
	}

	// TODO: Not used in shopify site, so remove this functionality/possibility
	const picToBrickSource = parsed.searchParams.get("picToBrickSource");
	if (picToBrickSource) {
		return {
			type: "startPicToBrick",
			picId: picToBrickSource,
		};
	}

	const sourceImageUrl = parsed.searchParams.get("sourceImageUrl");
	if (sourceImageUrl) {
		return {
			type: "loadSourceImage",
			imageUrl: sourceImageUrl,
		};
	}

	const project = parsed.searchParams.get("project");
	if (project) {
		return {
			type: "loadProject",
			projectId: project,
		};
	}

	const brickArtDesignId = parsed.searchParams.get("brickArtDesignId");
	if (brickArtDesignId) {
		return {
			type: "loadBrickArtDesign",
			designId: brickArtDesignId,
		};
	}

	return undefined;
}

export { getPossibleInitialActionFromUrl };
export type { InitialAction };
