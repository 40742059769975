import { gql } from "graphql-request";
import { FaceBoundingBox } from "@brickme/project-core/src";

type GetSavedDesignSourcesQuery = {
	readonly savedDesignById: {
		readonly backgroundMaskImageUrl: string | null;
		readonly colorisationImageUrl: string | null;
		readonly enhanceFacesImageUrl: string | null;
		readonly faceBoundingBoxes: readonly FaceBoundingBox[] | null;
	};
};

type GetSavedDesignSourcesVariables = {
	readonly id: string;
};

// Guest might be using this query so can't be under viewer
const getSavedDesignSourcesQuery = gql`
	query getSavedDesignSourcesQuery($id: ID!) {
		savedDesignById(id: $id) {
			backgroundMaskImageUrl
			colorisationImageUrl
			enhanceFacesImageUrl
			faceBoundingBoxes {
				x
				y
				width
				height
			}
		}
	}
`;

export type { GetSavedDesignSourcesVariables, GetSavedDesignSourcesQuery };
export default getSavedDesignSourcesQuery;
