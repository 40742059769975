import {
	distanceFromMillimeters,
	DistanceSize,
	NumberOfBricks,
	realWorldPictureSize,
	PictureConfiguration,
} from "@brickme/project-core/src";
import type { PictureFrame, PictureAddOnVariant } from "~/model.d.ts";
import { getAddOnVariantCompatibleWithPicture } from "./add-on.ts";

function realWorldSizeWithFrame(
	numberOfBricks: NumberOfBricks,
	frame?: PictureFrame,
): DistanceSize {
	const pictureSize = realWorldPictureSize(numberOfBricks);
	if (!frame) {
		return pictureSize;
	}
	return {
		width: distanceFromMillimeters(
			pictureSize.width.mm + frame.borderSize.mm * 2,
		),
		height: distanceFromMillimeters(
			pictureSize.height.mm + frame.borderSize.mm * 2,
		),
	};
}

function getPossibleFrameVariantForPicture(
	picture: Pick<PictureConfiguration, "basePlateSize" | "numberOfBasePlates">,
	frame: PictureFrame,
): PictureAddOnVariant | undefined {
	return getAddOnVariantCompatibleWithPicture(frame.variants, picture);
}

function getPossibleFrameVariantInStockForPicture(
	picture: Pick<PictureConfiguration, "basePlateSize" | "numberOfBasePlates">,
	frame: PictureFrame,
): PictureAddOnVariant | undefined {
	const price = getPossibleFrameVariantForPicture(picture, frame);
	if (price && !price.inStock) {
		return undefined;
	}
	return price;
}

function isFrameCompatibleWithProject(
	frame: PictureFrame,
	picture: Pick<PictureConfiguration, "basePlateSize" | "numberOfBasePlates">,
): boolean {
	return !!getPossibleFrameVariantForPicture(picture, frame);
}

function getFrameVariantForPicture(
	picture: Pick<PictureConfiguration, "basePlateSize" | "numberOfBasePlates">,
	frame: PictureFrame,
): PictureAddOnVariant {
	const price = getPossibleFrameVariantForPicture(picture, frame);
	if (!price) {
		throw new Error("No price found");
	}
	return price;
}

export {
	realWorldSizeWithFrame,
	getFrameVariantForPicture,
	isFrameCompatibleWithProject,
	getPossibleFrameVariantForPicture,
	getPossibleFrameVariantInStockForPicture,
};
