import { gql } from "graphql-request";
import {
	FullSavedProject,
	transportSavedProjectFragment,
} from "./get-saved-designs-query.ts";

const duplicateProjectMutation = gql`
	mutation duplicateProject($id: ID!) {
		duplicateProject(id: $id) {
			...transportSavedProject
		}
	}
	${transportSavedProjectFragment}
`;

type DuplicateProjectMutation = {
	readonly duplicateProject: FullSavedProject;
};

type DuplicateProjectMutationVariables = {
	readonly id: string;
};

export type { DuplicateProjectMutation, DuplicateProjectMutationVariables };
export default duplicateProjectMutation;
