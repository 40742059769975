import {
	Bitmap,
	cloneBitmap,
	cloneBitmapToWritable,
	resizeBitmap,
} from "../bitmap/index.ts";
import {
	BuildBitmap,
	iterateBitmapMaskForegroundGenerator,
} from "../model/index.ts";
import { hslToRgb, rgbToHsl } from "../utils/colour-conversions.ts";
import {
	BuildOperationInput,
	BuildOperationResult,
	ClonedImageResult,
} from "./operation.ts";

type RequiredNonNull<T> = {
	[K in keyof T]-?: NonNullable<T[K]>;
};

function hasFixFaceColoursSources(
	sources: Pick<BuildOperationInput["sources"], "colorisation" | "facesMask">,
): sources is RequiredNonNull<
	Pick<BuildOperationInput["sources"], "colorisation" | "facesMask">
> {
	const { colorisation, facesMask } = sources;
	return !!colorisation && !!facesMask;
}

function* performFixFaceColours(
	inputImage: BuildBitmap,
	sources: Pick<
		RequiredNonNull<BuildOperationInput["sources"]>,
		"colorisation" | "facesMask"
	>,
): Generator<undefined, ClonedImageResult> {
	const { colorisation, facesMask } = sources;
	const clonedImage = yield* cloneBitmapToWritable(inputImage);

	yield* iterateBitmapMaskForegroundGenerator(
		facesMask,
		(colorisedRatio, i) => {
			const bgRatio = 1 - colorisedRatio;
			clonedImage.data[i + 0] =
				clonedImage.data[i + 0] * bgRatio +
				colorisation.data[i + 0] * colorisedRatio;
			clonedImage.data[i + 1] =
				clonedImage.data[i + 1] * bgRatio +
				colorisation.data[i + 1] * colorisedRatio;
			clonedImage.data[i + 2] =
				clonedImage.data[i + 2] * bgRatio +
				colorisation.data[i + 2] * colorisedRatio;
		},
		5_000,
	);
	return { type: "cloned", clonedImage };
}

function* fixFaceColoursOperation(
	inputImage: BuildBitmap,
	sources: BuildOperationInput["sources"],
): Generator<undefined, BuildOperationResult> {
	if (!hasFixFaceColoursSources(sources)) {
		return {
			type: "no-change",
			operationsMissingSources: ["Fix face colours"],
		};
	}

	return yield* performFixFaceColours(inputImage, sources);
}

function* performEnhanceFaces(
	original: BuildBitmap,
	enhanceFaces: Bitmap,
): Generator<undefined, ClonedImageResult> {
	let clonedImage: Bitmap;
	if (
		enhanceFaces.width !== original.width ||
		enhanceFaces.height !== original.height
	) {
		console.warn(
			`Enhance faces must be same size as base image. Was ${enhanceFaces.width}x${enhanceFaces.height} vs ${original.width}x${original.height}`,
		);
		clonedImage = yield* resizeBitmap(
			enhanceFaces,
			original.width,
			original.height,
		);
	} else {
		clonedImage = yield* cloneBitmap(enhanceFaces);
	}

	return {
		type: "cloned",
		clonedImage: { noBrickColour: original.noBrickColour, ...clonedImage },
	};
}

function* enhanceFacesOperation(
	original: BuildBitmap,
	{ enhanceFaces }: BuildOperationInput["sources"],
): Generator<undefined, BuildOperationResult> {
	if (!enhanceFaces) {
		return {
			type: "no-change",
			operationsMissingSources: ["Enhance faces"],
		};
	}

	return yield* performEnhanceFaces(original, enhanceFaces);
}

function* enhanceFacesAndFixFaceColoursOperation(
	inputImage: BuildBitmap,
	sources: BuildOperationInput["sources"],
): Generator<undefined, BuildOperationResult> {
	const hasFixFaceColours = hasFixFaceColoursSources(sources);

	if (!!sources.enhanceFaces && hasFixFaceColours) {
		const { colorisation, facesMask, enhanceFaces } = sources;

		// We're doing the color layer blend from photoshop here.
		// From adobe:
		// Creates a result color with the luminance of the base color and the hue and
		// saturation of the blend color. This preserves the gray levels in the image
		// and is useful for coloring monochrome images and for tinting color images.
		const clonedImage = yield* cloneBitmapToWritable(enhanceFaces);
		yield* iterateBitmapMaskForegroundGenerator(
			facesMask,
			(maskIncludeRatio, i) => {
				const maskExcludeRatio = 1 - maskIncludeRatio;
				// TODO: If "unrolled" these algorithms then sure could find many optimisations.
				const eHsl = rgbToHsl(
					enhanceFaces.data[i + 0],
					enhanceFaces.data[i + 1],
					enhanceFaces.data[i + 2],
				);
				const fHsl = rgbToHsl(
					colorisation.data[i + 0],
					colorisation.data[i + 1],
					colorisation.data[i + 2],
				);
				const resultHsl = {
					// Hue and saturation from the fore colour
					h: fHsl.h * maskIncludeRatio + eHsl.l * maskExcludeRatio,
					s: fHsl.s * maskIncludeRatio + eHsl.l * maskExcludeRatio,
					// Luminance from the base colour
					l: eHsl.l * maskIncludeRatio + fHsl.l * maskExcludeRatio,
				};
				const resultRgb = hslToRgb(resultHsl.h, resultHsl.s, resultHsl.l);
				clonedImage.data[i + 0] = resultRgb.r;
				clonedImage.data[i + 1] = resultRgb.g;
				clonedImage.data[i + 2] = resultRgb.b;
			},
			1_000,
		);
		// I don't think this is right - i.e. transferring noBrickColour from the original
		return {
			type: "cloned",
			clonedImage: { ...clonedImage, noBrickColour: inputImage.noBrickColour },
		};
	}

	if (hasFixFaceColours) {
		const result = yield* performFixFaceColours(inputImage, sources);
		return {
			...result,
			operationsMissingSources: ["Enhance faces"],
		};
	}

	if (sources.enhanceFaces) {
		const result = yield* performEnhanceFaces(inputImage, sources.enhanceFaces);
		return {
			...result,
			operationsMissingSources: ["Fix face colours"],
		};
	}

	return {
		type: "no-change",
		operationsMissingSources: ["Fix face colours", "Enhance faces"],
	};
}

function* faceFiltersOperation({
	intermediate: { image },
	config: { enhanceFaces, fixFaceColours },
	sources,
}: Pick<BuildOperationInput, "intermediate" | "config" | "sources">): Generator<
	undefined,
	BuildOperationResult
> {
	if (fixFaceColours && enhanceFaces) {
		return yield* enhanceFacesAndFixFaceColoursOperation(image, sources);
	}

	if (fixFaceColours) {
		return yield* fixFaceColoursOperation(image, sources);
	}

	if (enhanceFaces) {
		return yield* enhanceFacesOperation(image, sources);
	}

	return { type: "no-change" };
}

export default faceFiltersOperation;
