import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { isEqual } from "lodash-es";
import { DateTime } from "luxon";
import { PenMark } from "@brickme/project-core/src";
import { requireOpenProject, State } from "../state.ts";

// Note: Keep as plural so can merge multiple add pen mark commands together
// for a better undo experience
function addPenMarksReducer(
	state: Draft<State>,
	action: PayloadAction<readonly PenMark[]>,
): void {
	const { project } = requireOpenProject(state);
	const { currentPicture } = project;
	const newPenMarks = action.payload;

	// TODO: Can maybe only do this if things change
	project.currentPicture.updatedAt = DateTime.utc().toMillis();
	project.updatedAt = project.currentPicture.updatedAt;

	const { pen } = currentPicture;
	const remainingMarks = pen.filter(
		(mark) => !newPenMarks.some((p) => isEqual(p.position, mark.position)),
	);
	currentPicture.pen = [...remainingMarks, ...newPenMarks];
}

export default addPenMarksReducer;
