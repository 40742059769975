import React, { ReactElement } from "react";
import useAppSelector from "~/hooks/use-app-selector.ts";
import StatusMainPanel from "~/components/ui/status-main-panel.tsx";
import LoadingAnimation from "~/components/ui/loading-animation.tsx";
import { isOnceOffError, isOnceOffNotComplete } from "~/utils/loading.ts";
import { selectPricingModel } from "~/features/reference/selectors.ts";

function PricingModelLoadStatus(): ReactElement {
	const load = useAppSelector(selectPricingModel);

	return (
		<StatusMainPanel>
			{isOnceOffNotComplete(load) && (
				<LoadingAnimation>Loading prices...</LoadingAnimation>
			)}
			{isOnceOffError(load) && (
				<div className="form-error">Error loading prices: {load.message}</div>
			)}
		</StatusMainPanel>
	);
}

export default PricingModelLoadStatus;
