/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, Draft } from "@reduxjs/toolkit";
import { castDraft } from "immer";
import { backOff } from "exponential-backoff";
import { findOnlyOrThrow } from "@dhau/lang-extras";
import {
	Palette,
	transportBrickColourToCore,
	PricingModel,
	areaFromMetres2,
} from "@brickme/project-core/src";
import { Country } from "~/frontend-common/localisation.ts";
import getPicToBrickCategories, {
	GetPicToBrickCategoriesQuery,
} from "~/api/get-pic-to-brick-categories.ts";
import getSystemPaletteQuery, {
	GetSystemPaletteQuery,
} from "~/api/get-system-palette.ts";
import getPricingModelQuery, {
	GetPricingModelQuery,
} from "~/api/get-pricing-model.ts";
import {
	getCountriesQuery,
	GetCountriesQuery,
} from "~/shopify/get-countries-query.ts";
import qetAllCollectionsQuery, {
	GetAllCollectionsQuery,
} from "~/shopify/get-all-collections-query.ts";
import { StoreThunkApiConfig } from "~/store-config.ts";
import type {
	PictureFrame,
	HangingStrips,
	PictureAddOnVariant,
} from "~/model.d.ts";
import {
	errorOnceOffLoad,
	loadedOnceOffLoad,
	loadingOnceOffLoad,
	isOnceOffLoaded,
	OnceOffLoad,
	pendingOnceOffLoad,
	performOnceOffLoad,
} from "~/utils/loading.ts";
import { ShopifyPrices } from "./shopify-prices.ts";
import {
	loadPicPrices,
	loadHangingStrips,
	loadPreassemblyPrices,
	loadFrames,
} from "./load-prices.ts";
import waitForCountries from "./wait-for-countries.ts";
import { State, initialState } from "./state.ts";

const name = "reference";

const ensurePicToBrickCategoriesLoaded = createAsyncThunk<
	GetPicToBrickCategoriesQuery["picToBrickCategories"],
	undefined,
	StoreThunkApiConfig<{ reference: State }>
>(
	`${name}/ensurePicToBrickCategoriesLoaded`,
	async (_, { extra: { apiClient }, getState }) => {
		const {
			reference: { picToBrickCategories },
		} = getState();
		if (picToBrickCategories) {
			return picToBrickCategories;
		}
		const result = await apiClient.request<GetPicToBrickCategoriesQuery>(
			getPicToBrickCategories,
		);
		return result.picToBrickCategories;
	},
);

const ensureShopifyCollectionsLoaded = createAsyncThunk<
	State["shopifyCollections"],
	undefined,
	StoreThunkApiConfig<{ reference: State }>
>(
	`${name}/ensureShopifyCollectionsLoaded`,
	async (_, { extra: { shopifyClient }, getState }) => {
		const {
			reference: { shopifyCollections },
		} = getState();
		// Might result in double load. Can't check against pending because pending
		// action reducer just set to loading
		if (shopifyCollections.type === "loaded") {
			return shopifyCollections;
		}
		return performOnceOffLoad(async () => {
			const result = await shopifyClient.request<GetAllCollectionsQuery>(
				qetAllCollectionsQuery,
			);
			return result.collections.edges.map((edge) => edge.node);
		});
	},
);

const loadSystemPalette = createAsyncThunk<
	OnceOffLoad<Palette>,
	undefined,
	StoreThunkApiConfig
>(`${name}/loadSystemPalette`, async (_, { extra: { apiClient } }) =>
	performOnceOffLoad(async () => {
		const result = await apiClient.request<GetSystemPaletteQuery>(
			getSystemPaletteQuery,
		);
		return result.brickPalette.map(transportBrickColourToCore).map((brick) => ({
			brick,
			limit: undefined,
		}));
	}),
);

const loadPricingModel = createAsyncThunk<
	OnceOffLoad<PricingModel>,
	undefined,
	StoreThunkApiConfig
>(`${name}/loadPricingModel`, async (_, { extra: { apiClient } }) =>
	performOnceOffLoad(async () => {
		const result =
			await apiClient.request<GetPricingModelQuery>(getPricingModelQuery);
		const { basePlateSizePrices, discountBrackets, ...pricingModel } =
			result.pricingModel;
		return {
			...pricingModel,
			discountBrackets: discountBrackets.map(({ areaMetres, discount }) => ({
				area: areaFromMetres2(areaMetres),
				discount,
			})),
			basePlateSizePrices: basePlateSizePrices.map(({ size, price }) => ({
				size,
				price: {
					dollars: parseFloat(price.amount),
					currencyCode: price.currencyCode,
				},
			})),
		};
	}),
);

const ensurePicPricesLoaded = createAsyncThunk<
	| {
			shopifyPrices: ShopifyPrices;
			hangingStrips?: HangingStrips;
			frames: readonly PictureFrame[];
			preassemblyOptions: readonly PictureAddOnVariant[];
	  }
	| undefined,
	string,
	StoreThunkApiConfig<{ reference: State }>
>(
	`${name}/ensurePicPricesLoaded`,
	async (countryCode, { getState, extra: { shopifyClient, apiClient } }) => {
		const {
			reference: { picPricesByCountryCode, basePlateSizes },
		} = getState();
		if (countryCode in picPricesByCountryCode) {
			return undefined;
		}

		const countries = await waitForCountries(getState);

		// A bit hacky, but works for our purposes because only worried about prices
		const { currencyCode } = findOnlyOrThrow(
			countries,
			(c) => c.code === countryCode,
			`Country not found when ensuring pic prices ${countryCode}`,
		);

		const [picPrices, hangingStripsCombo, preassemblyCombo, framesCombo] =
			await Promise.all([
				loadPicPrices(apiClient, countryCode, currencyCode),
				loadHangingStrips(shopifyClient, countryCode),
				loadPreassemblyPrices(shopifyClient, countryCode),
				loadFrames(shopifyClient, countryCode, basePlateSizes),
			]);
		return {
			shopifyPrices: {
				pic: picPrices,
				hangingStripsPrice: hangingStripsCombo?.price,
				preassemblyPricesByVariantId: preassemblyCombo.prices,
				framePricesByVariantId: framesCombo.prices,
			},
			preassemblyOptions: preassemblyCombo.options,
			frames: framesCombo.frames,
			hangingStrips: hangingStripsCombo?.hangingStrips,
		};
	},
);

const loadCountries = createAsyncThunk<
	readonly Country[],
	undefined,
	StoreThunkApiConfig
>(`${name}/loadCountries`, async (_, { extra: { shopifyClient } }) =>
	backOff(
		async () => {
			const data =
				await shopifyClient.request<GetCountriesQuery>(getCountriesQuery);
			if (!data) {
				throw new Error("Error loading countries - no data");
			}
			return data.localization.availableCountries.map((a) => ({
				code: a.isoCode,
				name: a.name,
				currencyCode: a.currency.isoCode,
				currencySymbol: a.currency.symbol,
			}));
		},
		{
			timeMultiple: 1.5,
		},
	),
);

const slice = createSlice({
	name,
	initialState,
	reducers: {},
	extraReducers: {
		[loadCountries.pending.type]: (state: Draft<State>) => {
			state.countries = pendingOnceOffLoad;
		},
		[loadCountries.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof loadCountries)["fulfilled"]>>,
		) => {
			state.countries = loadedOnceOffLoad(action.payload);
		},
		[loadCountries.rejected.type]: (state: Draft<State>) => {
			state.countries = errorOnceOffLoad("Error loading countries");
		},
		[loadSystemPalette.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof loadSystemPalette)["fulfilled"]>>,
		) => {
			state.systemPalette = action.payload;
		},
		[ensureShopifyCollectionsLoaded.pending.type]: (state: Draft<State>) => {
			if (state.shopifyCollections.type === "pending") {
				state.shopifyCollections = loadingOnceOffLoad;
			}
		},
		[ensureShopifyCollectionsLoaded.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<
				ReturnType<(typeof ensureShopifyCollectionsLoaded)["fulfilled"]>
			>,
		) => {
			state.shopifyCollections = action.payload;
		},
		[loadPricingModel.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof loadPricingModel)["fulfilled"]>>,
		) => {
			state.pricingModel = action.payload;
		},
		[ensurePicToBrickCategoriesLoaded.fulfilled.type]: (
			state: Draft<State>,
			action: ReturnType<
				(typeof ensurePicToBrickCategoriesLoaded)["fulfilled"]
			>,
		) => {
			state.picToBrickCategories = castDraft(action.payload);
		},
		[ensurePicPricesLoaded.pending.type]: (state: Draft<State>) => {
			if (!isOnceOffLoaded(state.hangingStrips)) {
				state.hangingStrips = loadingOnceOffLoad;
			}
			if (!isOnceOffLoaded(state.frames)) {
				state.frames = loadingOnceOffLoad;
			}
			if (!isOnceOffLoaded(state.preassemblyOptions)) {
				state.preassemblyOptions = loadingOnceOffLoad;
			}
		},
		[ensurePicPricesLoaded.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof ensurePicPricesLoaded)["fulfilled"]>>,
		) => {
			if (!action.payload) {
				return;
			}

			const { shopifyPrices, hangingStrips, frames, preassemblyOptions } =
				action.payload;
			state.picPricesByCountryCode[action.meta.arg] = shopifyPrices;
			state.hangingStrips = loadedOnceOffLoad(hangingStrips);
			state.frames = loadedOnceOffLoad(frames);
			state.preassemblyOptions = loadedOnceOffLoad(preassemblyOptions);
		},
		[ensurePicPricesLoaded.rejected.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof ensurePicPricesLoaded)["rejected"]>>,
		) => {
			state.hangingStrips = errorOnceOffLoad(
				action.error.message ?? "Unknown error",
			);
			state.frames = errorOnceOffLoad(action.error.message ?? "Unknown error");
			state.preassemblyOptions = errorOnceOffLoad(
				action.error.message ?? "Unknown error",
			);
		},
	},
});

export {
	ensurePicToBrickCategoriesLoaded,
	loadSystemPalette,
	loadPricingModel,
	ensurePicPricesLoaded,
	loadCountries,
	ensureShopifyCollectionsLoaded,
};
export default slice.reducer;
