import { TidioChatApi, VisitorData } from "~/frontend-common/tidio.ts";

export type TidioChatApiProxy = TidioChatApi & {
	setApi(api: TidioChatApi): void;
};

export function createTidioChatApiProxy(): TidioChatApiProxy {
	let apiRef: TidioChatApi | undefined;
	const trackCalls: string[] = [];
	const visitorCalls: VisitorData[] = [];

	const performCall = <T>(
		calls: T[],
		input: T,
		apiCall: (api: TidioChatApi, input: T) => void,
	) => {
		if (!apiRef) {
			calls.push(input);
			return;
		}
		apiCall(apiRef, input);
	};

	return {
		setApi(api) {
			apiRef = api;
			while (trackCalls.length > 0) {
				const i = trackCalls.pop();
				if (i) {
					api.track(i);
				}
			}
			while (visitorCalls.length > 0) {
				const i = visitorCalls.pop();
				if (i) {
					api.setVisitorData(i);
				}
			}
		},
		setVisitorData(visitorData: VisitorData) {
			performCall(visitorCalls, visitorData, (a, i) => a.setVisitorData(i));
		},
		track(event: string) {
			performCall(trackCalls, event, (a, i) => a.track(i));
		},
	};
}
