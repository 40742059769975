import React, { useState } from "react";
import { produce } from "immer";
import Modal from "~/components/modal.tsx";
import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import useAppSelector from "~/hooks/use-app-selector.ts";
import { loadSavedProject } from "~/features/user/store-slice.ts";
import { duplicateProject } from "~/features/save-project/store-slice.ts";
import { selectOptionalOpenProject } from "~/features/workspace/selectors.ts";
import { defaultAutoPaletteModeNumberOfColours } from "~/features/workspace/create-default-picture-configuration.ts";
import { FullSavedProject } from "~/api/get-saved-designs-query.ts";

type ConfirmOpenSimpleCreatorModalProps = {
	readonly show: boolean;
	readonly onCancel: () => void;
	readonly onConfirm: () => void;
};

function ConfirmOpenSimpleCreatorModal({
	show,
	onCancel,
	onConfirm: providedOnConfirm,
}: ConfirmOpenSimpleCreatorModalProps) {
	const dispatch = useAppDispatch();
	const [isLoading, setLoading] = useState(false);
	const openProject = useAppSelector(selectOptionalOpenProject);
	const onConfirm = async () => {
		if (!openProject) {
			providedOnConfirm();
			return;
		}

		setLoading(true);
		try {
			const { payload } = await dispatch(duplicateProject());
			// Simple creator only supports auto palette
			const newProject = produce(payload as FullSavedProject, (draft) => {
				draft.currentPicture.paletteMode = {
					type: "auto",
					numberOfColours: defaultAutoPaletteModeNumberOfColours,
				};
			});
			await dispatch(loadSavedProject(newProject));
			providedOnConfirm();
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal
			show={show}
			title="You are about to switch to the simple version of the Brick Me Creator"
			onClose={onCancel}
		>
			<Modal.Text>
				The Simple Creator is more intuitive and easy to use, but it has limited
				features compared to the advanced version. Your current project may not
				be supported, so we will save it for you and open a new project. Any
				changes you made so far will not be transferred to the new project.
			</Modal.Text>
			<Modal.Actions
				actions={[
					{
						text: "Go back",
						onClick: onCancel,
						type: "ghost",
						disabled: isLoading,
					},
					{
						text: isLoading ? "Creating..." : "Continue",
						onClick: onConfirm,
						disabled: isLoading,
					},
				]}
			/>
		</Modal>
	);
}

export default ConfirmOpenSimpleCreatorModal;
