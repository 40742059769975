import { backOff } from "exponential-backoff";
import { State } from "./state.ts";
import { isOnceOffLoaded } from "~/utils/loading.ts";

async function waitForCountries(getState: () => { reference: State }) {
	return backOff(
		async () => {
			const {
				reference: { countries },
			} = getState();
			if (isOnceOffLoaded(countries)) {
				return Promise.resolve(countries.data);
			}
			throw new Error("Countries not loaded");
		},
		{
			retry: (e) => e.message === "Countries not loaded",
			maxDelay: 200,
			timeMultiple: 1,
			numOfAttempts: 1_000,
		},
	);
}

export default waitForCountries;
