// Taken from jimp resize
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable class-methods-use-this */
/* eslint-disable require-yield */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { uint8ArrayFromArrayBuffer } from "../utils/uint8-array.ts";
import { Bitmap, WritableBitmap } from "./types.ts";

function* bufferIdentity<T extends ReadIndexBuffer>(
	buffer: T,
): Generator<undefined, T> {
	return buffer;
}

type ReadIndexBuffer = {
	readonly [key in number]: number;
};

type BufferLike = Uint8Array | Float64Array | Float32Array;

type Uint8ArraySource = Readonly<Record<number, number>>;

// TODO: This is really bad! Might be modified later
// const defaultBufferLike = new Uint8Array();

const fourthChannel = true;
const channelsNum = 4;

class Resize {
	private readonly widthOriginal: number;

	private readonly heightOriginal: number;

	private readonly targetWidth: number;

	private readonly targetHeight: number;

	private readonly targetWidthMultipliedByChannels: number;

	private readonly originalWidthMultipliedByChannels: number;

	private readonly originalHeightMultipliedByChannels: number;

	private readonly widthPassResultSize: number;

	private readonly finalResultSize: number;

	private resizeWidth: (
		buffer: Uint8ArraySource,
	) => Generator<undefined, BufferLike> = bufferIdentity as any;

	private ratioWeightWidthPass = 0;

	private resizeHeight: (
		buffer: BufferLike,
	) => Generator<undefined, BufferLike> = bufferIdentity;

	private ratioWeightHeightPass = 0;

	private widthBuffer: BufferLike = undefined as any;

	private heightBuffer: BufferLike = undefined as any;

	private outputHeightWorkBench: BufferLike = undefined as any;

	private outputHeightWorkBenchOpaquePixelsCount: BufferLike = undefined as any;

	private outputWidthWorkBench: BufferLike = undefined as any;

	private outputWidthWorkBenchOpaquePixelsCount: BufferLike = undefined as any;

	public constructor(
		widthOriginal: number,
		heightOriginal: number,
		targetWidth: number,
		targetHeight: number,
	) {
		this.widthOriginal = Math.abs(Math.floor(widthOriginal) || 0);
		this.heightOriginal = Math.abs(Math.floor(heightOriginal) || 0);
		this.targetWidth = Math.abs(Math.floor(targetWidth) || 0);
		this.targetHeight = Math.abs(Math.floor(targetHeight) || 0);
		this.targetWidthMultipliedByChannels = this.targetWidth * channelsNum;
		this.originalWidthMultipliedByChannels = this.widthOriginal * channelsNum;
		this.originalHeightMultipliedByChannels = this.heightOriginal * channelsNum;
		this.widthPassResultSize =
			this.targetWidthMultipliedByChannels * this.heightOriginal;
		this.finalResultSize =
			this.targetWidthMultipliedByChannels * this.targetHeight;
		this.initialize();
	}

	private initialize() {
		// Perform some checks:
		if (
			this.widthOriginal > 0 &&
			this.heightOriginal > 0 &&
			this.targetWidth > 0 &&
			this.targetHeight > 0
		) {
			this.configurePasses();
		} else {
			throw new Error("Invalid settings specified for the resizer.");
		}
	}

	private configurePasses() {
		if (this.widthOriginal === this.targetWidth) {
			// Bypass the width resizer pass:
			this.resizeWidth = this.bypassResizer.bind(this) as any;
		} else {
			// Setup the width resizer pass:
			this.ratioWeightWidthPass = this.widthOriginal / this.targetWidth;

			if (this.ratioWeightWidthPass < 1) {
				this.initializeFirstPassBuffers(true);
				this.resizeWidth = this.resizeWidthInterpolatedRGBA.bind(this);
			} else {
				this.initializeFirstPassBuffers(false);
				this.resizeWidth = this._resizeWidthRGBChannels.bind(this);
				// this.resizeWidth =
				//   colorChannels === 4 ? this.resizeWidthRGBA : this.resizeWidthRGB;
			}
		}

		if (this.heightOriginal === this.targetHeight) {
			// Bypass the height resizer pass:
			this.resizeHeight = this.bypassResizer.bind(this);
		} else {
			// Setup the height resizer pass:
			this.ratioWeightHeightPass = this.heightOriginal / this.targetHeight;

			if (this.ratioWeightHeightPass < 1) {
				this.initializeSecondPassBuffers(true);
				this.resizeHeight = this.resizeHeightInterpolated.bind(this);
			} else {
				this.initializeSecondPassBuffers(false);
				this.resizeHeight = this._resizeHeightRGBChannels.bind(this);
			}
		}
	}

	private _resizeWidthInterpolatedRGBChannels(
		source: Uint8ArraySource,
		// fourthChannel: boolean
	): BufferLike {
		// const channelsNum = fourthChannel ? 4 : 3;
		const ratioWeight = this.ratioWeightWidthPass;
		const outputBuffer = this.widthBuffer;
		let weight = 0;
		let finalOffset = 0;
		let pixelOffset = 0;
		let firstWeight = 0;
		let secondWeight = 0;
		let targetPosition; // Handle for only one interpolation input being valid for start calculation:

		for (
			targetPosition = 0;
			weight < 1 / 3;
			targetPosition += channelsNum, weight += ratioWeight
		) {
			for (
				finalOffset = targetPosition, pixelOffset = 0;
				finalOffset < this.widthPassResultSize;
				pixelOffset += this.originalWidthMultipliedByChannels,
					finalOffset += this.targetWidthMultipliedByChannels
			) {
				outputBuffer[finalOffset] = source[pixelOffset];
				outputBuffer[finalOffset + 1] = source[pixelOffset + 1];
				outputBuffer[finalOffset + 2] = source[pixelOffset + 2];
				if (fourthChannel)
					outputBuffer[finalOffset + 3] = source[pixelOffset + 3];
			}
		} // Adjust for overshoot of the last pass's counter:

		weight -= 1 / 3;
		let interpolationWidthSourceReadStop;

		for (
			interpolationWidthSourceReadStop = this.widthOriginal - 1;
			weight < interpolationWidthSourceReadStop;
			targetPosition += channelsNum, weight += ratioWeight
		) {
			// Calculate weightings:
			secondWeight = weight % 1;
			firstWeight = 1 - secondWeight; // Interpolate:

			for (
				finalOffset = targetPosition,
					pixelOffset = Math.floor(weight) * channelsNum;
				finalOffset < this.widthPassResultSize;
				pixelOffset += this.originalWidthMultipliedByChannels,
					finalOffset += this.targetWidthMultipliedByChannels
			) {
				outputBuffer[finalOffset + 0] =
					source[pixelOffset + 0] * firstWeight +
					source[pixelOffset + channelsNum + 0] * secondWeight;
				outputBuffer[finalOffset + 1] =
					source[pixelOffset + 1] * firstWeight +
					source[pixelOffset + channelsNum + 1] * secondWeight;
				outputBuffer[finalOffset + 2] =
					source[pixelOffset + 2] * firstWeight +
					source[pixelOffset + channelsNum + 2] * secondWeight;
				if (fourthChannel)
					outputBuffer[finalOffset + 3] =
						source[pixelOffset + 3] * firstWeight +
						source[pixelOffset + channelsNum + 3] * secondWeight;
			}
		} // Handle for only one interpolation input being valid for end calculation:

		for (
			interpolationWidthSourceReadStop =
				this.originalWidthMultipliedByChannels - channelsNum;
			targetPosition < this.targetWidthMultipliedByChannels;
			targetPosition += channelsNum
		) {
			for (
				finalOffset = targetPosition,
					pixelOffset = interpolationWidthSourceReadStop;
				finalOffset < this.widthPassResultSize;
				pixelOffset += this.originalWidthMultipliedByChannels,
					finalOffset += this.targetWidthMultipliedByChannels
			) {
				outputBuffer[finalOffset] = source[pixelOffset];
				outputBuffer[finalOffset + 1] = source[pixelOffset + 1];
				outputBuffer[finalOffset + 2] = source[pixelOffset + 2];
				if (fourthChannel)
					outputBuffer[finalOffset + 3] = source[pixelOffset + 3];
			}
		}

		return outputBuffer;
	}

	// Note: something about generator is inefficient when have a bunch of shared state
	// or possibly also related to the inner loops (more likely related?)
	private resizeWidthRgbaChannelsLoop(
		buffer: Uint8ArraySource,
		calcVars: {
			ratioWeight: number;
			ratioWeightDivisor: number;
			nextLineOffsetOriginalWidth: number;
			nextLineOffsetTargetWidth: number;
			output: BufferLike;
			outputBuffer: BufferLike;
			trustworthyColorsCount: BufferLike;
			weight: number;
			amountToNext: number;
			actualPosition: number;
			currentPosition: number;
			outputOffset: number;
			multiplier: number;
		},
	) {
		for (let line = 0; line < this.originalHeightMultipliedByChannels; ) {
			calcVars.output[line++] = 0;
			calcVars.output[line++] = 0;
			calcVars.output[line++] = 0;

			if (fourthChannel) {
				calcVars.output[line++] = 0;
				calcVars.trustworthyColorsCount[line / channelsNum - 1] = 0;
			}
		}

		calcVars.weight = calcVars.ratioWeight;

		do {
			calcVars.amountToNext =
				1 + calcVars.actualPosition - calcVars.currentPosition;
			calcVars.multiplier = Math.min(calcVars.weight, calcVars.amountToNext);

			let pixelOffset1 = calcVars.actualPosition;
			for (
				let line = 0;
				line < this.originalHeightMultipliedByChannels;
				pixelOffset1 += calcVars.nextLineOffsetOriginalWidth
			) {
				const r = buffer[pixelOffset1];
				const g = buffer[++pixelOffset1];
				const b = buffer[++pixelOffset1];
				const a = fourthChannel ? buffer[++pixelOffset1] : 255; // Ignore RGB values if pixel is completely transparent

				calcVars.output[line++] += (a ? r : 0) * calcVars.multiplier;
				calcVars.output[line++] += (a ? g : 0) * calcVars.multiplier;
				calcVars.output[line++] += (a ? b : 0) * calcVars.multiplier;

				if (fourthChannel) {
					calcVars.output[line++] += a * calcVars.multiplier;
					calcVars.trustworthyColorsCount[line / channelsNum - 1] += a
						? calcVars.multiplier
						: 0;
				}
			}

			if (calcVars.weight >= calcVars.amountToNext) {
				calcVars.actualPosition += channelsNum;
				calcVars.currentPosition = calcVars.actualPosition;
				calcVars.weight -= calcVars.amountToNext;
			} else {
				calcVars.currentPosition += calcVars.weight;
				break;
			}
		} while (
			calcVars.weight > 0 &&
			calcVars.actualPosition < this.originalWidthMultipliedByChannels
		);

		let pixelOffset = calcVars.outputOffset;
		for (
			let line = 0;
			line < this.originalHeightMultipliedByChannels;
			pixelOffset += calcVars.nextLineOffsetTargetWidth
		) {
			calcVars.weight = fourthChannel
				? calcVars.trustworthyColorsCount[line / channelsNum]
				: 1;
			calcVars.multiplier = fourthChannel
				? calcVars.weight
					? 1 / calcVars.weight
					: 0
				: calcVars.ratioWeightDivisor;
			calcVars.outputBuffer[pixelOffset] =
				calcVars.output[line++] * calcVars.multiplier;
			calcVars.outputBuffer[++pixelOffset] =
				calcVars.output[line++] * calcVars.multiplier;
			calcVars.outputBuffer[++pixelOffset] =
				calcVars.output[line++] * calcVars.multiplier;
			if (fourthChannel) {
				calcVars.outputBuffer[++pixelOffset] =
					calcVars.output[line++] * calcVars.ratioWeightDivisor;
			}
		}
		calcVars.outputOffset += channelsNum;
	}

	private *_resizeWidthRGBChannels(
		buffer: Uint8ArraySource,
	): Generator<undefined, BufferLike> {
		// const channelsNum = fourthChannel ? 4 : 3;
		const calcVars = {
			ratioWeight: this.ratioWeightWidthPass,
			ratioWeightDivisor: 1 / this.ratioWeightWidthPass,
			nextLineOffsetOriginalWidth:
				this.originalWidthMultipliedByChannels - channelsNum + 1,
			nextLineOffsetTargetWidth:
				this.targetWidthMultipliedByChannels - channelsNum + 1,
			output: this.outputWidthWorkBench,
			outputBuffer: this.widthBuffer,
			trustworthyColorsCount: this.outputWidthWorkBenchOpaquePixelsCount,
			weight: 0,
			amountToNext: 0,
			actualPosition: 0,
			currentPosition: 0,
			outputOffset: 0,
			multiplier: 1,
		};

		do {
			this.resizeWidthRgbaChannelsLoop(buffer, calcVars);
			yield;
		} while (calcVars.outputOffset < this.targetWidthMultipliedByChannels);

		return calcVars.outputBuffer;
	}

	private resizeHeightLoop(
		source: ReadIndexBuffer,
		calcVars: {
			ratioWeightDivisor: number;
			actualPosition: number;
			currentPosition: number;
			outputOffset: number;
		},
	): void {
		for (let i = 0; i < this.targetWidthMultipliedByChannels; ) {
			this.outputHeightWorkBench[i++] = 0;
			this.outputHeightWorkBench[i++] = 0;
			this.outputHeightWorkBench[i++] = 0;

			if (fourthChannel) {
				this.outputHeightWorkBench[i++] = 0;
				this.outputHeightWorkBenchOpaquePixelsCount[i / 4 - 1] = 0;
			}
		}

		let weight = this.ratioWeightHeightPass;

		do {
			const amountToNext =
				1 + calcVars.actualPosition - calcVars.currentPosition;
			const multiplier = Math.min(weight, amountToNext);
			let caret = calcVars.actualPosition;

			for (let j = 0; j < this.targetWidthMultipliedByChannels; ) {
				const r = source[caret++];
				const g = source[caret++];
				const b = source[caret++];
				const a = fourthChannel ? source[caret++] : 255; // Ignore RGB values if pixel is completely transparent

				this.outputHeightWorkBench[j++] += (a ? r : 0) * multiplier;
				this.outputHeightWorkBench[j++] += (a ? g : 0) * multiplier;
				this.outputHeightWorkBench[j++] += (a ? b : 0) * multiplier;

				if (fourthChannel) {
					this.outputHeightWorkBench[j++] += a * multiplier;
					this.outputHeightWorkBenchOpaquePixelsCount[j / 4 - 1] += a
						? multiplier
						: 0;
				}
			}

			if (weight >= amountToNext) {
				calcVars.actualPosition = caret;
				calcVars.currentPosition = calcVars.actualPosition;
				weight -= amountToNext;
			} else {
				calcVars.currentPosition += weight;
				break;
			}
		} while (weight > 0 && calcVars.actualPosition < this.widthPassResultSize);

		for (let k = 0; k < this.targetWidthMultipliedByChannels; ) {
			const pixelWeight = fourthChannel
				? this.outputHeightWorkBenchOpaquePixelsCount[k / 4]
				: 1;
			const multiplier = fourthChannel
				? pixelWeight
					? 1 / pixelWeight
					: 0
				: calcVars.ratioWeightDivisor;
			this.heightBuffer[calcVars.outputOffset++] = Math.round(
				this.outputHeightWorkBench[k++] * multiplier,
			);
			this.heightBuffer[calcVars.outputOffset++] = Math.round(
				this.outputHeightWorkBench[k++] * multiplier,
			);
			this.heightBuffer[calcVars.outputOffset++] = Math.round(
				this.outputHeightWorkBench[k++] * multiplier,
			);

			if (fourthChannel) {
				this.heightBuffer[calcVars.outputOffset++] = Math.round(
					this.outputHeightWorkBench[k++] * calcVars.ratioWeightDivisor,
				);
			}
		}
	}

	private *_resizeHeightRGBChannels(
		buffer: BufferLike,
		// fourthChannel: boolean
	): Generator<undefined, BufferLike> {
		const calcVars = {
			ratioWeightDivisor: 1 / this.ratioWeightHeightPass,
			actualPosition: 0,
			currentPosition: 0,
			outputOffset: 0,
		};

		do {
			this.resizeHeightLoop(buffer, calcVars);
			yield;
		} while (calcVars.outputOffset < this.finalResultSize);

		return this.heightBuffer;
	}

	// private *resizeWidthInterpolatedRGB(
	//   buffer: ReadIndexBuffer
	// ): Generator<undefined, ReadIndexBuffer> {
	//   return this._resizeWidthInterpolatedRGBChannels(buffer, false);
	// }

	private *resizeWidthInterpolatedRGBA(
		source: Uint8ArraySource,
	): Generator<undefined, BufferLike> {
		return this._resizeWidthInterpolatedRGBChannels(source /* , true */);
	}

	// private *resizeWidthRGB(
	//   buffer: ReadIndexBuffer
	// ): Generator<undefined, ReadIndexBuffer> {
	//   return this._resizeWidthRGBChannels(buffer /*, false */);
	// }

	// private *resizeWidthRGBA(
	//   buffer: ReadIndexBuffer
	// ): Generator<undefined, ReadIndexBuffer> {
	//   return this._resizeWidthRGBChannels(buffer /*, true*/);
	// }

	private *resizeHeightInterpolated(
		source: ReadIndexBuffer,
	): Generator<undefined, BufferLike> {
		const ratioWeight = this.ratioWeightHeightPass;
		const outputBuffer = this.heightBuffer;
		let weight = 0;
		let finalOffset = 0;
		let pixelOffset = 0;
		let pixelOffsetAccumulated = 0;
		let pixelOffsetAccumulated2 = 0;
		let firstWeight = 0;
		let secondWeight = 0;
		let interpolationHeightSourceReadStop; // Handle for only one interpolation input being valid for start calculation:

		for (; weight < 1 / 3; weight += ratioWeight) {
			for (
				pixelOffset = 0;
				pixelOffset < this.targetWidthMultipliedByChannels;

			) {
				outputBuffer[finalOffset++] = Math.round(source[pixelOffset++]);
			}
		} // Adjust for overshoot of the last pass's counter:

		weight -= 1 / 3;

		for (
			interpolationHeightSourceReadStop = this.heightOriginal - 1;
			weight < interpolationHeightSourceReadStop;
			weight += ratioWeight
		) {
			// Calculate weightings:
			secondWeight = weight % 1;
			firstWeight = 1 - secondWeight; // Interpolate:

			pixelOffsetAccumulated =
				Math.floor(weight) * this.targetWidthMultipliedByChannels;
			pixelOffsetAccumulated2 =
				pixelOffsetAccumulated + this.targetWidthMultipliedByChannels;

			for (
				pixelOffset = 0;
				pixelOffset < this.targetWidthMultipliedByChannels;
				++pixelOffset
			) {
				outputBuffer[finalOffset++] = Math.round(
					source[pixelOffsetAccumulated++] * firstWeight +
						source[pixelOffsetAccumulated2++] * secondWeight,
				);
			}
		} // Handle for only one interpolation input being valid for end calculation:

		while (finalOffset < this.finalResultSize) {
			for (
				pixelOffset = 0,
					pixelOffsetAccumulated =
						interpolationHeightSourceReadStop *
						this.targetWidthMultipliedByChannels;
				pixelOffset < this.targetWidthMultipliedByChannels;
				++pixelOffset
			) {
				outputBuffer[finalOffset++] = Math.round(
					source[pixelOffsetAccumulated++],
				);
			}
		}

		return outputBuffer;
	}

	// private *resizeHeightRGB(
	//   buffer: ReadIndexBuffer
	// ): Generator<undefined, ReadIndexBuffer> {
	//   return this._resizeHeightRGBChannels(buffer, false);
	// }

	// private *resizeHeightRGBA(
	//   buffer: ReadIndexBuffer
	// ): Generator<undefined, ReadIndexBuffer> {
	//   return this._resizeHeightRGBChannels(buffer /*, true */);
	// }

	public *resize(source: Uint8ArraySource): Generator<undefined, Uint8Array> {
		const widthBuffer = yield* this.resizeWidth(source);
		const heightResult = yield* this.resizeHeight(widthBuffer);
		return uint8ArrayFromArrayBuffer(heightResult);
	}

	private *bypassResizer<T>(buffer: T): Generator<undefined, T> {
		return buffer;
	}

	private initializeFirstPassBuffers(BILINEARAlgo: boolean) {
		// Initialize the internal width pass buffers:
		this.widthBuffer = this.generateFloatBuffer(this.widthPassResultSize);

		if (!BILINEARAlgo) {
			this.outputWidthWorkBench = this.generateFloatBuffer(
				this.originalHeightMultipliedByChannels,
			);

			if (channelsNum > 3) {
				this.outputWidthWorkBenchOpaquePixelsCount = this.generateFloat64Buffer(
					this.heightOriginal,
				);
			}
		}
	}

	private initializeSecondPassBuffers(BILINEARAlgo: boolean) {
		// Initialize the internal height pass buffers:
		this.heightBuffer = this.generateUint8Buffer(this.finalResultSize);

		if (!BILINEARAlgo) {
			this.outputHeightWorkBench = this.generateFloatBuffer(
				this.targetWidthMultipliedByChannels,
			);

			if (channelsNum > 3) {
				this.outputHeightWorkBenchOpaquePixelsCount =
					this.generateFloat64Buffer(this.targetWidth);
			}
		}
	}

	generateFloatBuffer(bufferLength: number): Float32Array {
		return new Float32Array(bufferLength);
	}

	generateFloat64Buffer(bufferLength: number): Float64Array {
		return new Float64Array(bufferLength);
	}

	generateUint8Buffer(bufferLength: number): Uint8Array {
		return new Uint8Array(bufferLength);
	}
}

function* resizeBitmapToWritable<T extends Bitmap>(
	image: T,
	width: number,
	height: number,
): Generator<undefined, T & WritableBitmap> {
	if (process.env.NODE_ENV === "development") {
		if (width !== Math.round(width) || height !== Math.round(height)) {
			throw new Error(`Resize expects int width height ${width}x${height}`);
		}
	}

	const resizer = new Resize(image.width, image.height, width, height);
	const data = yield* resizer.resize(image.data);
	return {
		...image,
		data,
		width,
		height,
	};
}

function* resizeBitmap<T extends Bitmap>(
	image: T,
	width: number,
	height: number,
): Generator<undefined, T & WritableBitmap> {
	return yield* resizeBitmapToWritable(image, width, height);
}

export { resizeBitmap, resizeBitmapToWritable };

// The resize mode results in a kind of anti-aliasing. The basic "nearest neighbour" is very fast
// but gives a more pixelated look. Default is a little bit of aa but at decent performance
// modified.resize(
//   width,
//   height
//   // RESIZE_NEAREST_NEIGHBOR,
//   // RESIZE_BILINEAR,
//   // RESIZE_BICUBIC,
//   // RESIZE_HERMITE
//   // RESIZE_BEZIER
// );

// Used this for a test at one time.
// function* resizeBitmapNearestNeighbour(
// 	{ width: originalWidth, height: originalHeight, data: originalData }: Bitmap,
// 	newWidth: number,
// 	newHeight: number,
// ) {
// 	const newData = new Uint8Array(newWidth * newHeight * 4);

// 	for (let newY = 0; newY < newHeight; newY++) {
// 		for (let newX = 0; newX < newWidth; newX++) {
// 			// Calculate corresponding coordinates in the original bitmap
// 			const originalX = Math.round((newX / newWidth) * originalWidth);
// 			const originalY = Math.round((newY / newHeight) * originalHeight);

// 			// Map the original pixel to the new position
// 			const originalIndex = (originalY * originalWidth + originalX) * 4;
// 			const newIndex = (newY * newWidth + newX) * 4;

// 			// Copy the pixel values to the resized bitmap
// 			newData[newIndex] = originalData[originalIndex];
// 			newData[newIndex + 1] = originalData[originalIndex + 1];
// 			newData[newIndex + 2] = originalData[originalIndex + 2];
// 			newData[newIndex + 3] = originalData[originalIndex + 3];
// 		}
// 		yield;
// 	}

// 	return {
// 		width: newWidth,
// 		height: newHeight,
// 		data: newData,
// 	};
// }
