import { resizeBitmap } from "./resize.ts";
import { Bitmap } from "./types.ts";

function scaleBitmap(image: Bitmap, scale: number) {
	const targetWidth = Math.round(image.width * scale);
	const targetHeight = Math.round(image.height * scale);
	// Trying to track down resize to non-integer width height error
	if (Number.isNaN(targetWidth) || Number.isNaN(targetHeight)) {
		throw new Error(
			`Invalid resize: ${targetWidth}x${targetHeight} from ${image.width}x${image.height}, scale ${scale}`,
		);
	}
	return resizeBitmap(image, targetWidth, targetHeight);
}

export default scaleBitmap;
