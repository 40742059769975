import { GenericSize } from "./geom.ts";

type Distance = {
	readonly mm: number;
	readonly cm: number;
	readonly m: number;
	readonly inches: number;
};

function distanceFromMillimeters(mm: number): Distance {
	return {
		mm,
		cm: mm / 10,
		m: mm / 1000,
		inches: mm * 0.0393701,
	};
}

function distanceFromInches(inches: number): Distance {
	return distanceFromMillimeters(25.4 * inches);
}

function distanceFromMetres(metres: number): Distance {
	return distanceFromMillimeters(metres * 1000);
}

type DistanceSize = GenericSize<Distance>;

export type { Distance, DistanceSize };
export { distanceFromMillimeters, distanceFromMetres, distanceFromInches };
