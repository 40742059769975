import { Palette } from "@brickme/project-core/src";
import { AppState } from "~/store/root-reducer.ts";
import { ensureLoadedOnceOffLoad, isOnceOffLoaded } from "~/utils/loading.ts";
import type { HangingStrips } from "~/model.d.ts";

function selectPricingModel(state: AppState) {
	return state.reference.pricingModel;
}

/* @deprecated Use shopify prices instead */
function selectLoadedPricingModel(state: AppState) {
	return ensureLoadedOnceOffLoad(
		selectPricingModel(state),
		"Pricing model not loaded",
	);
}

function selectScenes(state: AppState) {
	return state.reference.scenes;
}

function selectShopifyCollections(state: AppState) {
	return state.reference.shopifyCollections;
}

function selectHasPricingModel(state: AppState): boolean {
	return isOnceOffLoaded(selectPricingModel(state));
}

function selectSystemPalette(state: AppState): Palette {
	return ensureLoadedOnceOffLoad(
		state.reference.systemPalette,
		"System palette not loaded",
	);
}

function selectBasePlateSizes(state: AppState) {
	return state.reference.basePlateSizes;
}

function selectSystemPaletteLoad(state: AppState) {
	return state.reference.systemPalette;
}

function selectCountriesLoad(state: AppState) {
	return state.reference.countries;
}

function selectShopifyShopDomain(state: AppState) {
	return state.reference.shopifyShopDomain;
}

function selectSystemPaletteLength(state: AppState) {
	return selectSystemPalette(state).length;
}

function selectPreassemblyOptions(state: AppState) {
	return state.reference.preassemblyOptions;
}

function selectHasSystemPalette(state: AppState) {
	return isOnceOffLoaded(state.reference.systemPalette);
}

function selectIsMobile(state: AppState): boolean {
	return state.reference.isMobile;
}

function selectBrowserSource(state: AppState) {
	return state.reference.browserSource;
}

function selectVersion(state: AppState): string {
	return state.reference.version;
}

function selectOperatingSystem(state: AppState) {
	return state.reference.os;
}

function selectHangingStripsData({
	reference: { hangingStrips },
}: AppState): HangingStrips | undefined {
	if (isOnceOffLoaded(hangingStrips)) {
		return hangingStrips.data;
	}
	return undefined;
}

function selectMaxNumberOfSelectableBasePlates(state: AppState) {
	return state.reference.maxNumberOfSelectableBasePlates;
}

export {
	selectCountriesLoad,
	selectMaxNumberOfSelectableBasePlates,
	selectScenes,
	selectHangingStripsData,
	selectBrowserSource,
	selectLoadedPricingModel,
	selectOperatingSystem,
	selectIsMobile,
	selectPreassemblyOptions,
	selectVersion,
	selectHasSystemPalette,
	selectSystemPaletteLoad,
	selectSystemPalette,
	selectSystemPaletteLength,
	selectPricingModel,
	selectHasPricingModel,
	selectBasePlateSizes,
	selectShopifyCollections,
	selectShopifyShopDomain,
};
