/* eslint-disable no-param-reassign */
import {
	createAsyncThunk,
	createSlice,
	Draft,
	PayloadAction,
} from "@reduxjs/toolkit";
import { pick, uniqueId } from "lodash-es";
import { castDraft } from "immer";
import { findOnlyOrThrow, lastOrThrow } from "@dhau/lang-extras";
import {
	BasePlateSize,
	NumberOfBasePlates,
	BrickedPictureOutput,
	ImageZoomOffset,
	BrickColour,
	originPosition,
	multiplyPosition,
	PictureConfiguration,
	multiplySize,
	arePositionsClose,
	createBrickedImageFromSourceNoCacheSync,
	UnixTimestamp,
	roundPosition,
	maxImageZoom,
	maxKnownBasePlateSize,
	BrickPosition,
	DetailFilter,
	maxNumberOfBasePlates,
	createMaxRequiredResolutionSourceImage,
	Bitmap,
	PenMark,
	FaceBoundingBox,
	BitmapMask,
	createFacesMask,
	EncodedImage,
	loadBitmapFromUrlToMaxSize,
	constrainImageZoomOffset,
} from "@brickme/project-core/src";
import bitmapToSmallestSizeEncodedImage from "@brickme/project-core/src/browser/bitmap-to-smallest-size-encoded-image.ts";
import getPicToBrickById, {
	GetPicToBrickByIdQuery,
	GetPicToBrickByIdVariables,
} from "~/api/get-pic-to-brick-by-id.ts";
import { State as ReferenceState } from "~/features/reference/state.ts";
import { StoreThunkApiConfig } from "~/store-config.ts";
import {
	ensureLoadedOnceOffLoad,
	OnceOffLoad,
	isOnceOffLoaded,
} from "~/utils/loading.ts";
import encodedImageArrayBufferToBitmap from "~/utils/encoded-image-array-buffer-to-bitmap.ts";
import createDefaultPictureConfiguration from "./create-default-picture-configuration.ts";
import { State, requireOpenProject } from "./state.ts";
import addPenMarksReducer from "./reducers/add-pen-marks-reducer.ts";
import setAutoPaletteModeReducer from "./reducers/set-auto-palette-mode-reducer.ts";
import deselectCustomModePaletteBrick from "./actions/deselect-custom-mode-palette-brick.ts";
import startProject from "./start-project.ts";
import creatorProjectToBuildSources from "./creator-project-to-build-sources.ts";

const name = "workspace";

const selectImage = createAsyncThunk<
	void,
	{
		readonly fileName: string;
		readonly originalEncodedImage: EncodedImage | undefined;
		readonly inputImage: Bitmap;
	},
	StoreThunkApiConfig<{ reference: ReferenceState }>
>(
	`${name}/selectImage`,
	async (
		{
			/* fileName, */ originalEncodedImage: providedOriginalBuffer,
			inputImage,
		},
		{ getState, dispatch, extra: { tidioChatApi } },
	) => {
		const {
			reference: { basePlateSizes },
		} = getState();

		const config = createDefaultPictureConfiguration(
			basePlateSizes,
			inputImage.width,
			inputImage.height,
		);
		tidioChatApi.track("Image Selected");

		const sizedDownBitmap = createMaxRequiredResolutionSourceImage(
			inputImage,
			maxNumberOfBasePlates,
		);
		// If we've resized the image, then we can't use the original buffer because it's a different size.
		// This would result in a mismatch between local image and remote image sizes.
		// I've seen it in action and the bg mask ended up being a different size to source, which is a
		// problem.
		const originalEncodedImage =
			sizedDownBitmap.width === inputImage.width &&
			sizedDownBitmap.height === inputImage.height
				? providedOriginalBuffer
				: undefined;
		const smallestEncodedImage = await bitmapToSmallestSizeEncodedImage(
			sizedDownBitmap,
			originalEncodedImage,
		);
		// Careful, we want to use the bitmap extracted from the optimised version of the image,
		// not the un optimised image. Reason is we want design to be consistent. Otherwise subsequent
		// loads would use the optimised version whilst initial would use unoptimised.
		const smallestBitmap = await encodedImageArrayBufferToBitmap(
			smallestEncodedImage.buffer,
			smallestEncodedImage.mimeType,
		);

		await dispatch(
			startProject({
				project: {
					currentPicture: config,
					faceBoundingBoxes: undefined,
					otherVersions: [],
					updatedAt: Date.now(),
				},
				// source: {
				//   type: "userImage",
				//   fileName
				// },
				sourceImageBitmapOrUrl: {
					bitmap: smallestBitmap,
					originalEncodedImage,
					smallestEncodedImage,
				},
				backgroundMaskImageUrl: undefined,
				enhanceFacesImageUrl: undefined,
				colorisationImageUrl: undefined,
			}),
		);
	},
);

const selectImageByUrl = createAsyncThunk<
	void,
	string,
	StoreThunkApiConfig<{ reference: ReferenceState }>
>(`${name}/selectImageByUrl`, async (imageUrl, { dispatch }) => {
	const maxResolution =
		maxNumberOfBasePlates * maxKnownBasePlateSize * maxImageZoom;
	const inputImage = await loadBitmapFromUrlToMaxSize(imageUrl, maxResolution);
	// TODO: Download buffer to use here
	await dispatch(
		selectImage({
			fileName: lastOrThrow(imageUrl.split("/")),
			originalEncodedImage: undefined,
			inputImage,
		}),
	);
});

const selectImageByPicToBrick = createAsyncThunk<
	void,
	string,
	StoreThunkApiConfig<{ reference: ReferenceState }>
>(
	`${name}/selectImageByPicToBrick`,
	async (picId, { dispatch, extra: { apiClient } }) => {
		const result = await apiClient.request<
			GetPicToBrickByIdQuery,
			GetPicToBrickByIdVariables
		>(getPicToBrickById, { id: picId });
		if (!result.picToBrickById) {
			throw new Error(`No pic to brick with id ${picId}`);
		}
		// TODO: Going down this route creates a copy. We should be referencing the same image
		// for each customer using this
		await dispatch(selectImageByUrl(result.picToBrickById.imageUrl));
	},
);

const resetProject = createAsyncThunk<
	{ configuration: PictureConfiguration; build: BrickedPictureOutput },
	undefined,
	StoreThunkApiConfig<{ reference: ReferenceState; workspace: State }>
>(`${name}/resetProject`, async (_, { getState, extra: { openCvLoader } }) => {
	const {
		workspace: { openProject },
		reference: { systemPalette, basePlateSizes },
	} = getState();
	const systemPaletteData = ensureLoadedOnceOffLoad(
		systemPalette,
		"System palette not loaded",
	);
	if (!openProject) {
		throw new Error("No open project");
	}

	const { project } = openProject;
	const configuration = createDefaultPictureConfiguration(
		basePlateSizes,
		project.sourceImage.bitmap.width,
		project.sourceImage.bitmap.height,
	);
	const openCv = await openCvLoader;
	const build = createBrickedImageFromSourceNoCacheSync(
		{ openCv },
		creatorProjectToBuildSources(project),
		systemPaletteData,
		configuration,
	);
	return { configuration, build };
});

const setPictureConfiguration = createAsyncThunk<
	BrickedPictureOutput,
	PictureConfiguration,
	StoreThunkApiConfig<{ reference: ReferenceState; workspace: State }>
>(
	`${name}/setPictureConfiguration`,
	async (config, { getState, extra: { openCvLoader } }) => {
		const {
			reference: { systemPalette },
			workspace: { openProject },
		} = getState();
		const systemPaletteData = ensureLoadedOnceOffLoad(
			systemPalette,
			"System palette not loaded",
		);
		if (!openProject) {
			throw new Error("No open project");
		}

		const { project } = openProject;
		const openCv = await openCvLoader;
		return createBrickedImageFromSourceNoCacheSync(
			{ openCv },
			creatorProjectToBuildSources(project),
			systemPaletteData,
			config,
		);
	},
);

const switchToSavedVersion = createAsyncThunk<
	BrickedPictureOutput,
	string,
	StoreThunkApiConfig<{ workspace: State; reference: ReferenceState }>
>(
	`${name}/switchToSavedVersion`,
	async (versionId, { getState, extra: { openCvLoader } }) => {
		const {
			workspace: { openProject },
			reference: { systemPalette },
		} = getState();
		const systemPaletteData = ensureLoadedOnceOffLoad(
			systemPalette,
			"System palette not loaded",
		);
		if (!openProject) {
			throw new Error("No open project");
		}
		const version = findOnlyOrThrow(
			openProject.project.otherVersions,
			(o) => o.id === versionId,
			`Couldn't find version ${versionId}`,
		);

		const openCv = await openCvLoader;
		return createBrickedImageFromSourceNoCacheSync(
			{ openCv },
			creatorProjectToBuildSources(openProject.project),
			systemPaletteData,
			version.picture,
		);
	},
);

const setCustomPaletteMode = createAsyncThunk<
	readonly BrickColour[],
	undefined,
	StoreThunkApiConfig<{ reference: ReferenceState; workspace: State }>
>(`${name}/setCustomPaletteMode`, (_, { getState }) => {
	// TODO: Race condition here. If already building then won't have latest brickCount
	const {
		workspace: { openProject },
		reference: { systemPalette },
	} = getState();
	if (!openProject) {
		throw new Error("No open project");
	}
	const systemPaletteData = ensureLoadedOnceOffLoad(
		systemPalette,
		"System palette not loaded",
	);

	return Object.keys(openProject.build.brickCounts).map(
		(hex) =>
			findOnlyOrThrow(
				systemPaletteData,
				(p) => p.brick.hexString === hex,
				`Couldn't find hex ${hex}`,
			).brick,
	);
});

const selectCustomModePaletteBrick = createAsyncThunk<
	readonly BrickColour[],
	BrickColour,
	StoreThunkApiConfig<{ workspace: State; reference: ReferenceState }>
>(
	`${name}/selectCustomModePaletteBrick`,
	// Required for cross state access
	// eslint-disable-next-line @typescript-eslint/require-await
	async (colour, { getState }) => {
		const {
			workspace: { openProject },
			reference: { systemPalette },
		} = getState();
		if (!openProject) {
			throw new Error("No open project");
		}
		const systemPaletteData = ensureLoadedOnceOffLoad(
			systemPalette,
			"System palette not loaded",
		);

		let newPalette: BrickColour[];
		if (openProject.project.currentPicture.paletteMode.type === "custom") {
			newPalette =
				openProject.project.currentPicture.paletteMode.palette.slice();
		} else {
			// TODO: Race condition here. If already building then won't have latest brickCount
			newPalette = systemPaletteData
				.map((p) => p.brick)
				.filter((s) => s.hexString in openProject.build.brickCounts);
		}
		newPalette.push(colour);
		return newPalette;
	},
);

function positionToKey(position: BrickPosition) {
	return `${position.x},${position.y}`;
}

const slice = createSlice({
	name,
	initialState: {
		openProject: undefined,
	} as State,
	reducers: {
		startNewProject: (state) => {
			state.openProject = undefined;
		},
		applyPaintBucket: (
			state: Draft<State>,
			action: PayloadAction<{
				readonly colour: BrickColour;
				readonly positions: readonly BrickPosition[];
			}>,
		) => {
			const start = performance.now();
			const { project } = requireOpenProject(state);
			const { currentPicture } = project;

			const { colour, positions } = action.payload;

			let newPens: PenMark[] = [];
			const newPositions = new Set(positions.map(positionToKey));

			// Allow overriding of old pen positions
			currentPicture.pen.forEach((p) => {
				if (!newPositions.has(positionToKey(p.position))) {
					newPens.push(p);
				}
			});
			newPens = newPens.concat(
				positions.map((position) => ({
					colour,
					position,
				})),
			);

			// Add new pen positions
			currentPicture.pen = newPens;

			currentPicture.updatedAt = Date.now();
			project.updatedAt = currentPicture.updatedAt;

			if (import.meta.env.NODE_ENV === "development") {
				// eslint-disable-next-line no-console
				console.log(
					`PaintBucket applied ${Math.round(performance.now() - start)}ms`,
				);
			}
		},
		setImageZoom: (state: Draft<State>, action: PayloadAction<number>) => {
			const { project } = requireOpenProject(state);
			const { currentPicture } = project;
			const previousZoom = currentPicture.imageZoom;
			if (previousZoom === action.payload) {
				console.warn("Setting zoom to current value");
				return;
			}
			currentPicture.imageZoom = action.payload;
			currentPicture.imageZoomOffset = constrainImageZoomOffset(
				project.sourceImage.bitmap,
				castDraft(currentPicture),
				roundPosition(
					multiplyPosition(
						currentPicture.imageZoomOffset,
						action.payload / previousZoom,
					),
				),
			);
			// Kind of messy to try and scale this up
			if (currentPicture.pen.length > 0) {
				currentPicture.pen = [];
			}
			currentPicture.updatedAt = Date.now();
			project.updatedAt = currentPicture.updatedAt;
		},
		setImageZoomOffset: (state, action: PayloadAction<ImageZoomOffset>) => {
			const openProject = requireOpenProject(state);
			const { currentPicture } = openProject.project;

			const newOffset = constrainImageZoomOffset(
				openProject.project.sourceImage.bitmap,
				openProject.project.currentPicture,
				action.payload,
			);
			if (arePositionsClose(newOffset, currentPicture.imageZoomOffset, 0.5)) {
				console.warn("zoom offset didn't change");
				return;
			}

			currentPicture.imageZoomOffset = newOffset;
			currentPicture.updatedAt = Date.now();
			openProject.project.updatedAt = currentPicture.updatedAt;
		},
		addPenMarks: addPenMarksReducer,
		setNumberOfBasePlates: (
			state,
			action: PayloadAction<NumberOfBasePlates>,
		) => {
			const openProject = requireOpenProject(state);
			const picture = openProject.project.currentPicture;
			picture.imageZoomOffset = originPosition;
			picture.numberOfBasePlates = action.payload;
			picture.numberOfBricks = multiplySize(
				picture.numberOfBasePlates,
				picture.basePlateSize,
			);
			picture.pen = [];

			// Changing size might mean that frame can no longer support it
			// if (
			//   picture.frame &&
			//   !isFrameCompatibleWithProject(picture.frame, picture)
			// ) {
			//   picture.frame = undefined;
			// }
			openProject.project.currentPicture.updatedAt = Date.now();
			openProject.project.updatedAt =
				openProject.project.currentPicture.updatedAt;
		},
		setBasePlateSize: (
			state: Draft<State>,
			action: PayloadAction<BasePlateSize>,
		) => {
			const { openProject } = state;
			if (!openProject) {
				throw new Error("No project");
			}
			const picture = openProject.project.currentPicture;
			picture.imageZoomOffset = roundPosition(
				multiplyPosition(
					picture.imageZoomOffset,
					action.payload / picture.basePlateSize,
				),
			);
			picture.basePlateSize = action.payload;
			picture.numberOfBricks = multiplySize(
				picture.numberOfBasePlates,
				picture.basePlateSize,
			);
			picture.pen = [];

			// Changing size might mean that frame can no longer support it
			// if (
			//   picture.frame &&
			//   !isFrameCompatibleWithProject(picture.frame, picture)
			// ) {
			//   picture.frame = undefined;
			// }
			picture.updatedAt = Date.now();
			openProject.project.updatedAt = picture.updatedAt;
		},
		setBrightness: (state: Draft<State>, action: PayloadAction<number>) => {
			const { project } = requireOpenProject(state);
			project.currentPicture.brightness = action.payload;
			project.currentPicture.updatedAt = Date.now();
			project.updatedAt = project.currentPicture.updatedAt;
		},
		setContrast: (state: Draft<State>, action: PayloadAction<number>) => {
			const { project } = requireOpenProject(state);
			project.currentPicture.contrast = action.payload;
			project.currentPicture.updatedAt = Date.now();
			project.updatedAt = project.currentPicture.updatedAt;
		},
		removeOtherVersion: (
			state: Draft<State>,
			action: PayloadAction<string>,
		) => {
			const { project } = requireOpenProject(state);
			const otherVersionIndex = project.otherVersions.findIndex(
				(o) => o.id === action.payload,
			);
			if (otherVersionIndex >= 0) {
				project.otherVersions.splice(otherVersionIndex, 1);
			}
			// Try and trigger a re-save
			project.updatedAt = Date.now();
		},
		setDetailFilter: (
			state: Draft<State>,
			action: PayloadAction<DetailFilter | undefined>,
		) => {
			const { project } = requireOpenProject(state);
			project.currentPicture.detailFilter = action.payload;
			project.currentPicture.updatedAt = Date.now();
			project.updatedAt = project.currentPicture.updatedAt;
		},
		setRemoveBackground: (
			state: Draft<State>,
			action: PayloadAction<PictureConfiguration["removeBackground"]>,
		) => {
			const { project } = requireOpenProject(state);
			project.currentPicture.removeBackground = action.payload;
			project.currentPicture.updatedAt = Date.now();
			project.updatedAt = project.currentPicture.updatedAt;
		},
		setFixFaceColours: (
			state: Draft<State>,
			action: PayloadAction<boolean>,
		) => {
			const { project } = requireOpenProject(state);
			project.currentPicture.fixFaceColours = action.payload;
			project.currentPicture.updatedAt = Date.now();
			project.updatedAt = project.currentPicture.updatedAt;
		},
		setEnhanceFaces: (state: Draft<State>, action: PayloadAction<boolean>) => {
			const { project } = requireOpenProject(state);
			project.currentPicture.enhanceFaces = action.payload;
			project.currentPicture.updatedAt = Date.now();
			project.updatedAt = project.currentPicture.updatedAt;
		},
		setSaturation: (state: Draft<State>, action: PayloadAction<number>) => {
			const { project } = requireOpenProject(state);
			project.currentPicture.saturation = action.payload;
			project.currentPicture.updatedAt = Date.now();
			project.updatedAt = project.currentPicture.updatedAt;
		},
		setAutoPaletteMode: setAutoPaletteModeReducer,
		setBuild: (
			state: Draft<State>,
			action: PayloadAction<{
				output: BrickedPictureOutput;
				timestamp: UnixTimestamp;
			}>,
		) => {
			const { output, timestamp } = action.payload;
			if (Number.isNaN(timestamp)) {
				// Need to avoid this at all costs because blocks up the build pipeline
				throw new Error("NaN timestamp for new build");
			}
			const openProject = requireOpenProject(state);
			openProject.builtCurrentPictureUpdatedAt = timestamp;
			openProject.build = output as Draft<BrickedPictureOutput>;
		},
		// Don't want this action. i.e. should be an async action within workspace.
		// However need to merge saved project and workspace for that to work
		setBackgroundMaskImage: (
			state: Draft<State>,
			action: PayloadAction<OnceOffLoad<BitmapMask>>,
		) => {
			const openProject = requireOpenProject(state);
			openProject.project.backgroundMask = action.payload as Draft<
				OnceOffLoad<BitmapMask>
			>;
			if (
				openProject.project.facesMask === undefined &&
				isOnceOffLoaded(openProject.project.backgroundMask) &&
				openProject.project.faceBoundingBoxes !== undefined
			) {
				openProject.project.facesMask = createFacesMask(
					openProject.project.backgroundMask.data,
					openProject.project.faceBoundingBoxes,
				) as Draft<BitmapMask>;
			}
			openProject.project.currentPicture.updatedAt = Date.now();
		},
		setColorisationImage: (
			state: Draft<State>,
			action: PayloadAction<OnceOffLoad<Bitmap>>,
		) => {
			const openProject = requireOpenProject(state);
			openProject.project.colorisationImage = action.payload;
			openProject.project.currentPicture.updatedAt = Date.now();
		},
		setEnhanceFacesImage: (
			state: Draft<State>,
			action: PayloadAction<OnceOffLoad<Bitmap>>,
		) => {
			const openProject = requireOpenProject(state);
			openProject.project.enhanceFacesImage = action.payload;
			openProject.project.currentPicture.updatedAt = Date.now();
		},
		setFaceBoundingBoxes: (
			state: Draft<State>,
			action: PayloadAction<readonly FaceBoundingBox[]>,
		) => {
			const openProject = requireOpenProject(state);
			openProject.project.faceBoundingBoxes =
				action.payload as FaceBoundingBox[];
			if (
				openProject.project.facesMask === undefined &&
				isOnceOffLoaded(openProject.project.backgroundMask) &&
				openProject.project.faceBoundingBoxes !== undefined
			) {
				openProject.project.facesMask = createFacesMask(
					openProject.project.backgroundMask.data,
					openProject.project.faceBoundingBoxes,
				) as Draft<BitmapMask>;
			}
			openProject.project.currentPicture.updatedAt = Date.now();
		},
		addSavedVersionFromCurrent: (state: Draft<State>) => {
			const openProject = requireOpenProject(state);
			openProject.project.otherVersions.push({
				id: uniqueId(),
				picture: openProject.project.currentPicture,
			});
			openProject.project.updatedAt = Date.now();
		},
	},
	extraReducers: {
		[deselectCustomModePaletteBrick.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<
				ReturnType<(typeof deselectCustomModePaletteBrick)["fulfilled"]>
			>,
		) => {
			const { openProject } = state;
			if (!openProject) {
				throw new Error("No open project");
			}

			const { project } = openProject;
			project.currentPicture.paletteMode = {
				type: "custom",
				palette: action.payload,
			};
			// TODO: Only if a change
			project.currentPicture.updatedAt = Date.now();
			project.updatedAt = openProject.project.currentPicture.updatedAt;
		},
		[switchToSavedVersion.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof switchToSavedVersion)["fulfilled"]>>,
		) => {
			const openProject = requireOpenProject(state);
			const oldCurrent = openProject.project.currentPicture;
			const versionId = action.meta.arg;
			const otherVersion = findOnlyOrThrow(
				openProject.project.otherVersions,
				(o) => o.id === versionId,
				`Couldn't find version ${versionId}`,
			);

			openProject.project.currentPicture = otherVersion.picture;
			openProject.build = action.payload;
			const otherVersionIndex = openProject.project.otherVersions.findIndex(
				(o) => o.id === versionId,
			);
			if (otherVersionIndex >= 0) {
				openProject.project.otherVersions.splice(otherVersionIndex, 1);
			}
			openProject.project.otherVersions.unshift({
				id: uniqueId(),
				picture: oldCurrent,
			});
			// A little bit of a hack to force a rebuild
			openProject.builtCurrentPictureUpdatedAt = -1;
			openProject.project.updatedAt = Date.now();
		},
		[setPictureConfiguration.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof setPictureConfiguration)["fulfilled"]>>,
		) => {
			const openProject = requireOpenProject(state);
			openProject.project.currentPicture = action.meta.arg;
			openProject.build = action.payload;
			openProject.project.updatedAt = Date.now();
		},
		[startProject.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof startProject)["fulfilled"]>>,
		) => {
			state.openProject = action.payload;
		},
		[setCustomPaletteMode.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof setCustomPaletteMode)["fulfilled"]>>,
		) => {
			const { project } = requireOpenProject(state);
			project.currentPicture.paletteMode = {
				type: "custom",
				palette: action.payload,
			};
			project.currentPicture.updatedAt = Date.now();
			project.updatedAt = project.currentPicture.updatedAt;
		},
		[resetProject.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof resetProject)["fulfilled"]>>,
		) => {
			if (!state.openProject) {
				throw new Error("Expected open project");
			}

			const now = Date.now();
			state.openProject = {
				build: action.payload.build,
				builtCurrentPictureUpdatedAt: now,
				project: {
					currentPicture: action.payload.configuration,
					otherVersions: [],
					...pick(
						state.openProject.project,
						"sourceImage",
						"backgroundMask",
						"enhanceFacesImage",
						"colorisationImage",
						"faceBoundingBoxes",
						"facesMask",
					),
					updatedAt: now,
				},
			};
		},
		[selectCustomModePaletteBrick.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<
				ReturnType<(typeof selectCustomModePaletteBrick)["fulfilled"]>
			>,
		) => {
			const { openProject } = state;
			if (!openProject) {
				throw new Error("No open project");
			}
			// TODO: Only if change
			openProject.project.currentPicture.updatedAt = Date.now();
			openProject.project.updatedAt =
				openProject.project.currentPicture.updatedAt;
			openProject.project.currentPicture.paletteMode = {
				type: "custom",
				palette: action.payload,
			};
		},
	},
});

export const {
	startNewProject,
	setImageZoom,
	setImageZoomOffset,
	setAutoPaletteMode,
	setBasePlateSize,
	setBrightness,
	setContrast,
	setDetailFilter,
	setNumberOfBasePlates,
	setSaturation,
	addPenMarks,
	setRemoveBackground,
	setBuild,
	applyPaintBucket,
	removeOtherVersion,
	setFixFaceColours,
	setEnhanceFaces,
	setBackgroundMaskImage,
	setEnhanceFacesImage,
	setFaceBoundingBoxes,
	setColorisationImage,
	addSavedVersionFromCurrent,
} = slice.actions;
export {
	setCustomPaletteMode,
	selectImage,
	setPictureConfiguration,
	selectImageByUrl,
	switchToSavedVersion,
	selectImageByPicToBrick,
	resetProject,
	selectCustomModePaletteBrick,
};
export default slice.reducer;
