import { PayloadAction, AsyncThunkAction } from "@reduxjs/toolkit";
import {
	setBasePlateSize,
	setImageZoom,
	setImageZoomOffset,
	setNumberOfBasePlates,
	setBrightness,
	setContrast,
	setSaturation,
	selectCustomModePaletteBrick,
	setAutoPaletteMode,
	setCustomPaletteMode,
	addPenMarks,
	setDetailFilter,
	setRemoveBackground,
	setFixFaceColours,
	setEnhanceFaces,
} from "~/features/workspace/store-slice.ts";
import deselectCustomModePaletteBrick from "~/features/workspace/actions/deselect-custom-mode-palette-brick.ts";
import { addSelectedPaintBucketColourBrick } from "~/features/editor/store-slice.ts";
import { Command } from "./command.ts";

function createPerformAction(
	command: Command,
): PayloadAction<unknown> | AsyncThunkAction<unknown, unknown, any> {
	switch (command.type) {
		case "setBasePlateSize":
			return setBasePlateSize(command.next);
		case "setImageZoom":
			return setImageZoom(command.next);
		case "setImageZoomOffset":
			return setImageZoomOffset(command.next);
		case "setNumberOfBasePlates":
			return setNumberOfBasePlates(command.next);
		case "setBrightness":
			return setBrightness(command.next);
		case "setContrast":
			return setContrast(command.next);
		case "setSaturation":
			return setSaturation(command.next);
		case "selectCustomModePaletteBrick":
			return selectCustomModePaletteBrick(command.brick);
		case "deselectCustomModePaletteBrick":
			return deselectCustomModePaletteBrick(command.brick);
		case "setAutoPaletteMode":
			return setAutoPaletteMode(command.numberOfColours);
		case "setCustomPaletteMode":
			return setCustomPaletteMode();
		case "addPenMarks":
			return addPenMarks(command.penMarks);
		case "addPaintBucket":
			return addSelectedPaintBucketColourBrick(command.position);
		case "setDetailFilter":
			return setDetailFilter(command.detailFilter);
		case "setRemoveBackground":
			return setRemoveBackground(command.value);
		case "setFixFaceColours":
			return setFixFaceColours(command.fixFaceColours);
		case "setEnhanceFaces":
			return setEnhanceFaces(command.enhanceFaces);
		default:
			throw new Error("Unrecognised command");
	}
}

export default createPerformAction;
