import React, { ReactElement } from "react";
import Modal from "~/components/modal.tsx";
import { usePreventUnloadWarningCallback } from "~/context/before-unload.tsx";
import { useWebsiteUrl } from "~/context/website-url.tsx";

type ConfirmExitModalProps = {
	readonly show: boolean;
	readonly onCancel: () => void;
};

function ConfirmExitModal({
	show,
	onCancel,
}: ConfirmExitModalProps): ReactElement {
	const websiteUrl = useWebsiteUrl("/");
	const preventUnloadWarning = usePreventUnloadWarningCallback();
	return (
		<Modal show={show} title="WARNING!" onClose={onCancel}>
			<Modal.Text>Are you sure you want to exit?</Modal.Text>
			<Modal.Actions
				actions={[
					{ text: "Cancel", onClick: onCancel, type: "ghost" },
					{
						text: "Yes",
						href: websiteUrl,
						target: "self",
						onMouseDown: preventUnloadWarning,
					},
				]}
			/>
		</Modal>
	);
}

export default ConfirmExitModal;
