import { createAsyncThunk } from "@reduxjs/toolkit";
import { StoreThunkApiConfig } from "~/store-config.ts";
import { waitForLoadedOneOffLoad } from "~/utils/loading.ts";
import { State as ReferenceState } from "~/features/reference/state.ts";
import { HangingOption } from "./hanging-option.ts";

const selectDefaultHangingType = createAsyncThunk<
	HangingOption | undefined,
	undefined,
	StoreThunkApiConfig<{ reference: ReferenceState }>
>(
	"order/selectDefaultHangingType",
	// async thunk needed for cross slice state access
	// eslint-disable-next-line @typescript-eslint/require-await
	async (_, { getState }) => {
		const {
			reference: { hangingStrips },
		} = getState();

		const hangingStripsResult = await waitForLoadedOneOffLoad(() => {
			const {
				reference: { hangingStrips },
			} = getState();
			return hangingStrips;
		}, `Hanging strips not loaded: ${hangingStrips.type}`);
		if (!hangingStripsResult?.inStock) {
			return undefined;
		}

		return { type: "hangingStrips" };
	},
);

export default selectDefaultHangingType;
