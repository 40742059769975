import { sortBy } from "lodash-es";

type Country = {
	readonly code: string;
	readonly name: string;
	readonly currencyCode: string;
	readonly currencySymbol: string;
};

function getCountryOptionLabel({
	name,
	currencyCode,
	currencySymbol,
}: Country): string {
	return `${name} (${currencyCode}${
		currencySymbol ? ` ${currencySymbol}` : ""
	})`;
}

type Money = {
	readonly currencyCode: string;
	readonly amount: string;
};

type MoneyParts = {
	readonly symbol: string;
	readonly amount: string;
	readonly currencyCode: string;
};

// See https://github.com/Shopify/hydrogen/blob/v1.x-2022-07/packages/hydrogen/src/hooks/useMoney/hooks.tsx
function formatMoneyIntoParts(money: Money): MoneyParts {
	const parts = new Intl.NumberFormat(
		undefined, // Auto-detect/use the viewer's locale
		{
			style: "currency",
			currency: money.currencyCode,
			// narrowSymbol only supported by some. Use symbol in that case. Throws RangeError.
			// Have solved by having a polyfill in website and creator.
			currencyDisplay: "narrowSymbol",
		},
	).formatToParts(parseFloat(money.amount));

	const nonCurrencyParts = parts.filter((p) => p.type !== "currency");
	const symbol = parts.find((p) => p.type === "currency")?.value ?? "";
	const amount = nonCurrencyParts.map((p) => p.value).join("");

	return { symbol, amount, currencyCode: money.currencyCode };
}

function formatMoney(money: Money) {
	const { symbol, amount, currencyCode } = formatMoneyIntoParts(money);
	return `${symbol}${amount} ${currencyCode}`;
}

type IpInfo = {
	readonly country: string | undefined;
	readonly ipAddress: string | undefined;
};

function createIpInfoGeolocationService(token: string) {
	return {
		async getIpInfo() {
			const url = `https://ipinfo.io/json?token=${token}`;

			let response;
			try {
				response = await fetch(url);
			} catch {
				console.error("Error response");
				return undefined;
			}
			if (!response.ok) {
				console.error("Invalid response");
				return undefined;
			}

			let result: any;
			try {
				result = await response.json();
			} catch {
				console.error("Failed to parse ipinfo.io response");
				return undefined;
			}

			if (typeof result !== "object" || !result || !("country" in result)) {
				return undefined;
			}

			return {
				country: result.country ?? undefined,
				ipAddress: result.ip ?? undefined,
			} satisfies IpInfo;
		},
	};
}

type IpGeolocationService = ReturnType<typeof createIpInfoGeolocationService>;

const topGroupCountryCodes = ["AU", "NZ", "US"];

function groupCountryOptions(
	countries: readonly Country[],
	selectedCountryCode: string,
): readonly { readonly country?: Country; disabled?: boolean }[] {
	const mainOptions = sortBy(
		countries.filter(
			(c) =>
				topGroupCountryCodes.includes(c.code) || c.code === selectedCountryCode,
		),
		(c) => {
			if (c.code === selectedCountryCode) {
				return -1;
			}
			return topGroupCountryCodes.indexOf(c.code);
		},
	);
	const remainingOptions = sortBy(
		countries.filter((c) => !mainOptions.some((o) => o.code === c.code)),
		(r) => r.name,
	);
	return [
		...mainOptions.map((country) => ({ country })),
		{ country: undefined, disabled: true },
		...remainingOptions.map((country) => ({ country })),
	];
}

export type { Country, IpGeolocationService, IpInfo };
export {
	groupCountryOptions,
	createIpInfoGeolocationService,
	getCountryOptionLabel,
	formatMoney,
	formatMoneyIntoParts,
};
