/* eslint-disable no-param-reassign */
import {
	createAsyncThunk,
	createSlice,
	Draft,
	PayloadAction,
} from "@reduxjs/toolkit";
import { findOnlyOrThrow } from "@dhau/lang-extras";
import {
	BrickPosition,
	BrickColour,
	addPositions,
	subtractPositions,
	getBitmapPixelColour,
} from "@brickme/project-core/src";
import { isEqual } from "lodash-es";
import { StoreThunkApiConfig } from "~/store-config.ts";
import { applyPaintBucket } from "~/features/workspace/store-slice.ts";
import {
	requireOpenProject,
	State as WorkspaceState,
} from "~/features/workspace/state.ts";
import { State as ReferenceState } from "~/features/reference/state.ts";
import { performAddPenMark } from "~/features/commands/store-slice.ts";
import { State as SaveProjectState } from "~/features/save-project/store-slice.ts";
import { ensureLoadedOnceOffLoad } from "~/utils/loading.ts";

const name = "editor";

type State = {
	readonly selectedTool: "pen" | "paint-bucket";
	readonly selectedColour?: BrickColour;
	readonly lowQualityRenderRequesters: readonly string[];
};

const eyeDropperSelectColour = createAsyncThunk<
	BrickColour,
	BrickPosition,
	StoreThunkApiConfig<{ workspace: WorkspaceState; reference: ReferenceState }>
>(
	`${name}/eyeDropperSelectColour`,
	// async thunk needed for cross slice state access
	// eslint-disable-next-line @typescript-eslint/require-await
	async (position, { getState }) => {
		const {
			workspace: { openProject },
			reference: { systemPalette },
		} = getState();
		if (!openProject) {
			throw new Error("No open project");
		}
		const systemPaletteData = ensureLoadedOnceOffLoad(
			systemPalette,
			"System palette not loaded",
		);
		const rgba = getBitmapPixelColour(
			openProject.build.image,
			position.x,
			position.y,
		);
		return findOnlyOrThrow(systemPaletteData, (c) => c.brick.rgba === rgba)
			.brick;
	},
);

const addSelectedPaintBucketColourBrick = createAsyncThunk<
	void,
	BrickPosition,
	StoreThunkApiConfig<{
		workspace: WorkspaceState;
		editor: State;
	}>
>(
	`${name}/addSelectedPaintBucketColourBrick`,
	// async thunk needed for cross slice state access
	// eslint-disable-next-line @typescript-eslint/require-await
	async (position, { dispatch, getState }) => {
		const {
			workspace,
			editor: { selectedColour },
		} = getState();
		if (!selectedColour) {
			throw new Error("No selected colour");
		}

		const start = performance.now();
		const {
			project: {
				currentPicture: { imageZoomOffset },
			},
			build: { image },
		} = requireOpenProject(workspace as Draft<WorkspaceState>);
		const buildPosition = addPositions(position, imageZoomOffset);
		const pixelColour = getBitmapPixelColour(
			image,
			buildPosition.x,
			buildPosition.y,
		);
		// No change, clicked colour is same as selected. Note that this still adds a command to the
		// stack and it shouldn't.
		if (pixelColour === selectedColour.rgba) {
			console.warn("Pixel is already selected");
			return;
		}

		const visited = new Set<string>();
		const matching = new Set<BrickPosition>([buildPosition]);

		const toPositionId = (p: BrickPosition): string => `${p.x}x${p.y}`;

		visited.add(toPositionId(buildPosition));
		const positionsToCheck = [buildPosition];
		// Was using recursion by ran into max call stack size issues
		while (positionsToCheck.length > 0) {
			const p = positionsToCheck.pop();
			if (!p) {
				break;
			}

			if (getBitmapPixelColour(image, p.x, p.y) !== pixelColour) {
				/* eslint-disable-next-line no-continue */
				continue;
			}

			matching.add(p);

			const potentialPositions = [
				{ x: p.x - 1, y: p.y },
				{ x: p.x + 1, y: p.y },
				{ x: p.x, y: p.y - 1 },
				{ x: p.x, y: p.y + 1 },
			];
			const validPositions = potentialPositions.filter(
				(pP) =>
					pP.x >= 0 && pP.y >= 0 && pP.x < image.width && pP.y < image.height,
			);
			const nonVisitedPositions = validPositions.filter(
				(nVP) => !visited.has(toPositionId(nVP)),
			);
			nonVisitedPositions.forEach((nVP) => {
				visited.add(toPositionId(nVP));
			});
			positionsToCheck.splice(0, 0, ...nonVisitedPositions);
		}

		const positions = Array.from(matching, (p) =>
			subtractPositions(p, imageZoomOffset),
		);

		if (import.meta.env.NODE_ENV === "development") {
			// eslint-disable-next-line no-console
			console.log(
				`PaintBucket selection ${Math.round(performance.now() - start)}ms`,
			);
		}

		dispatch(
			applyPaintBucket({
				colour: selectedColour,
				positions,
			}),
		);
	},
);

const addSelectedPenColourBrick = createAsyncThunk<
	void,
	BrickPosition,
	StoreThunkApiConfig<{
		editor: State;
		workspace: WorkspaceState;
		saveProject: SaveProjectState;
	}>
>(
	`${name}/addSelectedPenColourBrick`,
	async (position, { dispatch, getState }) => {
		const {
			editor: { selectedTool, selectedColour },
		} = getState();
		if (selectedTool !== "pen" || !selectedColour) {
			throw new Error("No selected pen colour");
		}
		await dispatch(
			performAddPenMark({
				position,
				colour: selectedColour,
			}),
		);
	},
	{
		condition(arg: BrickPosition, { getState }) {
			const {
				workspace: { openProject },
				editor: { selectedTool, selectedColour },
			} = getState();
			if (!openProject) {
				throw new Error("No workspace");
			}
			if (selectedTool !== "pen" || !selectedColour) {
				throw new Error("Pen not selected");
			}

			// TODO: Can compare bricks directly and not rgba when have unique colour instances
			// system wide
			const perform = openProject.project.currentPicture.pen.every(
				(p) =>
					!isEqual(p.position, arg) || p.colour.rgba !== selectedColour.rgba,
			);
			if (!perform) {
				console.warn("adding pen that's already present");
			}
			return perform;
		},
	},
);

const slice = createSlice({
	name,
	initialState: {
		selectedTool: "pen",
		selectedColour: undefined,
		lowQualityRenderRequesters: [],
	} as State,
	reducers: {
		selectPen: (state: Draft<State>) => {
			state.selectedTool = "pen";
		},
		selectPaintBucket: (state: Draft<State>) => {
			state.selectedTool = "paint-bucket";
		},
		setSelectedToolColour: (
			state: Draft<State>,
			action: PayloadAction<BrickColour>,
		) => {
			state.selectedColour = action.payload;
		},
		requestLowQualityRender: (
			state: Draft<State>,
			action: PayloadAction<string>,
		) => {
			if (!state.lowQualityRenderRequesters.includes(action.payload)) {
				state.lowQualityRenderRequesters.push(action.payload);
			}
		},
		withdrawLowQualityRender: (
			state: Draft<State>,
			action: PayloadAction<string>,
		) => {
			if (state.lowQualityRenderRequesters.includes(action.payload)) {
				// Remove all in case some external error and multiple address
				state.lowQualityRenderRequesters =
					state.lowQualityRenderRequesters.filter((r) => r !== action.payload);
			}
		},
	},
	extraReducers: {
		[eyeDropperSelectColour.fulfilled.type]: (
			state: Draft<State>,
			action: Draft<ReturnType<(typeof eyeDropperSelectColour)["fulfilled"]>>,
		) => {
			state.selectedColour = action.payload;
		},
	},
});

export type { State };
export {
	eyeDropperSelectColour,
	addSelectedPaintBucketColourBrick,
	addSelectedPenColourBrick,
};
export const {
	setSelectedToolColour,
	requestLowQualityRender,
	withdrawLowQualityRender,
	selectPen,
	selectPaintBucket,
} = slice.actions;
export default slice.reducer;
