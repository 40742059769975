import React, { ReactElement, useCallback } from "react";
import logoImage from "~/styles/img/logo.svg";
import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import useAppSelector from "~/hooks/use-app-selector.ts";
import { selectUser } from "~/features/user/selectors.ts";
import { signOut } from "~/features/user/store-slice.ts";
import { showLoginPrompt } from "~/features/nav/store-slice.ts";
import MainMenu from "./main-menu.tsx";

export default function ShortHeader(): ReactElement {
	const user = useAppSelector(selectUser);
	const dispatch = useAppDispatch();
	const onSignOut = useCallback(() => {
		dispatch(signOut());
	}, [dispatch]);
	const promptSignIn = useCallback(() => {
		dispatch(showLoginPrompt());
	}, [dispatch]);

	return (
		<div className="short-header-outer test-nadav">
			<header className="short-header">
				<div className="short-header__container container-fluid">
					<div className="short-header__content">
						<div className="short-header__aside">
							<MainMenu />
							{/* <button */}
							{/*  type="button" */}
							{/*  className="short-header__back button button_type_ghost" */}
							{/*  onClick={() => undefined} */}
							{/* > */}
							{/*  <SpriteIcon */}
							{/*    className="short-header__back-icon" */}
							{/*    name="arrow-left" */}
							{/*  /> */}
							{/* </button> */}
						</div>
						<div className="short-header__logo">
							<a href="/" className="short-header__logo-link">
								<img
									src={logoImage}
									className="short-header__logo-img js-svg"
									alt="Brick Me Creator"
								/>
							</a>
						</div>
						{user.type === "guest" && (
							<div className="short-header__logout logout">
								<span className="logout__text">Guest</span>{" "}
								<button
									type="button"
									className="logout__link"
									onClick={promptSignIn}
								>
									(<span className="logout__link-inner">Sign in</span>)
								</button>
							</div>
						)}
						{user.type === "signedIn" && (
							<div className="short-header__logout logout">
								<span className="logout__text">
									G&apos;day {user.user.firstName}!
								</span>{" "}
								<button
									type="button"
									className="logout__link"
									onClick={onSignOut}
								>
									(<span className="logout__link-inner">Logout</span>)
								</button>
							</div>
						)}
					</div>
				</div>
			</header>
		</div>
	);
}
