import { NumberOfBasePlates } from "@brickme/project-core/src";

const minDimensionSize = 3;
const maxDimensionSize = 4;

function getDefaultNumberOfBasePlates(
	width: number,
	height: number,
): NumberOfBasePlates {
	if (width <= 0 || height <= 0) {
		throw new Error("Requires an image with size");
	}

	if (width > height) {
		return {
			width: Math.min(
				maxDimensionSize,
				minDimensionSize * Math.round(width / height),
			),
			height: minDimensionSize,
		};
	}

	return {
		width: minDimensionSize,
		height: Math.min(
			maxDimensionSize,
			minDimensionSize * Math.round(height / width),
		),
	};
}

export default getDefaultNumberOfBasePlates;
