import { gql } from "graphql-request";
import { TransportProject } from "@brickme/project-core/src";

export default gql`
	mutation createProject($input: CreateProjectInput!) {
		createProject(input: $input) {
			id
			sourceImageKey
		}
	}
`;

export type CreateProjectMutation = {
	readonly createProject: {
		readonly id: string;
		readonly sourceImageKey: string;
	};
};

export type CreateProjectMutationVariables = {
	readonly input: {
		readonly tempSourceImageKey: string;
	} & Pick<TransportProject, "currentPicture" | "otherVersions" | "updatedAt">;
};
