import { isEqual } from "lodash-es";
import { ImageZoomOffset, PenMark } from "../model/index.ts";
import {
	getBitmapPixelIndex,
	WritableBitmap,
	cloneBitmapToWritable,
} from "../bitmap/index.ts";
import { BuildOperationInput, BuildOperationResult } from "./operation.ts";
import { writeUInt32BE } from "../utils/uint8-array.ts";

function* drawPens(
	image: WritableBitmap,
	imageZoomOffset: ImageZoomOffset,
	pens: readonly PenMark[],
) {
	for (let i = 0; i < pens.length; i++) {
		const p = pens[i];
		const x = p.position.x + imageZoomOffset.x;
		const y = p.position.y + imageZoomOffset.y;
		if (x >= 0 && x < image.width && y >= 0 && y < image.height) {
			const idx = getBitmapPixelIndex(image, x, y);
			writeUInt32BE(image.data, p.colour.rgba, idx);
		}

		if ((i + 1) % 400 === 0) {
			yield;
		}
	}
}

function createPenKey(pen: PenMark) {
	return `${pen.colour.hexString}${pen.position.x},${pen.position.y}`;
}

function calculatePenChanges(
	previousPens: readonly PenMark[],
	currentPens: readonly PenMark[],
) {
	const previousMap = Object.fromEntries(
		previousPens.map((p) => [createPenKey(p), p]),
	);
	const currentMap = Object.fromEntries(
		currentPens.map((p) => [createPenKey(p), p]),
	);
	const newPenMarks = Object.keys(currentMap)
		.filter((currentKey) => !(currentKey in previousMap))
		.map((currentKey) => currentMap[currentKey]);
	const removedPenMarks = Object.keys(previousMap)
		.filter((previousKey) => !(previousKey in currentMap))
		.map((previousKey) => previousMap[previousKey]);
	return { newPenMarks, removedPenMarks };
}

function* penOperation({
	config: { pen, imageZoomOffset },
	intermediate: { image },
	previousThisCacheEntry,
}: BuildOperationInput): Generator<undefined, BuildOperationResult> {
	// No pens - no change to previous operation
	if (pen.length === 0) {
		return { type: "no-change" };
	}

	// No previous cache entry - redraw all pens
	if (!previousThisCacheEntry) {
		const clonedImage = yield* cloneBitmapToWritable(image);
		yield* drawPens(clonedImage, imageZoomOffset, pen);
		return { type: "cloned", clonedImage };
	}

	const {
		configDependencies,
		result: { image: previousPenImage },
	} = previousThisCacheEntry;

	// Image zoom offset change, redraw all pens
	if (!isEqual(imageZoomOffset, configDependencies.imageZoomOffset)) {
		const clonedImage = yield* cloneBitmapToWritable(image);
		yield* drawPens(clonedImage, imageZoomOffset, pen);
		return { type: "cloned", clonedImage };
	}

	// Pen changes
	// Note: Would be nicer if previousThisCacheEntry type was forced to have pens property
	const { newPenMarks, removedPenMarks } = calculatePenChanges(
		configDependencies.pen ?? [],
		pen,
	);

	// Only removing pen marks - could provide optimisation here if we wanted
	if (removedPenMarks.length > 0) {
		console.warn(
			"Removed pen marks not implemented, can optimise if this becomes a common use case",
		);
		const clonedImage = yield* cloneBitmapToWritable(image);
		yield* drawPens(clonedImage, imageZoomOffset, pen);
		return { type: "cloned", clonedImage };
	}

	const newImage = yield* cloneBitmapToWritable(previousPenImage);
	yield* drawPens(newImage, imageZoomOffset, newPenMarks);
	return { type: "cloned", clonedImage: newImage };
}

export default penOperation;
