import Cookies from "js-cookie";
import { isEqual } from "lodash-es";

type VisitorData = {
	readonly distinct_id: string;
	readonly email: string;
	readonly name: string;
};

type TidioChatApi = {
	track(event: string): void; // "Abandoned Cart" |
	setVisitorData(visitorData: VisitorData): void;
};

type Subscription = {
	remove(): void;
};

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		readonly tidioChatApi?: TidioChatApi;
	}
}

function appendTidioScript(
	doc: Document,
	scriptId: string,
	onApiLoaded: (api: TidioChatApi) => void,
): Subscription {
	const onTidioChatApiReady = () => {
		if (window.tidioChatApi) {
			onApiLoaded(window.tidioChatApi);
		}
	};
	doc.addEventListener("tidioChat-ready", onTidioChatApiReady);

	const script = doc.createElement("script");
	script.src = `//code.tidio.co/${scriptId}.js`;
	script.async = true;
	doc.body.appendChild(script);

	return {
		remove() {
			doc.body.removeChild(script);
			doc.removeEventListener("tidioChat-ready", onTidioChatApiReady);
		},
	};
}

const trackedCookieName = "tidioVisitorDataTracked";

function setTidioVisitorDataTracked(
	visitorData: VisitorData,
	cookieDomain: string,
): void {
	Cookies.set(trackedCookieName, JSON.stringify(visitorData), {
		expires: 365 * 10,
		domain: cookieDomain,
	});
}

function wasTidioVisitorDataTracked(visitorData: VisitorData): boolean {
	const rawValue = Cookies.get(trackedCookieName);
	if (!rawValue) {
		return false;
	}
	let tracked: VisitorData;
	try {
		tracked = JSON.parse(rawValue);
	} catch (e) {
		return false;
	}
	return isEqual(tracked, visitorData);
}

export {
	appendTidioScript,
	wasTidioVisitorDataTracked,
	setTidioVisitorDataTracked,
};
export type { TidioChatApi, VisitorData };
