import { uint8ArrayAllocUnsafe, uint8ArrayCopy } from "../utils/uint8-array.ts";
import { Bitmap, bitmapChannels, WritableBitmap } from "./types.ts";

const chunkSize = 500_000;

function* cloneBitmapToWritable<T extends Bitmap>(
	original: T,
): Generator<undefined, T & WritableBitmap> {
	const { data, width, height } = original;
	const dataSize = width * height * bitmapChannels;
	const newData = uint8ArrayAllocUnsafe(dataSize);
	for (let i = 0; i < dataSize; i += chunkSize) {
		uint8ArrayCopy(data, newData, i, i, Math.min(i + chunkSize, dataSize));
		yield;
	}

	return {
		...original,
		data: newData,
		width,
		height,
	};
}

function* cloneBitmap<T extends Bitmap>(original: T): Generator<undefined, T> {
	return yield* cloneBitmapToWritable(original);
}

export { cloneBitmapToWritable, cloneBitmap };
