import React, {
	ReactElement,
	ReactNode,
	createContext,
	useContext,
} from "react";
import { RunProcess } from "~/utils/create-process-runner.ts";

const Context = createContext<RunProcess | undefined>(undefined);

type HeavyProcessRunnerProviderProps = {
	readonly runProcess: RunProcess;
	readonly children: ReactNode;
};

function HeavyProcessRunnerProvider({
	runProcess,
	children,
}: HeavyProcessRunnerProviderProps): ReactElement {
	return <Context.Provider value={runProcess}>{children}</Context.Provider>;
}

function useHeavyProcessRunner(): RunProcess {
	const runner = useContext(Context);
	if (runner === undefined) {
		throw new Error("No heavy process context");
	}
	return runner;
}

export { useHeavyProcessRunner, HeavyProcessRunnerProvider, Context };
