import React from "react";
import welcomeImage from "~/styles/img/welcome.svg";
import Modal from "~/components/modal.tsx";
import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import useAppSelector from "~/hooks/use-app-selector.ts";
import { guestLogin } from "~/features/user/store-slice.ts";
import { selectUser } from "~/features/user/selectors.ts";
import LoginOrSignUpForm from "./login-or-sign-up-form.tsx";

type DontLoseYourWorkModalProps = {
	readonly show: boolean;
};

function DontLoseYourWorkModal({ show }: DontLoseYourWorkModalProps) {
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectUser);

	const onGuestLogin = () => {
		dispatch(guestLogin());
	};

	return (
		<Modal show={show} slimBorder>
			<img
				src={welcomeImage}
				alt="Welcome"
				width={220}
				className="welcome-intro-image"
			/>
			<h1 className="h1">Welcome to the Brick Me Creator!</h1>
			<Modal.Text className="welcome-text">
				Please enter your email.
				<br />
				That way, your work will be automatically saved in your account.
			</Modal.Text>
			<LoginOrSignUpForm
				showJustSignedUpMessage
				finalSubmitLabel="Choose image"
			>
				{user.type === "anonymous" && (
					<>
						<div className="plaintext account-or-text">Or</div>
						<button
							type="button"
							className="guest-continue-button"
							onClick={onGuestLogin}
						>
							Continue without an account
						</button>
					</>
				)}
			</LoginOrSignUpForm>
		</Modal>
	);
}

export default DontLoseYourWorkModal;
