import React, { useCallback } from "react";
import Modal from "~/components/modal.tsx";
import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import useAppSelector from "~/hooks/use-app-selector.ts";
import ReactInvalidComponentStateError from "~/utils/react-invalid-component-state-error.ts";
import { deleteSavedProject, cancelDeleteSavedProject } from "./store-slice.ts";

function ConfirmDeleteModal() {
	const dispatch = useAppDispatch();
	const pendingDeleteDesignId = useAppSelector(
		(s) => s.user.deleteSavedProjectStatus?.id,
	);
	const onConfirm = useCallback(() => {
		if (!pendingDeleteDesignId) {
			throw new ReactInvalidComponentStateError(
				"Expected pendingDeleteDesignId to be set",
			);
		}
		dispatch(deleteSavedProject(pendingDeleteDesignId));
	}, [pendingDeleteDesignId, dispatch]);
	const onCancel = useCallback(() => {
		dispatch(cancelDeleteSavedProject());
	}, [dispatch]);

	return (
		<Modal show={!!pendingDeleteDesignId} title="WARNING!">
			<Modal.Text>Are you sure you want to Delete this project?</Modal.Text>
			<Modal.Actions
				actions={[
					{ text: "Yes", onClick: onConfirm },
					{ text: "Cancel", onClick: onCancel, type: "ghost" },
				]}
			/>
		</Modal>
	);
}

export default ConfirmDeleteModal;
