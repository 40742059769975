import { resizeBitmap } from "../bitmap/index.ts";
import { BuildOperationInput, BuildOperationResult } from "./operation.ts";

function* resizeOperation({
	config: { imageZoom, numberOfBricks },
	intermediate: { image },
}: BuildOperationInput): Generator<undefined, BuildOperationResult> {
	const xScale = numberOfBricks.width / image.width;
	const yScale = numberOfBricks.height / image.height;
	const useScale = Math.max(xScale, yScale) * imageZoom;
	const targetWidth = Math.round(image.width * useScale);
	const targetHeight = Math.round(image.height * useScale);

	const clonedImage = yield* resizeBitmap(image, targetWidth, targetHeight);
	return { type: "cloned", clonedImage };
}

export default resizeOperation;
