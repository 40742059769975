import { gql } from "graphql-request";

const deleteProjectMutation = gql`
	mutation deleteProject($id: ID!) {
		deleteProject(id: $id)
	}
`;

type DeleteProjectMutation = {
	readonly deleteProject: true;
};

type DeleteProjectMutationVariables = {
	readonly id: string;
};

export type { DeleteProjectMutation, DeleteProjectMutationVariables };
export default deleteProjectMutation;
