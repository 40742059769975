import { clipPixelChannel } from "./clip-pixel.ts";
import { runGeneratorSync } from "../utils/run-generator.ts";
import { cloneBitmapToWritable } from "./clone.ts";
import { Bitmap } from "./types.ts";
import { iterateBitmapIndex } from "./iterate.ts";

// function perChannelOperation(
//   source: Bitmap,
//   other: Bitmap,
//   operation: (first: number, second: number) => number
// ): Bitmap {
//   const result = source.clone();
//   return result.scan(
//     0,
//     0,
//     source.getWidth(),
//     source.getHeight(),
//     (_1, _2, index) => {
//       const sourceR = source.bitmap.data[index];
//       const sourceG = source.bitmap.data[index + 1];
//       const sourceB = source.bitmap.data[index + 2];
//       const otherR = other.bitmap.data[index];
//       const otherG = other.bitmap.data[index + 1];
//       const otherB = other.bitmap.data[index + 2];
//       result.bitmap.data[index] = clipPixelChannel(operation(sourceR, otherR));
//       result.bitmap.data[index + 1] = clipPixelChannel(
//         operation(sourceG, otherG)
//       );
//       result.bitmap.data[index + 2] = clipPixelChannel(
//         operation(sourceB, otherB)
//       );
//     }
//   );
// }

function perPixelOperation(
	source: Bitmap,
	other: Bitmap,
	operation: (
		r1: number,
		g1: number,
		b1: number,
		r2: number,
		g2: number,
		b2: number,
	) => Readonly<[number, number, number]>,
) {
	const result = runGeneratorSync(cloneBitmapToWritable(source));
	iterateBitmapIndex(result, (i) => {
		const [newR, newG, newB] = operation(
			source.data[i],
			source.data[i + 1],
			source.data[i + 2],
			other.data[i],
			other.data[i + 1],
			other.data[i + 2],
		);
		result.data[i] = newR;
		result.data[i + 1] = newG;
		result.data[i + 2] = newB;
		result.data[i + 3] = 0xff;
	});
	return result;
}

// export function addImage(source: Bitmap, other: Bitmap): Bitmap {
//   return perChannelOperation(source, other, (c1, c2) => c1 + c2);
// }

function pixelBrightness(r: number, g: number, b: number): number {
	return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

const pixelMaxValue = 0xff;
const pixelMidValue = pixelMaxValue * 0.5;

// export function colourBurnBlend(foreground: Bitmap, other: Bitmap): Bitmap {
//   return perPixelOperation(foreground, other, (fR, fG, fB, bR, bG, bB) => [
//     clipPixelChannel(0xff - 0xff * ((0xff - bR) / fR)),
//     clipPixelChannel(0xff - 0xff * ((0xff - bG) / fG)),
//     clipPixelChannel(0xff - 0xff * ((0xff - bB) / fB)),
//   ]);
// }

// export function colourDodgeBlend(foreground: Bitmap, other: Bitmap): Bitmap {
//   return perPixelOperation(foreground, other, (fR, fG, fB, bR, bG, bB) => [
//     clipPixelChannel(0xff * (bR / (0xff - fR))),
//     clipPixelChannel(0xff * (bG / (0xff - fG))),
//     clipPixelChannel(0xff * (bB / (0xff - fB))),
//   ]);
// }

function vividLightBlend(
	foregroundBitmap: Bitmap,
	background: Bitmap,
	amount: number,
) {
	return perPixelOperation(
		foregroundBitmap,
		background,
		(fR, fG, fB, bR, bG, bB) => {
			const fBrightness = pixelBrightness(fR, fG, fB);
			if (fBrightness <= pixelMidValue) {
				return [
					clipPixelChannel(0xff - 0xff * ((0xff - bR) / (amount * fR))),
					clipPixelChannel(0xff - 0xff * ((0xff - bG) / (amount * fG))),
					clipPixelChannel(0xff - 0xff * ((0xff - bB) / (amount * fB))),
				];
			}
			return [
				clipPixelChannel(0xff * (bR / (amount * (0xff - fR)))),
				clipPixelChannel(0xff * (bG / (amount * (0xff - fG)))),
				clipPixelChannel(0xff * (bB / (amount * (0xff - fB)))),
			];
		},
	);
}

function overlayBlend(foreground: Bitmap, background: Bitmap) {
	return perPixelOperation(foreground, background, (fR, fG, fB, bR, bG, bB) => {
		const bBrightness = pixelBrightness(bR, bG, bB);
		if (bBrightness <= pixelMidValue) {
			return [
				clipPixelChannel(0xff * ((((2 * bR) / 0xff) * fR) / 0xff)),
				clipPixelChannel(0xff * ((((2 * bG) / 0xff) * fG) / 0xff)),
				clipPixelChannel(0xff * ((((2 * bB) / 0xff) * fB) / 0xff)),
			];
		}

		return [
			clipPixelChannel(
				0xff - 0xff * ((((2 * (0xff - fR)) / 0xff) * (0xff - bR)) / 0xff),
			),
			clipPixelChannel(
				0xff - 0xff * ((((2 * (0xff - fG)) / 0xff) * (0xff - bG)) / 0xff),
			),
			clipPixelChannel(
				0xff - 0xff * ((((2 * (0xff - fB)) / 0xff) * (0xff - bB)) / 0xff),
			),
		];
	});
}

export { vividLightBlend, overlayBlend };
