import React, {
	createContext,
	ReactElement,
	ReactNode,
	useContext,
} from "react";

const Context = createContext<undefined | string>(undefined);

type WebsiteUrlProviderProps = {
	readonly url: string;
	readonly children: ReactNode;
};

function WebsiteUrlProvider({
	url,
	children,
}: WebsiteUrlProviderProps): ReactElement {
	return <Context.Provider value={url}>{children}</Context.Provider>;
}

function useWebsiteUrl(path: string): string {
	const url = useContext(Context);
	if (url === undefined) {
		throw new Error("No website url context");
	}
	return url + path;
}

export { WebsiteUrlProvider, useWebsiteUrl };
