import { GraphQLClient, gql } from "graphql-request";

type TempMediaPresignedUrlQuery = {
	readonly tempMediaPresignedUrl: {
		readonly key: string;
		readonly url: string;
	};
};

type TempMediaPresignedUrlVariables = {
	readonly fileName: string;
	readonly contentType: string;
};

function createFileStorage(apiClient: GraphQLClient) {
	return {
		saveFile: async (
			fileName: string,
			contentType: string,
			file: ArrayBuffer,
		): Promise<string> => {
			const result = await apiClient.request<
				TempMediaPresignedUrlQuery,
				TempMediaPresignedUrlVariables
			>(
				gql`
					query TempMediaPresignedUrlQuery(
						$fileName: String!
						$contentType: String!
					) {
						tempMediaPresignedUrl(
							fileName: $fileName
							contentType: $contentType
						) {
							key
							url
						}
					}
				`,
				{ fileName, contentType },
			);
			await fetch(result.tempMediaPresignedUrl.url, {
				method: "PUT",
				headers: { "Content-Type": contentType },
				body: file,
			});
			return result.tempMediaPresignedUrl.key;
		},
	};
}

type FileStorage = ReturnType<typeof createFileStorage>;

export type { FileStorage };
export default createFileStorage;
