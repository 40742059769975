import { findOnlyOrThrow } from "@dhau/lang-extras";
import { Country } from "~/frontend-common/localisation.ts";
import { AppState } from "~/store/root-reducer.ts";
import { ShopifyPrices } from "~/features/reference/shopify-prices.ts";
import { isOnceOffLoaded } from "~/utils/loading.ts";
import { UserStatus } from "./store-slice.ts";
import { AppAuthUser } from "./app-auth-user.ts";

function selectUser(state: AppState): UserStatus {
	return state.user.user;
}

function selectJustSignedUp(state: AppState) {
	return state.user.justSignedUp;
}

function selectSignedInUser(state: AppState): AppAuthUser {
	const user = selectUser(state);
	if (user.type !== "signedIn") {
		// return null
		throw new Error("Not signed in");
	}
	return user.user;
}

function isAdminUser(state: AppState): boolean {
	const user = selectUser(state);
	if (user.type !== "signedIn") {
		return false;
	}
	return user.user.isAdminAccount;
}

function selectCountry({
	user: { selectedCountryCode },
	reference: { countries },
}: AppState): Country {
	if (!isOnceOffLoaded(countries)) {
		throw new Error("Countries not loaded");
	}
	return findOnlyOrThrow(
		countries.data,
		(c) => c.code === selectedCountryCode,
		`Country not found for code ${selectedCountryCode}`,
	);
}

function selectHasCountries({ reference: { countries } }: AppState): boolean {
	return isOnceOffLoaded(countries);
}

function selectCountries(state: AppState) {
	const {
		reference: { countries },
	} = state;
	if (!isOnceOffLoaded(countries)) {
		return [];
	}
	return countries.data;
}

function selectShopifyPricesForSelectedCountry({
	reference: { picPricesByCountryCode },
	user: { selectedCountryCode },
}: AppState): ShopifyPrices | undefined {
	if (!selectedCountryCode) {
		throw new Error("No selected country");
	}
	return picPricesByCountryCode[selectedCountryCode];
}

function selectMeasurementsPreference(state: AppState) {
	return state.user.measurementsPreference;
}

export {
	selectHasCountries,
	selectCountries,
	isAdminUser,
	selectCountry,
	selectSignedInUser,
	selectUser,
	selectJustSignedUp,
	selectMeasurementsPreference,
	selectShopifyPricesForSelectedCountry,
};
