import React, { ReactElement } from "react";
import Modal from "~/components/modal.tsx";

type ConfirmStartNewProjectModalProps = {
	readonly show: boolean;
	readonly onConfirm: () => void;
	readonly onCancel: () => void;
};

function ConfirmStartNewProjectModal({
	show,
	onConfirm,
	onCancel,
}: ConfirmStartNewProjectModalProps): ReactElement {
	return (
		<Modal show={show} title="WARNING!" onClose={onCancel}>
			<Modal.Text>Are you sure you want to start a new project?</Modal.Text>
			<Modal.Actions
				actions={[
					{ text: "Cancel", onClick: onCancel, type: "ghost" },
					{ text: "Start over", onClick: onConfirm },
				]}
			/>
		</Modal>
	);
}

export default ConfirmStartNewProjectModal;
