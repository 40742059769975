import { readUInt32BE, writeUInt32BE } from "../utils/uint8-array.ts";
import { Bitmap, WritableBitmap } from "./types.ts";

function getBitmapPixelIndex(image: Bitmap, x: number, y: number) {
	// eslint-disable-next-line no-bitwise
	return (image.width * y + x) << 2;
}

function getBitmapPixelColour(image: Bitmap, x: number, y: number) {
	const idx = getBitmapPixelIndex(image, x, y);
	return readUInt32BE(image.data, idx);
}

function setBitmapPixelColour(
	image: WritableBitmap,
	colour: number,
	x: number,
	y: number,
) {
	const idx = getBitmapPixelIndex(image, x, y);
	writeUInt32BE(image.data, colour, idx);
}

export { getBitmapPixelColour, setBitmapPixelColour, getBitmapPixelIndex };
