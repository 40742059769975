import {
	compact,
	range,
	mapKeys,
	mapValues,
	isEqual,
	sortBy,
	last,
	omit,
	pick,
	sum,
} from "lodash-es";
import {
	PictureConfiguration,
	BrickedPictureOutput,
	Palette,
	BrickCounts,
	BrickCount,
	BuildBitmap,
} from "../model/index.ts";
import { runGeneratorAsync, runGeneratorSync } from "../utils/run-generator.ts";
import { readUInt32BE } from "../utils/uint8-array.ts";
import { count } from "../utils/array.ts";
import {
	BuildCacheEntry,
	doesCacheEntryMatch,
	ImageBuildCache,
	SourceKey,
	takeValidCache,
} from "./cache.ts";
import {
	BuildOperationIntermediate,
	BuildOperationServices,
	OperationBuildSources,
	BuildOperation,
} from "./operation.ts";
import paletteModeOperation from "./palette-mode-operation.ts";
import colourCorrectionOperation from "./colour-correction-operation.ts";
import penOperation from "./pen-operation.ts";
import resizeOperation from "./resize-operation.ts";
import cropOperation from "./crop-operation.ts";
import detailFilterOperation from "./detail-filter-operation.ts";
import removeBackgroundOperation from "./remove-background-operation.ts";
import faceFiltersOperation from "./face-filters-operation.ts";
import { Bitmap, bitmapChannels } from "../bitmap/index.ts";
import constrainImageZoomOffset from "../utils/constrain-image-zoom-offset.ts";

const operations: readonly BuildOperation[] = [
	{
		name: "Face filters",
		perform: faceFiltersOperation,
		configDependencies: ["enhanceFaces", "fixFaceColours"],
	},
	// Was moved to after colour corrections at one point, which in some sense is a
	// more logical place for it to go since the colour corrections don't apply to
	// it. Problem was that we then needed to do calculations to original mask to get
	// within cropped + resized space (not a big deal). But more so we lost aliasing
	// quality of the mask since we were now working in sized down space.
	{
		name: "Remove background",
		perform: removeBackgroundOperation,
		configDependencies: ["removeBackground"],
	},
	{
		name: "Resize",
		perform: resizeOperation,
		configDependencies: ["imageZoom", "numberOfBricks"],
	},
	{
		name: "Crop",
		perform: cropOperation,
		configDependencies: ["imageZoomOffset", "numberOfBricks"], // , "imageZoom"],
	},
	{
		name: "Detail filter",
		perform: detailFilterOperation,
		configDependencies: ["detailFilter", "removeBackground"],
	},
	{
		name: "Colour correction",
		perform: colourCorrectionOperation,
		configDependencies: [
			"brightness",
			"contrast",
			"saturation",
			"imageZoomOffset",
			"imageZoom",
			"removeBackground",
		],
	},
	{
		name: "Palette",
		perform: paletteModeOperation,
		configDependencies: ["paletteMode"],
	},
	{
		name: "Pen",
		perform: penOperation,
		configDependencies: ["pen", "imageZoomOffset"],
	},
];

type BuildSources = OperationBuildSources & {
	readonly original: Bitmap;
};

function sourcesToKeys(sources: BuildSources): readonly SourceKey[] {
	return compact([
		sources.backgroundMask ? ("background-mask" as const) : undefined,
		sources.enhanceFaces ? ("enhance-faces" as const) : undefined,
		sources.colorisation ? ("colorisation" as const) : undefined,
		sources.facesMask ? ("face-mask" as const) : undefined,
	]);
}

function* countBricks(
	systemPalette: Palette,
	config: PictureConfiguration,
	image: BuildBitmap,
): Generator<undefined, BrickCounts> {
	const colourMap = Object.fromEntries(
		systemPalette.map(({ brick }) => [brick.rgba, brick.hexString]),
	);
	const channelIndices = range(
		0,
		image.width * image.height * bitmapChannels,
		bitmapChannels,
	);
	yield;
	const pixelValues = channelIndices
		.map((i) => readUInt32BE(image.data, i))
		.filter((p) => p !== image.noBrickColour?.rgba);
	yield;
	const pixelCounts = count(pixelValues);
	yield;
	const allBrickCounts = mapKeys(pixelCounts, (_, rgba) => {
		if (!(rgba in colourMap)) {
			throw new Error(`Couldn't find rgba ${rgba}`);
		}
		return colourMap[rgba];
	});
	yield;
	const penInFrame = config.pen.filter((p) => {
		const adjustedX = p.position.x + config.imageZoomOffset.x;
		const adjustedY = p.position.y + config.imageZoomOffset.y;
		return (
			adjustedX >= 0 &&
			adjustedX < config.numberOfBricks.width &&
			adjustedY >= 0 &&
			adjustedY < config.numberOfBricks.height
		);
	});
	yield;
	const penCounts = count(penInFrame.map((p) => p.colour.hexString));
	yield;
	return mapValues(allBrickCounts, (entryCount, hex): BrickCount => {
		if (!(hex in penCounts)) {
			return { type: "palette", count: entryCount };
		}
		if (entryCount === penCounts[hex]) {
			return { type: "pen-only", count: entryCount };
		}
		return { type: "palette", count: entryCount };
	});
}

type CacheMode =
	| { readonly type: "no-cache" }
	| { readonly type: "new-cache" }
	| { readonly type: "existing-cache"; readonly cache: ImageBuildCache };

type BuildResult = {
	readonly output: BrickedPictureOutput;
	readonly updatedCache?: ImageBuildCache;
};

function* createBrickedImageFromSource(
	services: BuildOperationServices,
	sources: BuildSources,
	systemPalette: Palette,
	config: PictureConfiguration,
	cacheMode: CacheMode,
): Generator<undefined, BuildResult> {
	if (process.env.NODE_ENV === "development") {
		const altSourceNames = [
			"backgroundMask",
			"facesMask",
			"enhanceFaces",
			"colorisation",
		] as const;
		altSourceNames.forEach((altSourceName) => {
			const altSource = sources[altSourceName];
			if (
				altSource &&
				(altSource.width !== sources.original.width ||
					altSource.height !== sources.original.height)
			) {
				console.warn(
					`Source ${altSourceName} (${altSource.width}x${altSource.height}) has different dimentions to original ${sources.original.width}x${sources.original.height}}`,
				);
			}
		});
	}

	// Check if valid
	// TODO: Other checks. e.g. positive, negative numbers, etc
	const constrainedOffset = constrainImageZoomOffset(
		sources.original,
		config,
		config.imageZoomOffset,
	);
	if (!isEqual(constrainedOffset, config.imageZoomOffset)) {
		const errorMessage = `Invalid image zoom offset provided ${JSON.stringify(
			config.imageZoomOffset,
		)} constrains to ${JSON.stringify(constrainedOffset)}`;
		if (
			process.env.NODE_ENV === "development" ||
			process.env.NODE_ENV === "test"
		) {
			throw new Error(errorMessage);
		} else {
			console.error(errorMessage);
		}
	}

	// TODO: Whatever the first operation ends up becoming (probably resizing), it could become a
	// clone version. i.e. write to a new data rather than the current one
	// Actually, resize already is a cloning operation!
	// Resize is a logical first operation - not performed much

	const availableSources = sourcesToKeys(sources);

	// Find which cache "branch" and how much of it is usable by the current config
	let initialIntermediate: BuildOperationIntermediate;
	let currentCache:
		| {
				entries: readonly BuildCacheEntry[];
				step1AlternateEntries: readonly BuildCacheEntry[];
		  }
		| undefined;

	if (cacheMode.type === "existing-cache") {
		if (
			isEqual(
				sortBy(availableSources),
				sortBy(cacheMode.cache.availableSources),
			)
		) {
			// Find cache entries we can use/that are still valid
			currentCache = {
				entries: takeValidCache(config, cacheMode.cache),
				step1AlternateEntries: cacheMode.cache.step1AlternateEntries,
			};

			// Use step 1 alternatives if "regular" cache not usable
			if (currentCache.entries.length === 0) {
				const matchingStep1Alternative =
					currentCache.step1AlternateEntries.find((e) =>
						doesCacheEntryMatch(config, e),
					);
				if (matchingStep1Alternative) {
					currentCache.entries = [matchingStep1Alternative];
				}
			}
		} else {
			// If sources change, we need to nuke the whole cache
			currentCache = {
				entries: [],
				step1AlternateEntries: [],
			};
		}

		// Get cache entry to start with
		const workingEntry = last(currentCache.entries);
		if (workingEntry) {
			initialIntermediate = workingEntry.result;
		} else {
			initialIntermediate = {
				image: {
					...sources.original,
					noBrickColour: undefined,
				},
				operationsMissingSources: [],
			};
		}

		// Shortcut - no work to do since this configuration is fully cached
		if (currentCache.entries.length === operations.length) {
			// Was doing this for a long time. Don't think it's needed and seems to work
			// without it. (8/6/2023)
			// const shortcutResultImage = yield* cloneBitmap(workingImage);
			return {
				output: {
					image: initialIntermediate.image,
					operationsMissingSources: cacheMode.cache.operationsMissingSources,
					brickCounts: cacheMode.cache.finalBrickCounts,
				},
				updatedCache: cacheMode.cache,
			};
		}
	} else if (cacheMode.type === "new-cache") {
		initialIntermediate = {
			image: {
				...sources.original,
				noBrickColour: undefined,
			},
			operationsMissingSources: [],
		};
		currentCache = {
			entries: [],
			step1AlternateEntries: [],
		};
	} else {
		initialIntermediate = {
			image: {
				...sources.original,
				noBrickColour: undefined,
			},
			operationsMissingSources: [],
		};
	}

	// Reporting cache hits + misses
	if (process.env.NODE_ENV === "development" && currentCache !== undefined) {
		if (currentCache.entries.length > 0) {
			// eslint-disable-next-line no-console
			console.log(
				"Build Cache Hit",
				currentCache.entries.length,
				"/",
				operations.length,
			);
		} else {
			console.warn("Build Cache MISS");
		}
	}

	// First operation used for caching. See below
	const firstOperationIndex = currentCache ? currentCache.entries.length : 0;
	const firstOperation = operations[firstOperationIndex];
	let intermediate: BuildOperationIntermediate = initialIntermediate;

	// We're building upon the previously cached operations that don't need to be redone.
	const operationSources = omit(sources, "original");
	for (let i = firstOperationIndex; i < operations.length; i++) {
		const operation = operations[i];
		// console.time(`Op ${operation.name}`);
		const operationResult = yield* operation.perform({
			...services,
			systemPalette,
			sources: operationSources,
			config,
			intermediate,
			// We should only provide the previous cache entry if it's the first operation.
			// e.g. pen can build on previous pen output ONLY if it's the first operation.
			// If the palette mode changes for instance, then we need to nuke the previous
			// pen output.
			previousThisCacheEntry:
				operation === firstOperation && cacheMode.type === "existing-cache"
					? cacheMode.cache.entries[i]
					: undefined,
		});
		// console.timeEnd(`Op ${operation.name}`);
		intermediate = {
			...intermediate,
			operationsMissingSources: [
				...intermediate.operationsMissingSources,
				...(operationResult.operationsMissingSources ?? []),
			],
		};
		if (operationResult.type === "cloned") {
			intermediate = {
				...intermediate,
				image: operationResult.clonedImage,
			};
		}

		if (currentCache) {
			// Operations must produce a clone, so safe to store this here.
			const newEntry = {
				configDependencies: Object.fromEntries(
					operation.configDependencies.map((d) => [d, config[d]]),
				),
				result: pick(intermediate, "image", "operationsMissingSources"),
			};

			if (
				i === 0 &&
				!currentCache.step1AlternateEntries.some((e) =>
					isEqual(e.configDependencies, newEntry.configDependencies),
				)
			) {
				currentCache.step1AlternateEntries = [
					...currentCache.step1AlternateEntries,
					newEntry,
				];
			}
			currentCache.entries = [...currentCache.entries, newEntry];
		}
	}
	const { image, operationsMissingSources } = intermediate;

	// Brick counts should be in in createBricked. But the typing gets very
	// complicated to add brickCounts to the cache entries, etc.

	// NOTE: Once had the dream of having a running brick count that each operation
	// updates. Given the number of operations and black box opencv style operations
	// now, I think this would be much less efficient than this final iteration
	// for most builds.
	// const brickCounts = yield* timeGeneratorOperation(
	// 	"Final brick count",
	// 	countBricks(systemPalette, config, image),
	// );
	const brickCounts = yield* countBricks(systemPalette, config, image);

	if (process.env.NODE_ENV === "development") {
		const totalCount = sum(Object.values(brickCounts).map((c) => c.count));
		const expected = image.width * image.height;
		if (totalCount !== expected) {
			// eslint-disable-next-line no-console
			console.log(brickCounts);
			console.error(
				`Expected total brick count of ${expected}, instead got ${totalCount}`,
			);
		}
	}

	return {
		output: {
			image,
			operationsMissingSources,
			brickCounts,
		},
		updatedCache: currentCache
			? {
					availableSources,
					entries: currentCache.entries,
					step1AlternateEntries: currentCache.step1AlternateEntries,
					finalBrickCounts: brickCounts,
					operationsMissingSources,
				}
			: undefined,
	};
}

type AsyncOptions = {
	readonly abortSignal: AbortSignal;
};

// TODO: Better typing. Only possible undefined return is abortSignal provided
function createBrickedImageFromSourceNoCacheSync(
	services: BuildOperationServices,
	sources: BuildSources,
	systemPalette: Palette,
	picture: PictureConfiguration,
): BrickedPictureOutput {
	const generator = createBrickedImageFromSource(
		services,
		sources,
		systemPalette,
		picture,
		{ type: "no-cache" },
	);
	return runGeneratorSync(generator).output;
}

function* createBrickedImageFromSourceNoCache(
	services: BuildOperationServices,
	sources: BuildSources,
	systemPalette: Palette,
	config: PictureConfiguration,
): Generator<undefined, BrickedPictureOutput> {
	const result = yield* createBrickedImageFromSource(
		services,
		sources,
		systemPalette,
		config,
		{ type: "no-cache" },
	);
	return result.output;
}

function* createBrickedImageFromSourceWithCache(
	services: BuildOperationServices,
	sources: BuildSources,
	systemPalette: Palette,
	picture: PictureConfiguration,
	previousCache: ImageBuildCache | undefined,
): Generator<undefined, BuildResult> {
	return yield* createBrickedImageFromSource(
		services,
		sources,
		systemPalette,
		picture,
		previousCache
			? { type: "existing-cache", cache: previousCache }
			: { type: "new-cache" },
	);
}

/* eslint-disable @typescript-eslint/unified-signatures */
async function createBrickedImageFromSourceWithCacheAsync(
	services: BuildOperationServices,
	sources: BuildSources,
	systemPalette: Palette,
	picture: PictureConfiguration,
	previousCache: ImageBuildCache | undefined,
): Promise<BuildResult>;
async function createBrickedImageFromSourceWithCacheAsync(
	services: BuildOperationServices,
	sources: BuildSources,
	systemPalette: Palette,
	picture: PictureConfiguration,
	previousCache: ImageBuildCache | undefined,
	options: undefined,
): Promise<BuildResult>;
async function createBrickedImageFromSourceWithCacheAsync(
	services: BuildOperationServices,
	sources: BuildSources,
	systemPalette: Palette,
	picture: PictureConfiguration,
	previousCache: ImageBuildCache | undefined,
	options: AsyncOptions,
): Promise<BuildResult | undefined>;

async function createBrickedImageFromSourceWithCacheAsync(
	services: BuildOperationServices,
	sources: BuildSources,
	systemPalette: Palette,
	picture: PictureConfiguration,
	previousCache: ImageBuildCache | undefined,
	options?: AsyncOptions,
): Promise<BuildResult | undefined> {
	const generator = createBrickedImageFromSource(
		services,
		sources,
		systemPalette,
		picture,
		previousCache
			? { type: "existing-cache", cache: previousCache }
			: { type: "new-cache" },
	);
	if (options) {
		return runGeneratorAsync(generator, options);
	}
	return runGeneratorAsync(generator);
}

export type { BuildResult, BuildSources };
export {
	createBrickedImageFromSourceWithCache,
	createBrickedImageFromSourceWithCacheAsync,
	createBrickedImageFromSourceNoCache,
	createBrickedImageFromSourceNoCacheSync,
};
