/* eslint-disable no-bitwise */
import { BrickColour } from "./bricks.ts";

const redMask32 = 0xff000000;
const greenMask32 = 0x00ff0000;
const blueMask32 = 0x0000ff00;

// TODO: Explore https://github.com/ibezkrovnyi/image-quantization distance
// formulas, might find one that's better than euclidean RGB but performance still
// ok

function getColourDistance(colour: BrickColour, rgba: number) {
	// This red calc is too long, not sure why it works or how to simplify
	const r = (((rgba & redMask32) >> 24) >>> 0) & 0xff;
	const g = (rgba & greenMask32) >> 16;
	const b = (rgba & blueMask32) >> 8;

	const dR = colour.r - r;
	const dG = colour.g - g;
	const dB = colour.b - b;
	return Math.sqrt(dR * dR + dG * dG + dB * dB);
}

export default getColourDistance;

// LAB is a colour space where colours are (close to) linearly arranged
// i.e. 10 units of change always = 10 units of rgb change.
// This isn't the case with an RGB comparison SQRT(rDiff^2 + gDiff^2 + bDiff^2)

// This is more accurate, but is just too computationally expensive.
// For a 4x3 it took about 60ms longer (70ms vs 10ms)
// const lab = rgbToLab(r, g, b);
// const deltaL = lab.l - colour.lab.l;
// const deltaA = lab.a - colour.lab.a;
// const deltaB = lab.b - colour.lab.b;
// const c1 = Math.sqrt(lab.a * lab.a + lab.b * lab.b);
// const c2 = Math.sqrt(colour.lab.a * colour.lab.a + colour.lab.b * colour.lab.b);
// const deltaC = c1 - c2;
// let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
// deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
// const sc = 1.0 + 0.045 * c1;
// const sh = 1.0 + 0.015 * c1;
// const deltaLKlsl = deltaL / (1.0);
// const deltaCkcsc = deltaC / (sc);
// const deltaHkhsh = deltaH / (sh);
// const i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
// return i < 0 ? 0 : Math.sqrt(i);
