import React, { ReactElement, useCallback, useState, useEffect } from "react";
import { lastOrThrow } from "@dhau/lang-extras";
import { Bitmap, loadBitmapFromUrl } from "@brickme/project-core/src";
import { sortBy } from "lodash-es";
import Modal from "~/components/modal.tsx";
import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import useAppSelector from "~/hooks/use-app-selector.ts";
import Button from "~/components/ui/button.tsx";
import LoadingAnimation from "~/components/ui/loading-animation.tsx";
import { pushGtmData } from "~/features/analytics/index.ts";
import { ensurePicToBrickCategoriesLoaded } from "~/features/reference/store-slice.ts";

type LibraryModalProps = {
	readonly show: boolean;
	readonly onCancel: () => void;
	readonly onSelect: (image: Bitmap, name: string) => void;
};

function LibraryModal({
	show,
	onCancel,
	onSelect,
}: LibraryModalProps): ReactElement {
	const sourceCategories = useAppSelector(
		(s) => s.reference.picToBrickCategories,
	);
	const sortedCategories = sourceCategories
		? sortBy(sourceCategories, (c) => c.name)
		: undefined;
	const dispatch = useAppDispatch();
	useEffect(() => {
		if (show) {
			dispatch(ensurePicToBrickCategoriesLoaded());
		}
	}, [show, dispatch]);
	const [initialCategoryLoadDone, setInitialCategoryLoadDone] =
		useState(!!sortedCategories);
	const [selectedCategoryId, setSelectedCategoryId] = useState<
		undefined | string
	>(sortedCategories ? sortedCategories[0]?.id : undefined);
	const pics =
		sortedCategories?.find((c) => c.id === selectedCategoryId)?.pics ?? [];
	useEffect(() => {
		if (!sortedCategories || initialCategoryLoadDone) {
			return;
		}
		setInitialCategoryLoadDone(true);
		setSelectedCategoryId(sortedCategories[0]?.id);
	}, [sortedCategories, initialCategoryLoadDone]);

	const [loadingImage, setLoadingImage] = useState<string | undefined>(
		undefined,
	);
	const onSelectUrl = useCallback(
		async (url: string) => {
			setLoadingImage(url);
			try {
				const image = await loadBitmapFromUrl(url);
				onSelect(image, lastOrThrow(url.split("/")));
			} finally {
				setLoadingImage(undefined);
			}
		},
		[onSelect],
	);

	// GTM
	useEffect(() => {
		if (show) {
			dispatch(
				pushGtmData({
					event: "PageView",
					page_title: "Library",
				}),
			);
		}
	}, [dispatch, show]);

	return (
		<Modal
			show={show}
			title="Library"
			size="large"
			bodyClassName="library-modal-body"
			onClose={onCancel}
		>
			{sortedCategories === undefined && (
				<LoadingAnimation>Loading pics...</LoadingAnimation>
			)}
			{loadingImage && <LoadingAnimation>Loading image...</LoadingAnimation>}
			{!!sortedCategories && !loadingImage && (
				<div>Select one of our images below.</div>
			)}
			<div className="library-folders">
				{(sortedCategories ?? []).map((category) => (
					<Button
						key={category.id}
						size="small"
						color={selectedCategoryId === category.id ? "darkblue" : "yellow"}
						onClick={() => setSelectedCategoryId(category.id)}
						disabled={!!loadingImage}
					>
						{category.name}
					</Button>
				))}
			</div>
			<div className="pics-container">
				{pics.map((pic) => (
					<button
						key={pic.id}
						type="button"
						onClick={() => onSelectUrl(pic.imageUrl)}
						style={{ margin: 10 }}
						disabled={!!loadingImage}
					>
						<img src={pic.thumbnailImageUrl} alt={pic.name} height={120} />
					</button>
				))}
			</div>
			<Modal.Actions
				actions={[
					{
						text: "Cancel",
						onClick: onCancel,
						type: "ghost",
						disabled: !!loadingImage,
					},
				]}
			/>
		</Modal>
	);
}

export default LibraryModal;
