import React, { useCallback } from "react";
import Modal from "~/components/modal.tsx";
import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import { hideLoginPrompt } from "./store-slice.ts";
import LoginOrSignUpForm from "./login-or-sign-up-form.tsx";

type LoginPromptModalProps = {
	readonly show: boolean;
};

function LoginPromptModal({ show }: LoginPromptModalProps) {
	const dispatch = useAppDispatch();

	const onComplete = useCallback(() => {
		dispatch(hideLoginPrompt());
	}, [dispatch]);

	return (
		<Modal show={show} title="Sign in" onClose={onComplete}>
			<LoginOrSignUpForm
				showJustSignedUpMessage
				onSuccess={onComplete}
				finalSubmitLabel="Sign up"
			/>
		</Modal>
	);
}

export default LoginPromptModal;
