import { BrickColour } from "./bricks.ts";
import { Palette } from "./picture.ts";
import getColourDistance from "./getColourDistance.ts";

// TODO: Should pass this cache in instead of using a global cache
const paletteCaches = new WeakMap<Palette, Record<string, Palette[number]>>();

function getClosestPaletteItem(palette: Palette, rgba: number) {
	if (palette.length === 0) {
		throw new Error("Invalid palette (empty)");
	}

	const rgbaString = rgba.toString();
	let cache = paletteCaches.get(palette);
	if (cache === undefined) {
		cache = {};
		paletteCaches.set(palette, cache);
	} else if (rgbaString in cache) {
		return cache[rgbaString];
	}

	let closest: Palette[number] = palette[0];
	let minDistance = Number.MAX_VALUE;
	// Note, important that check the first item here  even though it's set as
	// the closest above
	palette.forEach((p) => {
		const distance = getColourDistance(p.brick, rgba);
		if (distance < minDistance) {
			minDistance = distance;
			closest = p;
		}
	});
	cache[rgbaString] = closest;
	return closest;
}

function getClosestPaletteBrick(palette: Palette, rgba: number): BrickColour {
	return getClosestPaletteItem(palette, rgba).brick;
}

export { getClosestPaletteBrick, getClosestPaletteItem };
