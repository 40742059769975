import { gql } from "graphql-request";
import {
	transportSavedProjectFragment,
	FullSavedProject,
} from "./get-saved-designs-query.ts";

type GetSavedDesignByIdQuery = {
	readonly viewer: {
		readonly savedDesignById: FullSavedProject;
	};
};

type GetSavedDesignByIdVariables = {
	readonly id: string;
};

const getSavedDesignByIdQuery = gql`
	query getSavedDesignByIdQuery($id: ID!) {
		viewer {
			id
			savedDesignById(id: $id) {
				...transportSavedProject
			}
		}
	}
	${transportSavedProjectFragment}
`;

export type { GetSavedDesignByIdVariables, GetSavedDesignByIdQuery };
export default getSavedDesignByIdQuery;
