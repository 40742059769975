import { cropBitmap } from "../bitmap/index.ts";
import { BuildOperationInput, BuildOperationResult } from "./operation.ts";

// Assumes image already resized according to zoom
function* cropOperation({
	config: { imageZoomOffset, numberOfBricks },
	intermediate: { image },
}: BuildOperationInput): Generator<undefined, BuildOperationResult> {
	// Image zoom
	const freeX = image.width - numberOfBricks.width;
	const freeY = image.height - numberOfBricks.height;

	// We have min max protections here for imageZoomOffset. It's possible the ui will send back
	// invalid image offsets sometimes (as a bug) - we want to be flexible enough to handle that
	const cropX = Math.min(
		freeX,
		Math.max(0, Math.round(freeX / 2 - imageZoomOffset.x)),
	);
	const cropY = Math.min(
		freeY,
		Math.max(0, Math.round(freeY / 2 - imageZoomOffset.y)),
	);
	const cropped = yield* cropBitmap(
		image,
		cropX,
		cropY,
		Math.round(numberOfBricks.width),
		Math.round(numberOfBricks.height),
	);
	return { type: "cloned", clonedImage: cropped };
}

export default cropOperation;
