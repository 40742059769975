import React, { ReactElement, useCallback } from "react";
import Modal from "~/components/modal.tsx";
import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import { guestLogout } from "~/features/user/store-slice.ts";
import { hideGuestPromo } from "./store-slice.ts";

type GuestPromoModalProps = {
	readonly show: boolean;
};

export default function GuestPromoModal({
	show,
}: GuestPromoModalProps): ReactElement {
	const dispatch = useAppDispatch();

	const onGoBack = useCallback(() => {
		dispatch(hideGuestPromo());
	}, [dispatch]);
	const onClose = useCallback(() => {
		dispatch(hideGuestPromo());
		dispatch(guestLogout());
	}, [dispatch]);

	return (
		<Modal title="Enjoying the Brick Me Creator?" show={show} onClose={onClose}>
			<Modal.Text>
				Enjoying the Brick Me Creator? As a registered user, you&apos;ll be able
				to create awesome brick mosaics using your own photos! Just sign in with
				your email address. Don&apos;t worry, we will never share your email and
				we won&apos;t send you marketing emails if you don&apos;t want us to.
			</Modal.Text>
			<Modal.Actions
				actions={[
					{
						text: "Go back and continue as a guest",
						onClick: onGoBack,
						type: "ghost",
					},
					{
						text: "Sign in and use my own photos!",
						onClick: onClose,
						type: "ghost",
					},
				]}
			/>
		</Modal>
	);
}
