import { compact } from "lodash-es";
import { VisitorData } from "~/frontend-common/tidio.ts";
import { AuthUser } from "~/frontend-common/auth.ts";

export default function authUserToTidioVisitor(
	authUser: AuthUser,
): VisitorData {
	return {
		distinct_id: authUser.id,
		email: authUser.emailAddress,
		name: compact([authUser.firstName, authUser.lastName]).join(" "),
	};
}
