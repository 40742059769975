/* eslint-disable no-param-reassign */
import { bitmapChannels, cloneBitmapToWritable } from "../bitmap/index.ts";
import { hslToRgb, rgbToHsl } from "../utils/colour-conversions.ts";
import { floatFloor } from "../utils/float.ts";
import compileMaskingProps from "./compile-masking-props.ts";
import { hspToRgb, rgbToHsp } from "./hsp.ts";
import { BuildOperationInput, BuildOperationResult } from "./operation.ts";

// Careful, ordering that these are applied matters
function* colourCorrectionOperation({
	sources: { backgroundMask },
	config,
	intermediate: { image },
}: BuildOperationInput): Generator<undefined, BuildOperationResult> {
	const { brightness, contrast, saturation } = config;
	if (brightness === 0 && contrast === 0 && saturation === 0) {
		return { type: "no-change" };
	}

	const applyBrightness = brightness !== 0;
	const applyContrast = contrast !== 0;
	const applySaturation = saturation !== 0;

	// Important to take reference since it could change underneath
	const clonedImage = yield* cloneBitmapToWritable(image);
	const { data, width, height } = clonedImage;
	// This is a bit fudged to try and match photoshop's range
	const brightnessPositive = 0xff / 2.8;
	const brightnessNegative = 0xff / 9;
	const brightnessOffset =
		brightness * (brightness > 0 ? brightnessPositive : brightnessNegative);
	const adjustedContrast = contrast > 0 ? contrast * 0.2 : contrast * 0.15;

	// We don't want to apply colour corrections to background.
	// Background mask is in scale of original source, so need some re-calc.
	const maskingProps = compileMaskingProps(config, backgroundMask, image);

	const numPixelChannels = width * height * bitmapChannels;
	for (let i = 0; i < numPixelChannels; i += bitmapChannels) {
		if (maskingProps !== undefined) {
			const pixelIndex = i / bitmapChannels;
			const x = pixelIndex % width;
			const y = (pixelIndex - x) / width;
			const maskX = floatFloor(
				(x + maskingProps.croppedXOffset) / maskingProps.usedResizeScale,
			);
			const maskY = floatFloor(
				(y + maskingProps.croppedYOffset) / maskingProps.usedResizeScale,
			);
			if (maskingProps.foregroundRatio[maskX][maskY] === 0) {
				continue;
			}
		}

		let r = data[i];
		let g = data[i + 1];
		let b = data[i + 2];

		// Found a comment somewhere saying you'd typically apply contrast first.
		// Not a super authorative source, but the only source I've seen atm.
		// Can apply all at same time, looks complex though:
		// https://docs.rainmeter.net/tips/colormatrix-guide/
		if (applyContrast) {
			const factor = (adjustedContrast + 1) / (1 - adjustedContrast);
			/* eslint-disable no-nested-ternary */
			// Got to be a bitwise way to do these roundings?
			r = Math.floor(factor * (r - 127) + 127);
			r = r < 0 ? 0 : r > 0xff ? 0xff : r;
			g = Math.floor(factor * (g - 127) + 127);
			g = g < 0 ? 0 : g > 0xff ? 0xff : g;
			b = Math.floor(factor * (b - 127) + 127);
			b = b < 0 ? 0 : b > 0xff ? 0xff : b;
			/* eslint-enable no-nested-ternary */
		}

		if (applyBrightness) {
			// convert to HSP, increase P (perceived brightness), then back to rgb
			const hsp = rgbToHsp(r, g, b);
			const newRgb = hspToRgb(
				hsp.h,
				hsp.s,
				Math.max(0, Math.min(0xff, hsp.p + brightnessOffset)),
			);
			r = newRgb.r;
			g = newRgb.g;
			b = newRgb.b;
		}

		if (applySaturation) {
			if (saturation > 0) {
				const hsl = rgbToHsl(r, g, b);
				const result = hslToRgb(hsl.h, Math.min(1, hsl.s + saturation), hsl.l);
				r = result.r;
				g = result.g;
				b = result.b;
			} else if (saturation < 0) {
				const hsl = rgbToHsl(r, g, b);

				// const c = tinyColor({ r, g, b });
				// const tinyHsl = c.toHsl();
				// if (hsl.saturation !== tinyHsl.s ||
				// 	hsl.lightness !== tinyHsl.l ||
				// 	hsl.hue !== tinyHsl.h) {
				// 		console.log("ERROR", hsl, tinyHsl);
				// 		console.log("RGB", r, g, b);
				// 		console.log("tiny RGB", c.toRgb().r, c.toRgb().g, c.toRgb().b);
				// 		throw new Error("Mismatch");
				// }

				const result = hslToRgb(hsl.h, Math.max(0, hsl.s + saturation), hsl.l);

				// const resultHsl = rgbToHsl(result.r, result.g, result.b);
				// const tinySatRes = c.desaturate(-100 * saturation).toRgb();

				// if (tinySatRes.r !== result.r) {
				// 	console.log("R", tinySatRes.r, result.r);
				// 	console.log("G", tinySatRes.g, result.g);
				// 	console.log("B", tinySatRes.b, result.b);
				// 	console.log("My before convert", hsl.hue, Math.max(0, hsl.saturation + saturation * 100), hsl.saturation)
				// 	const t = tinyColor({ h: hsl.hue, s: Math.max(0, hsl.saturation + saturation * 100), l: hsl.lightness }).toHsl();
				// 	console.log("Tiny before convert", t.h, t.s, t.l)
				// 	console.log("Adding to", hsl.saturation, saturation)
				// 	throw new Error("Diff in res")
				// }

				// const tinyRes = satRes.toRgb();
				// const tinyHslRes = satRes.toHsl();
				// if (Math.abs(tinyRes.h - resultHsl.hue) > 0.0001) {
				// 	console.log("Parse hues", tinyColor({ r, g, b }).toHsl().h, hsl.hue);
				// 	console.log("Hues result", tinyRes.h, hsl.hue);
				// 	console.log("Result", result);
				// 	throw new Error("Diff in hue")
				// }

				// console.log("Res", result, "vs", c.desaturate(-100 * saturation).toHsl())

				r = result.r;
				g = result.g;
				b = result.b;
			}
		}

		/* eslint-disable no-param-reassign */
		data[i] = r;
		data[i + 1] = g;
		data[i + 2] = b;
		/* eslint-enable no-param-reassign */

		if ((numPixelChannels + 1) % 4000 === 0) {
			yield;
		}
	}

	return { type: "cloned", clonedImage };
}

export default colourCorrectionOperation;
