import React, { ReactElement, useEffect } from "react";
import Modal from "~/components/modal.tsx";
import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import useAppSelector from "~/hooks/use-app-selector.ts";
import LoadingAnimation from "~/components/ui/loading-animation.tsx";
import { isUpdatableLoading, isUpdatableError } from "~/utils/loading.ts";
import {
	loadSavedDesigns,
	loadSavedProject,
	loadMoreSavedDesigns,
	requestDeleteSavedProject,
} from "~/features/user/store-slice.ts";
import { selectSignedInUser } from "~/features/user/selectors.ts";
import { closeSavedDesigns, hidePreview } from "~/features/nav/store-slice.ts";
import { pushGtmData } from "~/features/analytics/index.ts";
import SavedDesignCell from "./saved-design-cell.tsx";
import { SavedDesignProject } from "../user/app-auth-user.ts";
import { isBackendCommittedSaveState } from "../save-project/store-slice.ts";

function getCellStatus(
	s: any,
	loadingIndex?: number,
	currentId?: string,
): "disabled" | "current" | undefined {
	if (loadingIndex !== undefined) {
		return "disabled";
	}
	if (currentId === s.id) {
		return "current";
	}
	return undefined;
}

type SavedDesignsModalProps = {
	readonly show: boolean;
};

function SavedDesignsModal({ show }: SavedDesignsModalProps): ReactElement {
	const dispatch = useAppDispatch();

	// Try loading saved designs as soon as enter
	useEffect(() => {
		if (show) {
			dispatch(loadSavedDesigns());
		}
	}, [dispatch, show]);
	const { savedDesigns } = useAppSelector(selectSignedInUser);
	const { nextPageToken, projectsImport, loadingIndex, designs } =
		savedDesigns.data ?? {
			nextPageToken: undefined,
			loadingIndex: undefined,
			designs: [],
			projectsImport: undefined,
		};

	// Reload the saved designs periodically if import is still running
	useEffect(() => {
		if (projectsImport?.status !== "running") {
			return;
		}

		// Problem here that can double load if takes longer than 10s
		const intervalId = setInterval(() => {
			dispatch(loadSavedDesigns());
		}, 8_000);
		// Will stop reloading once status comes through as complete
		return () => {
			clearInterval(intervalId);
		};
	}, [projectsImport?.status, dispatch]);

	const hasMore = !!nextPageToken;
	const currentId = useAppSelector((s) => {
		const state = s.saveProject.openedProjectSaveState;
		if (!state || !isBackendCommittedSaveState(state)) {
			return undefined;
		}
		return state.id;
	});

	const onLoadMoreClick = () => {
		dispatch(loadMoreSavedDesigns());
	};

	const onClickProject = async (project: SavedDesignProject) => {
		await dispatch(loadSavedProject(project));
		dispatch(closeSavedDesigns());
		dispatch(hidePreview());
	};

	const onDeleteClickProject = (projectId: string) => {
		dispatch(requestDeleteSavedProject(projectId));
	};

	const onCloseSavedDesignsClick = () => {
		dispatch(closeSavedDesigns());
	};

	// GTM
	useEffect(() => {
		if (show) {
			dispatch(
				pushGtmData({
					event: "PageView",
					page_title: "Saved Design",
				}),
			);
		}
	}, [dispatch, show]);

	// TODO: Fallback image
	return (
		<Modal
			show={show}
			title="Saved designs"
			size="large"
			onClose={onCloseSavedDesignsClick}
		>
			{isUpdatableError(savedDesigns) ? (
				<Modal.Text className="error-message">
					{savedDesigns.message}
				</Modal.Text>
			) : (
				<Modal.Text>
					{projectsImport?.status === "running" && (
						<>
							<LoadingAnimation size={100}>
								Importing projects...
							</LoadingAnimation>
							<div>
								We&apos;re bringing across your projects from the old website.
								They will be ready shortly.
							</div>
						</>
					)}
					{isUpdatableLoading(savedDesigns) && designs.length === 0 && (
						<LoadingAnimation size={50}>Loading...</LoadingAnimation>
					)}
					{!isUpdatableLoading(savedDesigns) &&
						designs.length > 0 &&
						"Select a project to load it."}
					{!isUpdatableLoading(savedDesigns) &&
						projectsImport?.status !== "running" &&
						designs.length === 0 &&
						"You don't have any saved projects."}
					{loadingIndex !== undefined && " Opening project..."}
				</Modal.Text>
			)}
			<div className="default-modal__text plaintext saved-designs-container">
				{designs.map((s) => (
					<SavedDesignCell
						key={s.id}
						design={s}
						onClick={() => onClickProject(s)}
						onDeleteClick={() => onDeleteClickProject(s.id)}
						status={getCellStatus(s, loadingIndex, currentId)}
					/>
				))}
			</div>
			{hasMore && (
				<div className="default-modal__text plaintext saved-designs-container">
					<button
						type="button"
						className="button button_size_small button_type_ghost"
						onClick={onLoadMoreClick}
						disabled={isUpdatableLoading(savedDesigns)}
					>
						{isUpdatableLoading(savedDesigns) ? "Loading..." : "Load More"}
					</button>
				</div>
			)}
			<Modal.Actions
				actions={[
					{
						text: "Cancel",
						onClick: onCloseSavedDesignsClick,
						type: "ghost",
						disabled: loadingIndex !== undefined,
					},
				]}
			/>
		</Modal>
	);
}

export default SavedDesignsModal;
