import {
	Money,
	multiplyMoney,
	PictureConfiguration,
} from "@brickme/project-core/src";

const numStripsPerPack = 4;
const numStripsPerBaseplate = parseInt(
	import.meta.env.VITE_NUM_STRIPS_PER_BASEPLATE,
	10,
);

function getNumberOfHangingStripPacksForPicture({
	numberOfBasePlates,
}: PictureConfiguration) {
	const baseplates = numberOfBasePlates.width * numberOfBasePlates.height;
	return Math.ceil((baseplates * numStripsPerBaseplate) / numStripsPerPack);
}

function calculateHangingStripsPrice(
	picture: PictureConfiguration,
	price: Money,
) {
	return multiplyMoney(price, getNumberOfHangingStripPacksForPicture(picture));
}

export { getNumberOfHangingStripPacksForPicture, calculateHangingStripsPrice };
