import { takeWhile, isEqual, pick } from "lodash-es";
import {
	BrickCounts,
	PictureConfiguration,
	OperationMissingSourcesName,
	BuildBitmap,
} from "../model/index.ts";

type BuildCacheEntry = {
	readonly configDependencies: Partial<PictureConfiguration>;
	readonly result: {
		readonly image: BuildBitmap;
		readonly operationsMissingSources: readonly OperationMissingSourcesName[];
	};
};

type SourceKey =
	| "background-mask"
	| "enhance-faces"
	| "colorisation"
	| "face-mask";

type ImageBuildCache = {
	readonly entries: readonly BuildCacheEntry[];
	// Current step 1 can be very heavy and has low number of config permutations.
	// Save a copy of all entries for performance.
	readonly step1AlternateEntries: readonly BuildCacheEntry[];
	readonly operationsMissingSources: readonly OperationMissingSourcesName[];
	readonly finalBrickCounts: BrickCounts;
	readonly availableSources: readonly SourceKey[];
};

type BuildCacheResult = {
	readonly result: BuildCacheEntry["result"];
	readonly reuseCache: ImageBuildCache;
};

function doesCacheEntryMatch(
	picture: PictureConfiguration,
	entry: BuildCacheEntry,
) {
	return isEqual(
		entry.configDependencies,
		pick(picture, Object.keys(entry.configDependencies)),
	);
}

function takeValidCache(
	picture: PictureConfiguration,
	cache: ImageBuildCache,
): readonly BuildCacheEntry[] {
	return takeWhile(cache.entries, (e) => doesCacheEntryMatch(picture, e));
}

export { takeValidCache, doesCacheEntryMatch };
export type { SourceKey, BuildCacheEntry, BuildCacheResult, ImageBuildCache };
