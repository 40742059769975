import { AppState } from "~/store/root-reducer.ts";
import { EditorMenu } from "./editor-menu.ts";
import { InitialActionStatus } from "./store-slice.ts";
import { Command } from "../commands/command.ts";

function selectPremiumColoursMessageStatus(
	state: AppState,
): "show" | "seen" | undefined {
	return state.nav.premiumColoursMessageStatus;
}

function selectSelectedEditorMenu(state: AppState): EditorMenu {
	return state.nav.selectedEditorMenu;
}

function selectShowPreview(state: AppState): boolean {
	return state.nav.showPreview;
}

function selectIsTutorialOpen(state: AppState): boolean {
	return state.nav.isTutorialOpen;
}

function selectIsCompleteOpen(state: AppState): boolean {
	return state.nav.isCompleteOpen;
}

function selectIsSavedDesignsOpen(state: AppState): boolean {
	return state.nav.isSavedDesignsOpen;
}

function selectShowWhatsMyPassword(state: AppState): boolean {
	return state.nav.showWhatsMyPassword;
}

function selectIsSimpleCreatorOpen(state: AppState): boolean {
	return state.nav.isSimpleCreatorOpen;
}

function selectInitialActionStatus(state: AppState): InitialActionStatus {
	return state.nav.initialActionStatus;
}

function selectChangeSizeErasesPenMessageStatus(
	state: AppState,
): Command | "seen" | undefined {
	return state.nav.changeSizeErasesPenMessageStatus;
}

function selectGuestPromoIsOpen(state: AppState): boolean {
	return state.nav.guestPromoOpen;
}

function selectBasePlateConversionWarningOpen(state: AppState): boolean {
	return state.nav.basePlateConversionWarningOpen;
}

function selectPromptLogin(state: AppState): boolean {
	return state.nav.showLoginPrompt;
}

function selectShowCompleteLoginPrompt(state: AppState) {
	return state.nav.isCompleteLoginPromptOpen;
}

function selectShowCreateBrickArt(state: AppState) {
	return state.nav.showCreateBrickArt;
}

export {
	selectShowCreateBrickArt,
	selectIsTutorialOpen,
	selectIsCompleteOpen,
	selectIsSavedDesignsOpen,
	selectShowWhatsMyPassword,
	selectIsSimpleCreatorOpen,
	selectInitialActionStatus,
	selectChangeSizeErasesPenMessageStatus,
	selectGuestPromoIsOpen,
	selectBasePlateConversionWarningOpen,
	selectPremiumColoursMessageStatus,
	selectPromptLogin,
	selectShowCompleteLoginPrompt,
	selectSelectedEditorMenu,
	selectShowPreview,
};
