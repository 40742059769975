import { range } from "lodash-es";
import { firstOrThrow, findOnlyOrThrow } from "@dhau/lang-extras";
import {
	Palette,
	PaletteMode,
	Bitmap,
	bitmapChannels,
	readUInt32BE,
} from "@brickme/project-core/src";
import { CompleteProjectMutationVariables } from "~/api/complete-project-mutation.ts";

const upperAlphabet = Array(26)
	.fill(undefined)
	.map((_, i) => String.fromCharCode("A".charCodeAt(0) + i));
const allSymbols = [
	...upperAlphabet.map((a) => a.toLowerCase()),
	...upperAlphabet,
	...range(0, 10).map((i) => i.toString()),
];

type PaletteEntry = {
	readonly identifier: number;
	readonly symbol: string;
	readonly colour: string;
};

type Result = {
	readonly palette: CompleteProjectMutationVariables["input"]["palette"];
	readonly build: CompleteProjectMutationVariables["input"]["build"];
};

function createSerialisedBuild(
	systemPalette: Palette,
	image: Bitmap,
	paletteMode: PaletteMode,
): Result {
	const remainingSymbols = Array.from(allSymbols);
	const pixelToColour: Record<string, PaletteEntry> = {};
	let build = "";
	const palette: PaletteEntry[] = [];
	for (
		let i = 0;
		i < image.width * image.height * bitmapChannels;
		i += bitmapChannels
	) {
		const pixel = readUInt32BE(image.data, i);
		if (!pixelToColour[pixel]) {
			const brickColour = findOnlyOrThrow(
				systemPalette,
				(p) => p.brick.rgba === pixel,
			).brick;
			const symbol = firstOrThrow(remainingSymbols, "No more symbols");
			remainingSymbols.shift();
			const entry = {
				identifier: brickColour.identifier,
				symbol,
				colour: brickColour.hexString,
			};
			palette.push(entry);
			pixelToColour[pixel] = entry;
		}
		const match = pixelToColour[pixel];
		build += match.symbol;
	}

	// There are some cases where a custom palette may include colours not actually in the final
	// output. We try and prevent this in the frontend app, but want to be resilient anyway in case it
	// doesn't work
	if (paletteMode.type === "custom") {
		paletteMode.palette
			.filter((paletteColour) => !pixelToColour[paletteColour.rgba])
			.forEach((paletteColour) => {
				const brickColour = findOnlyOrThrow(
					systemPalette,
					(p) => p.brick.rgba === paletteColour.rgba,
				).brick;
				const symbol = firstOrThrow(remainingSymbols, "No more symbols");
				remainingSymbols.shift();
				palette.push({
					identifier: brickColour.identifier,
					symbol,
					colour: brickColour.hexString,
				});
			});
	}

	return {
		palette,
		build: {
			format: "symbols",
			data: build,
		},
	};
}

export default createSerialisedBuild;
