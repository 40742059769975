import { gql } from "graphql-request";
import { TransportPictureConfiguration } from "@brickme/project-core/src";

const completeProjectMutation = gql`
	mutation completeProject($input: CompleteProjectInput!) {
		completeProject(input: $input) {
			shopifyProductVariantId
			customProperties {
				name
				value
			}
		}
	}
`;

type CompleteProjectMutation = {
	readonly completeProject: {
		readonly shopifyProductVariantId: string;
		readonly customProperties:
			| readonly {
					readonly name: string;
					readonly value: string;
			  }[]
			| null;
	};
};

type CompleteProjectMutationVariables = {
	readonly input: {
		readonly sourceImageName: string;
		readonly picture: TransportPictureConfiguration;
		readonly palette: readonly {
			readonly identifier: number;
			readonly symbol: string;
			readonly colour: string;
		}[];
		readonly build: {
			readonly format: "symbols";
			readonly data: string;
		};
	};
};

export type { CompleteProjectMutation, CompleteProjectMutationVariables };
export default completeProjectMutation;
