import React, { useCallback, useState, useEffect } from "react";
import { unknownToString } from "@dhau/lang-extras";
import useMeasure from "react-use/esm/useMeasure";
import useEffectOnce from "react-use/esm/useEffectOnce";
import {
	maxKnownBasePlateSize,
	maxImageZoom,
	runGeneratorSync,
	bitmapToImageData,
	maxNumberOfBasePlates,
	Bitmap,
	scaleBitmap,
	cloneBitmap,
	ensureBitmapOpaque,
	rotateBitmapAtRightAngles,
	WritableBitmap,
	EncodedImage,
	ParsedUploadedFile,
	parseUploadedFile,
} from "@brickme/project-core/src";
import chooseIconImage from "~/styles/img/svg/graphic/choose-icon.svg";
import useAppDispatch from "~/hooks/use-app-dispatch.ts";
import useAppSelector from "~/hooks/use-app-selector.ts";
import useBooleanCallbacks from "~/hooks/use-boolean-callbacks.ts";
import ReactInvalidComponentStateError from "~/utils/react-invalid-component-state-error.ts";
import { useHeavyProcessRunner } from "~/context/heavy-process-runner.tsx";
import { useWebsiteUrl } from "~/context/website-url.tsx";
import { selectImage } from "~/features/workspace/store-slice.ts";
import { openSavedDesigns } from "~/features/nav/store-slice.ts";
import { selectUser } from "~/features/user/selectors.ts";
import { loadHasSavedDesignStatus } from "~/features/user/store-slice.ts";
import selectDefaultHangingType from "~/features/order/select-default-hanging-type.ts";
import { pushGtmData } from "~/features/analytics/index.ts";
import {
	selectBrowserSource,
	selectOperatingSystem,
} from "~/features/reference/selectors.ts";
import LibraryModal from "./library-modal.tsx";
import ImageFileUpload from "./image-file-upload.tsx";

// Keeping a single desired size is better for rotation calcs
const desiredSize = 350;

const rotationSteps = [0, 90, 180, 270] as const;

type RotationStep = (typeof rotationSteps)[number];

function previousRotationStep(rotate: RotationStep) {
	const index = rotationSteps.indexOf(rotate);
	if (index === 0) {
		return rotationSteps[rotationSteps.length - 1];
	}
	return rotationSteps[index - 1];
}

function* resizeImage(image: Bitmap): Generator<undefined, Bitmap> {
	const useScale = Math.min(
		desiredSize / image.width,
		desiredSize / image.height,
	);
	const cloned = yield* scaleBitmap(image, useScale);
	// TODO: generator
	// This was here before, not sure if needed
	ensureBitmapOpaque(cloned as unknown as WritableBitmap);
	return cloned;
}

type PendingImage = {
	readonly scaled: Bitmap;
	// We want to keep reference to the originally encoded image straight from the user
	// in case it's compressed to a smaller size than what we can do. Currently this
	// includes PNG8 (we don't do that in browser). Or jpeg for example - re-encoding
	// a jpeg can lead to worse visual results with a higher file size.
	readonly originalEncodedImage: EncodedImage | undefined;
	readonly fileName: string;
	readonly image: Bitmap;
	readonly rotate: 0 | 90 | 180 | 270;
	readonly source: "custom" | "library";
};

function SourceImageScreen() {
	const os = useAppSelector(selectOperatingSystem);
	const browserSource = useAppSelector(selectBrowserSource);
	const user = useAppSelector(selectUser);
	const runHeavyProcess = useHeavyProcessRunner();

	const dispatch = useAppDispatch();
	useEffectOnce(() => {
		dispatch(loadHasSavedDesignStatus());
		dispatch(
			pushGtmData({
				event: "PageView",
				page_title: "Image Selection",
			}),
		);
	});

	const [pendingImage, setPendingImage] = useState<PendingImage | undefined>(
		undefined,
	);
	const [imageError, setImageError] = useState<string | undefined>(undefined);
	const setPendingImageByBitmap = useCallback(
		async (image: Bitmap, fileName: string, source: "custom" | "library") => {
			const scaled = await runHeavyProcess({
				generator: resizeImage(image),
				priority: -5,
			}).promise;
			setPendingImage({
				image,
				originalEncodedImage: undefined,
				fileName,
				scaled,
				rotate: 0,
				source,
			});
		},
		[runHeavyProcess],
	);
	const setPendingImageByParsed = useCallback(
		async (
			{ bitmap, originalEncodedImage }: ParsedUploadedFile,
			fileName: string,
			source: "custom" | "library",
		) => {
			const scaled = await runHeavyProcess({
				generator: resizeImage(bitmap),
				priority: -5,
			}).promise;
			setPendingImage({
				image: bitmap,
				originalEncodedImage,
				fileName,
				scaled,
				rotate: 0,
				source,
			});
		},
		[runHeavyProcess],
	);
	const onChangeImage = () => setPendingImage(undefined);
	// TODO: Resizing of some sort is being done in 3 separate places.
	// Make it at a single source only (here is probably best?)
	// 1. here
	// 2. selectImage
	// 3. upload file
	const maxResolution =
		maxNumberOfBasePlates * maxKnownBasePlateSize * maxImageZoom;
	const onFileChange = useCallback(
		async (file: File) => {
			setImageError(undefined);
			// TODO: parseUploadedFile gives us the buffer for free. We must be getting it again later.
			// store the buffer as well
			try {
				const image = await parseUploadedFile(file, maxResolution);
				await setPendingImageByParsed(image, file.name, "custom");
			} catch (error) {
				setImageError(unknownToString(error));
				return;
			}
			dispatch(
				pushGtmData({
					event: "ImageUpload",
				}),
			);
		},
		[dispatch, setPendingImageByParsed, maxResolution],
	);

	// Select image
	const onConfirmImage = async () => {
		if (!pendingImage) {
			throw new ReactInvalidComponentStateError();
		}

		const { image, originalEncodedImage, fileName, rotate, source } =
			pendingImage;

		// TODO: promise/generator
		let rotated = image;
		if (rotate) {
			rotated = rotateBitmapAtRightAngles(image, rotate);
		}
		await dispatch(
			selectImage({
				inputImage: rotated,
				// If the user has rotated, then the original buffer isn't useful
				originalEncodedImage: rotate === 0 ? originalEncodedImage : undefined,
				fileName,
			}),
		);
		dispatch(selectDefaultHangingType());
		dispatch(
			pushGtmData({
				event: "ImageSelected",
				source,
			}),
		);
	};

	// Rendering
	const [setMeasureCanvas, { width: canvasWidth, height: canvasHeight }] =
		useMeasure<HTMLElement>();
	const [renderCanvas, setRenderCanvas] = useState<
		HTMLCanvasElement | undefined
	>(undefined);
	const setPreviewCanvas = useCallback(
		(canvas: HTMLCanvasElement) => {
			setMeasureCanvas(canvas);
			setRenderCanvas(canvas);
		},
		[setMeasureCanvas],
	);
	const pendingScaledImage = pendingImage?.scaled;
	useEffect(() => {
		if (!renderCanvas || !pendingScaledImage) {
			return;
		}

		// Only using canvasWidth and canvasHeight to force effect to refire
		const ctx = renderCanvas.getContext("2d", { alpha: false });
		if (!ctx) {
			throw new Error("No 2d context");
		}
		const imageData = bitmapToImageData(pendingScaledImage);
		ctx.putImageData(imageData, 0, 0);
	}, [pendingScaledImage, canvasWidth, canvasHeight, renderCanvas]);

	// Library
	const [isLibraryOpen, openLibrary, closeLibrary] = useBooleanCallbacks(false);
	// TODO: Opportunity here to use buffer
	const onSelectLibraryImage = useCallback(
		async (image: Bitmap, name: string) => {
			await setPendingImageByBitmap(image, name, "library");
			closeLibrary();
		},
		[closeLibrary, setPendingImageByBitmap],
	);

	// Rotate
	const onRotateImage = () => {
		if (!pendingImage) {
			throw new ReactInvalidComponentStateError();
		}
		const { image, originalEncodedImage, rotate, scaled, fileName, source } =
			pendingImage;
		// TODO: Generator for rotate
		const newScaled = runGeneratorSync(cloneBitmap(scaled));
		const rotated = rotateBitmapAtRightAngles(newScaled, 270);
		setPendingImage({
			image,
			originalEncodedImage,
			fileName,
			scaled: rotated,
			rotate: previousRotationStep(rotate),
			source,
		});
	};

	// Continue
	const readOurTipsLink = useWebsiteUrl(
		"/blogs/inspiration/6-tips-to-find-the-best-pics-to-brick/",
	);
	const designServiceLink = useWebsiteUrl("/products/design-it-for-me-service");

	const onOpenSavedDesignsClick = useCallback(() => {
		dispatch(openSavedDesigns());
	}, [dispatch]);

	// GTM
	const hasPendingImage = !!pendingImage;
	useEffect(() => {
		if (hasPendingImage) {
			dispatch(
				pushGtmData({
					event: "PageView",
					page_title: "Selected Image Preview",
				}),
			);
		}
	}, [dispatch, hasPendingImage]);

	if (!pendingImage) {
		return (
			<main className="app-main">
				<div className="step-wrap container-fluid">
					<div className="step">
						<div className="step__body">
							<h2 className="step__title h1">Choose an image to brick-it</h2>
							<div className="step__choose-image choose-image">
								<ImageFileUpload
									os={os}
									browserSource={browserSource}
									onSelectFile={onFileChange}
								/>
								<button
									className="choose-image__card file-card"
									type="button"
									onClick={openLibrary}
								>
									<span className="file-card__inner">
										<span className="file-card__graph">
											<img
												src={chooseIconImage}
												alt="Choose"
												className="file-card__graph-img"
											/>
										</span>
										<span className="file-card__title">
											or browse our library
										</span>
									</span>
								</button>
							</div>
							{imageError && <div className="error-message">{imageError}</div>}
							{user.type === "signedIn" && user.user.hasSavedDesign && (
								<div className="step__actions">
									<button
										type="button"
										onClick={onOpenSavedDesignsClick}
										className="btn btn-link step__actions-link"
									>
										Or continue a Brick-Pic you&apos;ve already started
									</button>
								</div>
							)}
						</div>
						<div className="step__footer">
							<div className="step__footer-content">
								<span className="step__footer-text">
									Don’t want design it yourself? For a small fee, we can create
									a design for you based on your image and instructions.
								</span>{" "}
								<a
									href={designServiceLink}
									target="_blank"
									rel="noopener noreferrer"
									className="step__footer-link"
								>
									Click here to learn more.
								</a>
							</div>
						</div>
						<footer className="step__footer">
							<div className="step__footer-content">
								<span className="step__footer-text">
									What pics are best to brick?
								</span>{" "}
								<a
									href={readOurTipsLink}
									target="_blank"
									rel="noopener noreferrer"
									className="step__footer-link"
								>
									Read our tips
								</a>
							</div>
						</footer>
					</div>
				</div>
				<LibraryModal
					show={isLibraryOpen}
					onCancel={closeLibrary}
					onSelect={onSelectLibraryImage}
				/>
			</main>
		);
	}
	return (
		<main className="app-main">
			<div className="step-wrap container-fluid">
				<div className="step">
					<div className="step__body">
						<h2 className="step__title h1">Your selected image</h2>
						<div className="step__selected">
							<canvas
								ref={setPreviewCanvas}
								width={pendingImage.scaled.width}
								height={pendingImage.scaled.height}
								className="step__selected-img"
							/>
						</div>
						<div className="step__actions">
							<button
								className="step__actions-btn button button_text_uppercase"
								type="button"
								onClick={onConfirmImage}
							>
								Brick Me
							</button>
						</div>
					</div>
					<footer className="step__footer">
						<button
							className="step__footer-btn"
							type="button"
							onClick={onRotateImage}
						>
							Rotate image
						</button>
						<button
							className="step__footer-btn"
							type="button"
							onClick={onChangeImage}
						>
							Change image
						</button>
					</footer>
				</div>
			</div>
		</main>
	);
}

export default SourceImageScreen;
