async function waitUntil<T>(condition: () => T | false): Promise<T> {
	return new Promise((resolve, reject) => {
		setInterval(() => {
			try {
				const result = condition();
				if (result !== false) {
					resolve(result);
				}
			} catch (e) {
				reject(e);
			}
		}, 100);
	});
}

export default waitUntil;
