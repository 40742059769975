import React, { ReactNode, useEffect, useState } from "react";
import { clsx } from "clsx";
import ReactModal from "react-modal";

type ModalProps = {
	readonly show: boolean;
	readonly title?: string;
	readonly bodyClassName?: string;
	readonly size?: "small" | "large" | "dynamic";
	readonly children: ReactNode;
	readonly onClose?: () => void;
	readonly slimBorder?: boolean;
};

function Modal({
	title,
	size,
	show,
	bodyClassName,
	children,
	slimBorder,
	onClose,
}: ModalProps) {
	const useSize = size ?? "small";
	const [shown, setShown] = useState(false);
	useEffect(() => {
		if (show) {
			// Need a break between modal and modal_active being applied for transition to work
			const intervalId = window.requestAnimationFrame(() => {
				setShown(true);
			});
			return () => {
				window.cancelAnimationFrame(intervalId);
			};
		}
		setShown(false);
		return undefined;
	}, [show]);

	return (
		<ReactModal
			isOpen={show}
			shouldCloseOnOverlayClick={false}
			closeTimeoutMS={300}
			onRequestClose={onClose}
			style={{
				content: { inset: 0, padding: 0, background: "none" },
				overlay: { background: "none" },
			}}
		>
			<div className={clsx(["modal", { modal_active: shown }])}>
				<div className="modal__box">
					<div
						className={clsx(
							"modal__content",
							"default-modal",
							`default-modal__${useSize}`,
						)}
					>
						{onClose && (
							<button
								type="button"
								className="modal__close button"
								onClick={onClose}
							>
								X
							</button>
						)}
						<div
							className={clsx(
								"default-modal__body",
								{ "default-modal__body__slim": slimBorder },
								bodyClassName,
							)}
						>
							{title && <h1 className="default-modal__title h1">{title}</h1>}
							{children}
						</div>
					</div>
				</div>
			</div>
		</ReactModal>
	);
}

type IllustrationProps = {
	readonly src: string;
	readonly alt: string;
	readonly imgClassname?: string;
};

function Illustration({ src, alt, imgClassname }: IllustrationProps) {
	return (
		<div className="default-modal__illustration">
			<img
				src={src}
				alt={alt}
				className={`default-modal__illustration-img ${imgClassname ?? ""}`}
			/>
		</div>
	);
}

type TextProps = {
	readonly className?: string;
	readonly scrollable?: boolean;
	readonly padded?: boolean;
	readonly textAlign?: "center" | "left";
	readonly children: ReactNode;
};

function Text({
	className,
	textAlign,
	scrollable,
	padded,
	children,
}: TextProps) {
	return (
		<div
			className={clsx(
				"default-modal__text",
				`default-modal__text__align-${textAlign ?? "center"}`,
				{
					"default-modal__text__scrollable": scrollable,
					"default-modal__text__padded": padded,
				},
				"plaintext",
				className,
			)}
		>
			{children}
		</div>
	);
}

type BaseActionButton = {
	readonly text: ReactNode;
};

type ClickActionButton = BaseActionButton & {
	readonly onClick?: () => void;
	readonly type?: "ghost";
	readonly disabled?: boolean;
};

type LinkActionButton = BaseActionButton & {
	readonly href: string;
	readonly target?: "blank" | "self";
	readonly onMouseDown?: () => void;
	readonly onMouseUp?: () => void;
};

type ActionButton = ClickActionButton | LinkActionButton;

type ModalActionsProps = {
	readonly compactButtons?: boolean;
	readonly actions: readonly ActionButton[];
};

function Actions({ compactButtons, actions }: ModalActionsProps) {
	/* eslint-disable react/jsx-no-target-blank */
	return (
		<div
			className={clsx("default-modal__actions", {
				"default-modal__actions__compact": compactButtons,
			})}
		>
			{actions.map((action, i) => {
				const buttonClassName = clsx(["default-modal__actions-btn", "button"]);

				/* eslint-disable react/no-array-index-key */
				if ("href" in action) {
					return (
						<a
							key={i}
							href={action.href}
							className={buttonClassName}
							target={action.target === "blank" ? "_blank" : undefined}
							rel={
								action.target === "blank" ? "noopener noreferrer" : undefined
							}
							onMouseDown={action.onMouseDown}
							onMouseUp={action.onMouseUp}
							onTouchStart={action.onMouseDown}
							onTouchEnd={action.onMouseUp}
							onTouchCancel={action.onMouseUp}
						>
							{action.text}
						</a>
					);
				}
				return (
					<button
						key={i}
						type="button"
						className={clsx(buttonClassName, {
							button_type_ghost: action.type === "ghost",
						})}
						onClick={action.onClick}
						disabled={action.disabled}
					>
						{action.text}
					</button>
				);
				/* eslint-enable react/no-array-index-key */
			})}
		</div>
	);
	/* eslint-enable react/jsx-no-target-blank */
}

Modal.Actions = Actions;
Modal.Illustration = Illustration;
Modal.Text = Text;

export type { ModalProps, ModalActionsProps };
export default Modal;
