import React, { ReactElement } from "react";
import { clsx } from "clsx";
import source from "~/styles/img/svg/sprite.svg";

type SpriteImageProps = {
	readonly className?: string;
	readonly name: string; // TODO: Maybe restrict?
};

export default function SpriteIcon({
	name,
	className,
}: SpriteImageProps): ReactElement {
	return (
		<svg className={clsx(["sprite-icon", className])}>
			<use xlinkHref={`${source}#${name}`} />
		</svg>
	);
}
