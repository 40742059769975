import { createCanvas, CanvasRenderingContext2D } from "canvas";

function createCanvasContext2d(
	width: number,
	height: number,
): CanvasRenderingContext2D {
	return createCanvas(width, height).getContext("2d", {
		alpha: false,
	});
}

export default createCanvasContext2d;
